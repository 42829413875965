import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { LoginRequest } from "../../Api/Auth/TokenEndpoints";
import { setUserAuthenticated } from "../../Redux/Slice/AuthenticatedUser/AutheticatedUserSlice";
import { IsUserAuthenticated, SetAuthenticationValues } from "../../Util/AuthHelper";
import DocumentDialog from "./DocumentDialog";

const loginFormValidationSchema = Yup.object().shape({
  email: Yup.string().required("Enter email address").email("Enter valid email address"),
  password: Yup.string().required("Enter password"),
});

const Login: React.FC = function () {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCredentials, setShowCredentials] = useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);
  const userErrorMessages = [
    "User Not Found.",
    "User Not Active. Please contact the administrator.",
    "E-Mail not confirmed.",
  ];

  const passwordErrorMessages = ["Invalid Credentials."];

  const [error, setError] = useState<string>("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginFormValidationSchema,
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      setError("");
      LoginRequest({
        email: values.email,
        password: values.password,
      })
        .then((res) => {
          setSubmitting(false);
          SetAuthenticationValues(res.token, res.refreshToken, res.userId, res.userImageURL);
          dispatch(setUserAuthenticated({ isUserAuthenticated: true, userId: res.userId }));
          navigateToSelectTenantClient();
        })
        .catch((err) => {
          setSubmitting(false);
          if (Array.isArray(err)) {
            if (userErrorMessages.indexOf(err[0]) > -1) {
              setFieldError("email", err[0]);
            }
            if (passwordErrorMessages.indexOf(err[0]) > -1) {
              setFieldError("password", err[0]);
            }
          } else {
            setError(err[0]);
          }
        });
    },
  });

  const { touched, errors, handleChange, values, setValues, handleSubmit, isSubmitting } = formik;
  const navigateToSelectTenantClient = () => navigate("/SelectTenantClient", { replace: true });

  useEffect(() => {
    if (IsUserAuthenticated()) {
      navigateToSelectTenantClient();
    }
  }, []);

  return (
    <div
      style={{
        height: "98vh",
        width: "100vw",
        background: theme.palette.background.default,
      }}
    >
      <Container>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          paddingTop={20}
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={5}
          >
            <Paper
              elevation={3}
              style={{ padding: 30 }}
            >
              <Grid container>
                <Grid
                  item
                  container
                  spacing={2}
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid item>
                    <Avatar sx={{ bgcolor: "secondary.main" }}>
                      <LockOutlinedIcon />
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Typography variant='h4'>Sign In</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Login with your Credentials.</Typography>
                  </Grid>
                  {error && (
                    <Grid item>
                      <Typography color={theme.palette.error.main}>{error}</Typography>
                    </Grid>
                  )}
                </Grid>
                <div style={{ padding: 10 }}></div>
                <Grid
                  container
                  spacing={2}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      placeholder='Email'
                      name='email'
                      variant='outlined'
                      label='Email'
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      onChange={(x) => {
                        handleChange(x);
                        setShowCredentials(
                          x.target.value === "default" && values.password === "show",
                        );
                      }}
                      value={values.email}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      placeholder='Password'
                      name='password'
                      variant='outlined'
                      label='Password'
                      type='password'
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      onChange={(x) => {
                        handleChange(x);
                        setShowCredentials(values.email === "default" && x.target.value === "show");
                      }}
                      value={values.password}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Grid item>
                        <FormControlLabel
                          label='Remember me?'
                          control={<Checkbox />}
                        />
                      </Grid>
                      <Grid item>
                        <Typography>
                          <Link
                            sx={{ cursor: "pointer" }}
                            onClick={() => navigate("/forgotPassword", { replace: true })}
                          >
                            Forgot Password
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {showCredentials && (
                    <>
                      <Grid item>
                        <Button
                          onClick={() => {
                            setValues({
                              email: "no-reply@automatedintegrationtechnologies.com",
                              password: "123Pa$$word!",
                            });
                          }}
                        >
                          Fill Administrator Credentials
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => {
                            setValues({
                              email: "basicuser@medhelpinc.com",
                              password: "123Pa$$word!",
                            });
                          }}
                        >
                          Fill Basic Credentials
                        </Button>
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    xs={12}
                  >
                    <LoadingButton
                      fullWidth
                      variant='contained'
                      type='submit'
                      onClick={() => handleSubmit()}
                      loading={isSubmitting}
                    >
                      Sign In
                    </LoadingButton>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography>
                      By clicking Sign In, you agree to{" "}
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={() => setTermsDialogOpen(true)}
                        className='terms-link'
                      >
                        Terms & Conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={() => setPrivacyDialogOpen(true)}
                        className='privacy-link'
                      >
                        Privacy Policy
                      </Link>{" "}
                      for Automated Integration Technologies
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        {/* Terms and Privacy Dialogs */}
        <DocumentDialog
          open={termsDialogOpen}
          onClose={() => setTermsDialogOpen(false)}
          documentType='terms'
          title='Terms & Conditions'
        />
        <DocumentDialog
          open={privacyDialogOpen}
          onClose={() => setPrivacyDialogOpen(false)}
          documentType='privacy'
          title='Privacy Policy'
        />
      </Container>
    </div>
  );
};

export default Login;
