import React, { FC, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  useTheme,
} from "@mui/material";
import { CheckUsernameAndEmailAvailable } from "../../../Api/User/UserEndpoints";
import { showErrorSnackbar } from "../../../Redux/Slice/Application/ApplicationSlice";
import {
  addUserSelector,
  setActiveStep,
  setUserInfo,
} from "../../../Redux/Slice/Users/AddUserSlice";
import { AddUserStep } from "../../../Redux/State/User/AddUserState";
import { useAppDispatch } from "../../../Redux/Store";
import { UserStepProps } from "./CommonProps";

const validationSchema = Yup.object().shape({
  userName: Yup.string()
    .required("Username is required")
    .matches(/^[a-zA-Z0-9]+$/i, "Only numbers and Alphabets are allowed")
    .min(6, "Username should have atleast 6 characters."),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      "Phone number is invalid",
    ),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Password and Confirm Password must match"),
});

const UserInfoStep: FC<UserStepProps> = function ({ active, onUnsavedChange }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const state = useSelector(addUserSelector);

  const initialValues = {
    userName: state.userInfoStep?.userName || "",
    firstName: state.userInfoStep?.firstName || "",
    lastName: state.userInfoStep?.lastName || "",
    email: state.userInfoStep?.email || "",
    password: state.userInfoStep?.password || "",
    confirmPassword: state.userInfoStep?.password || "",
    phoneNumber: state.userInfoStep?.phoneNumber || null,
    isActive: state.userInfoStep?.isActive || true,
    autoConfirmEmail: false,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      CheckUsernameAndEmailAvailable(values.userName, values.email)
        .then((res) => {
          setSubmitting(false);
          if (res[0].data === false) {
            setFieldError("userName", "Username is already exists.");
          }
          if (res[1].data === false) {
            setFieldError("email", "Email is already exists.");
          }
          if (res[0].data === true && res[1].data === true) {
            dispatch(
              setUserInfo({
                userName: values.userName,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password,
                autoConfirmEmail: values.autoConfirmEmail,
                isActive: values.isActive,
                phoneNumber: values.phoneNumber,
                fieldErrors: [],
              }),
            );
            dispatch(setActiveStep(AddUserStep.RolesInfo));
          }
        })
        .catch(() => {
          dispatch(showErrorSnackbar("Something went wrong."));
        });
    },
  });

  onUnsavedChange && onUnsavedChange(formik.dirty);

  useEffect(() => {
    state.userInfoStep.fieldErrors.map((fieldError) => {
      formik.setFieldError(fieldError.fieldName, fieldError.errors[0]);
    });
  }, [state.userInfoStep.fieldErrors]);

  return (
    <Box hidden={!active}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <TextField
            fullWidth
            placeholder='Username'
            label='Username'
            name='userName'
            value={formik.values.userName || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.userName && !!formik.errors.userName}
            helperText={formik.touched.userName && formik.errors.userName}
            disabled={formik.isSubmitting}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            fullWidth
            placeholder='First Name'
            label='First Name'
            name='firstName'
            value={formik.values.firstName || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && !!formik.errors.firstName}
            helperText={formik.touched.firstName && formik.errors.firstName}
            disabled={formik.isSubmitting}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            fullWidth
            placeholder='Last Name'
            label='Last Name'
            name='lastName'
            value={formik.values.lastName || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && !!formik.errors.lastName}
            helperText={formik.touched.lastName && formik.errors.lastName}
            disabled={formik.isSubmitting}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            fullWidth
            placeholder='Email'
            label='Email'
            name='email'
            value={formik.values.email || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
            disabled={formik.isSubmitting}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            fullWidth
            placeholder='Phone Number'
            label='Phone Number'
            name='phoneNumber'
            value={formik.values.phoneNumber || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            disabled={formik.isSubmitting}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            fullWidth
            placeholder='Password'
            label='Password'
            name='password'
            type='password'
            value={formik.values.password || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password && formik.errors.password}
            disabled={formik.isSubmitting}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            fullWidth
            placeholder='Confirm Password'
            label='Confirm Password'
            name='confirmPassword'
            type='password'
            value={formik.values.confirmPassword || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            disabled={formik.isSubmitting}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox name='isActive' />}
              name='isActive'
              label='Active?'
              checked={formik.values.isActive}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
            />
          </FormGroup>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox name='autoConfirmEmail' />}
              label='Auto Confirm Email?'
              checked={formik.values.autoConfirmEmail}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row-reverse"}
        paddingTop={theme.spacing(2)}
      >
        <Grid item>
          <LoadingButton
            variant='contained'
            loading={formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Next
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserInfoStep;
