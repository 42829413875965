import React, { FC, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { GetUserRoles, UpdateRoles } from "../../../Api/User/UserEndpoints";
import { RoleNamesResponse } from "../../../Models/ApiResponse/Role/RoleNamesResponse";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../Redux/Slice/Application/ApplicationSlice";
import { useAppDispatch } from "../../../Redux/Store";
import { MenuProps } from "../../Common/Static";

export interface EditRolesModalProps {
  nameToDisplay: string;
  userId: string;
  onCancel(): void;
  onSave(): void;
}

const EditRolesModal: FC<EditRolesModalProps> = function ({
  nameToDisplay,
  userId,
  onCancel,
  onSave,
}) {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [roles, setRoles] = useState<Array<RoleNamesResponse>>([]);
  const [selectedRoles, setSelectedRoles] = useState<Array<string>>([]);

  useEffect(() => {
    setLoading(true);
    GetUserRoles(userId)
      .then(({ data: response }) => {
        if (response.succeeded) {
          setRoles(response.data.roles);
          setSelectedRoles(response.data.selectedRoles);
          setLoading(false);
        } else {
          dispatch(showErrorSnackbar(response.messages[0]));
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        dispatch(showErrorSnackbar("Failed to load roles"));
      });
  }, []);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(typeof value === "string" ? value.split(",") : value);
  };

  const handleSave = () => {
    setSubmitting(true);
    UpdateRoles(userId, { userRoles: selectedRoles })
      .then(({ data: res }) => {
        if (res.succeeded) {
          dispatch(showSuccessSnackbar(res.messages[0]));
          onSave();
        } else {
          dispatch(showErrorSnackbar(res.messages[0]));
        }
        setSubmitting(false);
      })
      .catch(() => {
        dispatch(showErrorSnackbar("Failed to update roles."));
        setSubmitting(false);
      });
  };

  const getSkeleton = () => (
    <>
      <DialogContent>
        <Skeleton
          variant='rounded'
          animation='wave'
          width='100%'
        >
          <FormControl>
            <InputLabel>Roles</InputLabel>
            <Select fullWidth></Select>
          </FormControl>
        </Skeleton>
      </DialogContent>
      <DialogActions>
        <Skeleton
          variant='rectangular'
          animation='wave'
        >
          <Button />
          <Button />
        </Skeleton>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>Manage Roles - {nameToDisplay}</DialogTitle>
      {loading ? (
        getSkeleton()
      ) : (
        <>
          <DialogContent>
            <Box pt={1}>
              <FormControl
                fullWidth
                disabled={submitting}
              >
                <InputLabel id='roles-selection-label'>Roles</InputLabel>
                <Select
                  labelId='roles-selection-label'
                  id='roles-selection-checkbox'
                  multiple
                  fullWidth
                  disabled={submitting}
                  value={selectedRoles}
                  input={<OutlinedInput label='Tag' />}
                  renderValue={(selected) => {
                    return roles
                      .filter((r) => selected.indexOf(r.normalizedName) > -1)
                      .map((r) => r.name)
                      .join(", ");
                  }}
                  MenuProps={MenuProps}
                  onChange={handleChange}
                >
                  {roles.map((role, index) => (
                    <MenuItem
                      key={"Role_" + index}
                      value={role.normalizedName}
                    >
                      <Checkbox checked={selectedRoles.indexOf(role.normalizedName) > -1} />
                      <ListItemText primary={role.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant='contained'
              type='submit'
              color='success'
              loading={submitting}
              onClick={handleSave}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default EditRolesModal;
