import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { authUserFirstNameSelector } from "../../Redux/Selectors/AuthenticatedUser/AuthenticatedUserSelectors";
import { toggleSidebar } from "../../Redux/Slice/Application/ApplicationSlice";
import { RedirectToLogin } from "../../Util/AuthHelper";
import NamedAvatar from "../Common/NamedAvatar";
import { httpClient } from "../../Api/APIBase";

export interface NavbarProps {
  isSidebarOpen?: boolean;
  onToggleSideBarClicked?(): void;
}

const Navbar: FC<NavbarProps> = function ({ onToggleSideBarClicked }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDisplayName = useSelector(authUserFirstNameSelector);

  const settings = [
    {
      text: "Profile",
      onClick: function () {
        console.log("Go to profile");
      },
    },
    {
      text: "Back to Tenant Selection",
      onClick: function () {
        navigate("/SelectTenantClient", { replace: true });
      },
    },
    {
      text: "Logout",
      onClick: async function () {
        try {
          const response = await httpClient.get("identity/token/logout");
          // Check if the request was successful (status code 200)
          if (response.status === 200) {
            // Redirect to the login page
            RedirectToLogin();
          } else {
            // Handle unsuccessful logout (optional)
            console.error("Logout failed:", response.statusText);
            // Optionally, display an error message to the user
          }
        } catch (error) {
          // Handle any errors that occur during logout
          console.error("An error occurred during logout:");
          // Optionally, display an error message to the user
        }
      },
    },
  ];

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <>
      <AppBar position='fixed'>
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2 }}
            onClick={() => {
              dispatch(toggleSidebar());
              onToggleSideBarClicked && onToggleSideBarClicked();
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant='h6'
            component='div'
            sx={{ flexGrow: 1 }}
          >
            AIT Financial Analysis
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title='Open settings'>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
              >
                <NamedAvatar displayText={userDisplayName || ""}></NamedAvatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.text}
                  onClick={() => {
                    handleCloseUserMenu();
                    setting.onClick();
                  }}
                >
                  <Typography textAlign='center'>{setting.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
