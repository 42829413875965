import React, { FC } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, TextField, useTheme } from "@mui/material";
import { ResetPassword } from "../../../Api/User/UserEndpoints";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../Redux/Slice/Application/ApplicationSlice";
import { useAppDispatch } from "../../../Redux/Store";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Password and Confirm Password must match"),
});

export type ChangePasswordCompProps = {
  userId: string;
  showCancel?: boolean;
  onCancel?(): void;
  onSave?(): void;
};

const ChangePasswordComp: FC<ChangePasswordCompProps> = function ({
  userId,
  showCancel,
  onCancel,
  onSave,
}) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      ResetPassword({ userId, password: values.password })
        .then(({ data }) => {
          if (data.succeeded) {
            dispatch(showSuccessSnackbar(data.messages[0]));
            formik.resetForm();
            onSave && onSave();
          } else {
            dispatch(showErrorSnackbar(data.messages[0]));
          }
          setSubmitting(false);
        })
        .catch(() => {
          dispatch(showErrorSnackbar("Something went wrong"));
          setSubmitting(false);
        });
    },
  });
  return (
    <Box>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <TextField
            fullWidth
            placeholder='Password'
            label='Password'
            name='password'
            type='password'
            value={formik.values.password || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password && formik.errors.password}
            disabled={formik.isSubmitting}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            fullWidth
            placeholder='Confirm Password'
            label='Confirm Password'
            name='confirmPassword'
            type='password'
            value={formik.values.confirmPassword || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            disabled={formik.isSubmitting}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row-reverse"}
        paddingTop={theme.spacing(2)}
        spacing={1}
      >
        <Grid item>
          <LoadingButton
            variant='contained'
            type='submit'
            loading={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            Save Changes
          </LoadingButton>
        </Grid>
        {showCancel && (
          <Grid item>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => onCancel && onCancel()}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ChangePasswordComp;
