import { createSelector } from "@reduxjs/toolkit";
import { RootSelector } from "../RootSelector";

export const editClientSelector = createSelector(RootSelector, (state) => state.EditClientSlice);
export const editClientActiveTabSelector = createSelector(
  editClientSelector,
  (state) => state.activeTab,
);

export const editClientIdSelector = createSelector(editClientSelector, (state) => state.clientId);

export const editClientTenantIdSelector = createSelector(
  editClientSelector,
  (state) => state.tenantId,
);

export const editClientAddEditApiKeyModalOpenSelector = createSelector(
  editClientSelector,
  (state) => state.addEditApiKeyModalOpen,
);
export const editClientAddEditApiKeyModalIdSelector = createSelector(
  editClientSelector,
  (state) => state.addEditApiKeyId,
);
