import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Menu,
  setActiveBreadCumb,
  setActiveMenu,
  setBreadCumbDisplayText,
} from "../../../Redux/Slice/Application/ApplicationSlice";
import { BreadcrumbsEnum } from "../../Base/BreadcrumbsBar";
import { useParams } from "react-router-dom";
import { GetTenantNameById } from "../../../Api/Tenants/TenantEndpoints";
import ClaimStatusBatchesDashboardBase from "./ClaimStatusBatches/ClaimStatusBatchesDashboardBase";
import RPAInsuranceBase from "./RPAInsurances/RPAInsuranceBase";
import { styled } from "@mui/material";

const FlexContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

const FlexItem = styled("div")`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TenantDashboard: FC = function () {
  const dispatch = useDispatch();
  const params = useParams();
  const tenantId = Number(params.tenantId);

  useEffect(() => {
    dispatch(setActiveMenu(Menu.Tenants));
    dispatch(setActiveBreadCumb(BreadcrumbsEnum.TenantDashboard));
    GetTenantNameById(tenantId).then(({ data }) => {
      if (data.succeeded) {
        dispatch(
          setBreadCumbDisplayText([
            { path: BreadcrumbsEnum.TenantInstance, replaceText: data.data },
          ]),
        );
      }
    });
  }, []);

  return (
    <FlexContainer>
      <FlexItem>
        <ClaimStatusBatchesDashboardBase tenantId={tenantId} />
      </FlexItem>
      <FlexItem>
        <RPAInsuranceBase tenantId={tenantId} />
      </FlexItem>
    </FlexContainer>
  );
};

export default TenantDashboard;
