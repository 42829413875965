import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  useTheme,
} from "@mui/material";
import { GetUserRoles, UpdateRoles } from "../../../Api/User/UserEndpoints";
import { RoleNamesResponse } from "../../../Models/ApiResponse/Role/RoleNamesResponse";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../Redux/Slice/Application/ApplicationSlice";
import { editUserSelector } from "../../../Redux/Slice/Users/EditUserSlice";
import { useAppDispatch } from "../../../Redux/Store";
import { MenuProps } from "../../Common/Static";
import { EditUserTabProps } from "./CommonProps";

const RolesInfoTab: FC<EditUserTabProps> = function ({ active }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const state = useSelector(editUserSelector);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [roles, setRoles] = useState<Array<RoleNamesResponse>>([]);
  const [selectedRoles, setSelectedRoles] = useState<Array<string>>([]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    setLoading(true);
    if (state.userId) {
      GetUserRoles(state.userId)
        .then(({ data: response }) => {
          if (response.succeeded) {
            setRoles(response.data.roles);
            setSelectedRoles(response.data.selectedRoles);
            setLoading(false);
          } else {
            dispatch(showErrorSnackbar(response.messages[0]));
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
          dispatch(showErrorSnackbar("Failed to load roles"));
        });
    }
  }, [state.userId]);

  const handleSave = () => {
    setSubmitting(true);
    UpdateRoles(state.userId, { userRoles: selectedRoles })
      .then(({ data: res }) => {
        if (res.succeeded) {
          dispatch(showSuccessSnackbar(res.messages[0]));
        } else {
          dispatch(showErrorSnackbar(res.messages[0]));
        }
        setSubmitting(false);
      })
      .catch(() => {
        dispatch(showErrorSnackbar("Failed to update roles."));
        setSubmitting(false);
      });
  };
  const getSkeleton = () => (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Skeleton
            variant='rounded'
            animation='wave'
            width='100%'
          >
            <FormControl>
              <InputLabel>Tenants</InputLabel>
              <Select fullWidth></Select>
            </FormControl>
          </Skeleton>
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row-reverse"}
        paddingTop={theme.spacing(2)}
      >
        <Button />
      </Grid>
    </>
  );
  return (
    <Box hidden={!active}>
      {loading ? (
        getSkeleton()
      ) : (
        <>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <FormControl
                fullWidth
                disabled={submitting}
              >
                <InputLabel id='roles-selection-label'>Roles</InputLabel>
                <Select
                  labelId='roles-selection-label'
                  id='roles-selection-checkbox'
                  multiple
                  fullWidth
                  disabled={submitting}
                  value={selectedRoles}
                  input={<OutlinedInput label='Tag' />}
                  renderValue={(selected) => {
                    return roles
                      .filter((r) => selected.indexOf(r.normalizedName) > -1)
                      .map((r) => r.name)
                      .join(", ");
                  }}
                  MenuProps={MenuProps}
                  onChange={handleChange}
                >
                  {roles.map((role, index) => (
                    <MenuItem
                      key={"Role_" + index}
                      value={role.normalizedName}
                    >
                      <Checkbox checked={selectedRoles.indexOf(role.normalizedName) > -1} />
                      <ListItemText primary={role.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            direction={"row-reverse"}
            paddingTop={theme.spacing(2)}
          >
            <Grid item>
              <LoadingButton
                variant='contained'
                type='submit'
                loading={submitting}
                onClick={handleSave}
              >
                Save Roles
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default RolesInfoTab;
