import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetMasterUserRecord } from "../../../Api/User/UserEndpoints";
import { MasterUserInfoResponse } from "../../../Models/ApiResponse/User/UserInfoResponse";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { EditUserState, EditUserTabs } from "../../State/User/EditUserState";
import { RootState } from "../../Store";
import { showErrorSnackbar } from "../Application/ApplicationSlice";

const fetchUserRecord = createAsyncThunk<MasterUserInfoResponse, string, { state: RootState }>(
  "editUser/fetchUserRecord",
  async (userId, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(ApiCallStatus.Loading));
    try {
      const userRecord = (await GetMasterUserRecord(userId)).data;
      if (userRecord.succeeded) {
        dispatch(setLoading(ApiCallStatus.Success));
        return userRecord.data;
      } else {
        dispatch(setLoading(ApiCallStatus.Error));
        dispatch(showErrorSnackbar(userRecord.messages[0]));
        return rejectWithValue(userRecord.messages[0]);
      }
    } catch (error) {
      dispatch(showErrorSnackbar("Something went wrong"));
      return rejectWithValue("Something went wrong");
    }
  },
);

const getInitialState = () => {
  return {
    activeTab: EditUserTabs.BasicUserInfo,
    loading: ApiCallStatus.NoStarted,
    userId: "",
    userRecord: {
      id: "",
      userName: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: null,
      profilePictureDataUrl: "",
      emailConfirmed: false,
      isActive: false,
      tenants: [],
      tenantClients: [],
      pin: ""
    },
  } as EditUserState;
};

const editUserSlice = createSlice({
  name: "editUser",
  initialState: getInitialState(),
  reducers: {
    setActiveTab: (state, action: PayloadAction<EditUserTabs>) => {
      state.activeTab = action.payload;
    },
    setLoading: (state, action: PayloadAction<ApiCallStatus>) => {
      state.loading = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setUserRecord: (state, action: PayloadAction<MasterUserInfoResponse>) => {
      state.userRecord = action.payload;
    },
    reset: () => {
      return { ...getInitialState() };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUserRecord.fulfilled, (state, action) => {
      state.userRecord = action.payload;
    });
  },
});

export { fetchUserRecord };
export const { setUserId, reset, setLoading, setActiveTab, setUserRecord } = editUserSlice.actions;
export const editUserSelector = (state: RootState) => state.EditUserSlice;
export default editUserSlice.reducer;
