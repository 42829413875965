import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { TenantAssociatedGridState } from "../../State/Tenant/TenantAssociatedGridState";
import { PaginatedResult } from "../../../Models/ApiResponse/Common/PaginatedResult";
import { TenantAssociatedUserResponse } from "../../../Models/ApiResponse/Tenant/TenantAssociatedUserResponse";
import { RootState } from "../../Store";
import { GetTenantAssociatedUsersGrid } from "../../../Api/Tenants/TenantEndpoints";
import {
  showErrorSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar,
} from "../Application/ApplicationSlice";
import { ToggleUserStatus } from "../../../Api/User/UserEndpoints";
import { IResult } from "../../../Models/ApiResponse/Common/IResult";

const getInitialState = (): TenantAssociatedGridState => {
  return {
    rows: [],
    status: ApiCallStatus.NoStarted,
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
    tenantId: 0,
    quickSearch: "",
  } as TenantAssociatedGridState;
};

export const toggleUserStatus = createAsyncThunk<
  void,
  { userId: string; activeUser: boolean },
  { state: RootState }
>("users/toggleUserStatus", async (_, thunk) => {
  const { dispatch } = thunk;
  try {
    dispatch(setLoading(true));
    const response = await ToggleUserStatus({ userId: _.userId, activateUser: _.activeUser });
    const res = response.data as IResult<string>;
    if (res.succeeded) {
      dispatch(
        showSuccessSnackbar(`User ${_.activeUser ? "activated" : "inactivated"} successfully`),
      );
    } else {
      dispatch(showInfoSnackbar(res.messages[0]));
    }
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(showErrorSnackbar("Something went wrong."));
    dispatch(setLoading(false));
  }
});

export const fetchPagedTenantUsers = createAsyncThunk<
  PaginatedResult<TenantAssociatedUserResponse>,
  { tenantId: number },
  { state: RootState }
>("tenants/fetchUsers", async (_, thunk) => {
  const { getState } = thunk;
  const state = getState().TenantAssociatedUsersGridSliceSlice;
  const response = await GetTenantAssociatedUsersGrid(
    _.tenantId,
    state.currentPage,
    state.pageSize,
    state.quickSearch,
  );
  return response.data as PaginatedResult<TenantAssociatedUserResponse>;
});

const TenantAssociatedUsersGridSliceSlice = createSlice({
  name: "tenantAssociatedUsersGridSliceSlice",
  initialState: getInitialState(),
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload + 1;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setQuickSearch: (state, action: PayloadAction<string>) => {
      state.quickSearch = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.status = action.payload ? ApiCallStatus.Loading : ApiCallStatus.Success;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchPagedTenantUsers.fulfilled, (state, action) => {
      if (action.payload.succeeded) {
        state.rows = action.payload.data;
        state.currentPage = action.payload.currentPage;
        state.totalCount = action.payload.totalCount;
        state.totalPages = action.payload.totalPages;
        state.status = ApiCallStatus.Success;
      } else {
        state.status = ApiCallStatus.Error;
      }
    });
    builder.addCase(fetchPagedTenantUsers.pending, (state) => {
      state.status = ApiCallStatus.Loading;
    });
    builder.addCase(fetchPagedTenantUsers.rejected, (state) => {
      state.rows = [];
      state.status = ApiCallStatus.Error;
      state.error = "Something went wrong";
    });
  },
});

export const tenantAssociatedUsersGridSelector = (state: RootState) =>
  state.TenantAssociatedUsersGridSliceSlice;
export const { setPage, setPageSize, setQuickSearch, setLoading } =
  TenantAssociatedUsersGridSliceSlice.actions;
export default TenantAssociatedUsersGridSliceSlice.reducer;
