import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Tab, Tabs, useTheme } from "@mui/material";
import {
  editClientActiveTabSelector,
  editClientSelector,
} from "../../../Redux/Selectors/Client/EditClientSelectors";
import { fetchClientById, setActiveTab } from "../../../Redux/Slice/Clients/EditClientSlice";
import { EditClientTabs } from "../../../Redux/State/Client/EditClientState";
import { useAppDispatch } from "../../../Redux/Store";
import BasicClientInfoTab from "./BasicClientInfoTab";
import ClientApiKeysTab from "./ClientApiKeysTab";
import DocumentKPITab from "./DepartmentKPITab";

const EditClientBase = function () {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const activeTab = useSelector(editClientActiveTabSelector);

  const { clientId, tenantId } = useSelector(editClientSelector);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setActiveTab(newValue + 1));
  };

  useEffect(() => {
    if (clientId && tenantId) {
      dispatch(fetchClientById());
    }
  }, [clientId, tenantId]);

  return (
    <Box p={theme.spacing(1)}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab - 1}
          onChange={handleChange}
        >
          <Tab label='Client Info'></Tab>
          <Tab label='Department KPI'></Tab>
          <Tab label='API Keys'></Tab>
        </Tabs>
      </Box>
      <Box paddingTop={theme.spacing(2)}>
        <BasicClientInfoTab active={activeTab == EditClientTabs.BasicClientInfo} />
        <DocumentKPITab active={activeTab == EditClientTabs.DepartmentKPI} />
        <ClientApiKeysTab active={activeTab == EditClientTabs.ApiKeys} />
      </Box>
    </Box>
  );
};

export default EditClientBase;
