import React from "react";
import { Container, Grid, Link, Typography } from "@mui/material";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { useNavigate } from "react-router";
import { clearAuthentication } from "../../Redux/Slice/AuthenticatedUser/AutheticatedUserSlice";
import { useAppDispatch } from "../../Redux/Store";

const UnAuthorized: React.FC = function () {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <Container>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingTop={20}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant='h1'>
            <ReportProblemRoundedIcon
              color='warning'
              fontSize='inherit'
            />
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant='h3'>403 - Access Forbidden</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant='h6'>
            {"You are not authorized to access this page. "}
            <Link
              href='#'
              onClick={() => {
                dispatch(clearAuthentication());
                navigate("/login", { replace: true });
              }}
            >
              Go to Login Page.
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UnAuthorized;
