import { GetUserLoginHistoryGridRequest } from "../../Models/ApiRequest/Admin/Dashboard/UserLoginHistoryRequest";
import { PaginatedResult } from "../../Models/ApiResponse/Common/PaginatedResult";
import { GetUserLoginHistoryGridResponse } from "../../Models/ApiResponse/Dashboard/GetUserLoginHistoryGridResponse";
import { httpClient } from "../APIBase";

export const GetUserLoginHistoryGrid = function (request: GetUserLoginHistoryGridRequest) {
  return httpClient.post<PaginatedResult<GetUserLoginHistoryGridResponse>>(
    "/admin/dashboard/user-login-history",
    request,
  );
};
