import React, { FC, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { forgotPassword } from "../../Api/User/UserEndpoints";
import { closeAddModal } from "../../Redux/Slice/Clients/ClientsGridSlice";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Slice/Application/ApplicationSlice";
import { FieldError, ValidationErrorCatcher } from "../../Api/Common/ApiHelpers";
import { useAppDispatch } from "../../Redux/Store";
import { useNavigate } from "react-router";
import { clearAuthentication } from "../../Redux/Slice/AuthenticatedUser/AutheticatedUserSlice";
import { RedirectToLogin } from "../../Util/AuthHelper";
import LoginIcon from "@mui/icons-material/Login";

// Define validation schema for the forgot password form
const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().required("Enter email address").email("Enter valid email address"),
});

const ForgotPassword: FC = function () {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [message] = useState("");
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: (
      form: { email: string },
      { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
    ) => {
      forgotPassword(form.email)
        .then(({ data: res }) => {
          setSubmitting(false);
          if (res.succeeded) {
            dispatch(closeAddModal());
            navigate("/passwordResetConfirmation");
            dispatch(showSuccessSnackbar("Email Send Successfully"));
          } else {
            dispatch(showErrorSnackbar(res.messages[0]));
          }
        })
        .catch(
          ValidationErrorCatcher((fieldErrors: Array<FieldError>) => {
            fieldErrors.map((fieldError) => {
              formik.setFieldError(fieldError.fieldName, fieldError.errors[0]);
            });
          }),
        )
        .catch(() => {
          setSubmitting(false);
          dispatch(showErrorSnackbar("Failed to create client."));
        });
    },
  });

  const handleLogout = () => {
    dispatch(clearAuthentication());
    RedirectToLogin();
  };

  const { touched, errors, handleChange, values, handleSubmit, isSubmitting } = formik;

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        background: theme.palette.background.default,
      }}
    >
      <IconButton
        style={{
          position: "absolute",
          right: "8px",
          top: "8px",
          background: "#fff",
          boxShadow: "0 0 12px rgba(0, 0, 0, 0.08)",
        }}
        color='inherit'
        onClick={handleLogout}
      >
        <LoginIcon />
      </IconButton>

      <Container>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          paddingTop={20}
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={5}
          >
            <Paper
              elevation={3}
              style={{ padding: "46px 30px 50px" }}
            >
              <Grid container>
                <Grid
                  item
                  container
                  spacing={2}
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid item>
                    <Typography
                      variant='h4'
                      style={{ fontSize: "30px" }}
                    >
                      Forgot Password
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{ marginBottom: "20px", color: "#6c6565", textAlign: "center" }}
                    >
                      Enter your email address to reset your password.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      placeholder='Email'
                      name='email'
                      variant='outlined'
                      label='Email'
                      error={touched.email && !!errors.email}
                      //   helperText={touched.email && errors.email}
                      onChange={handleChange}
                      value={values.email}
                    />
                    <FormHelperText
                      style={{
                        margin: touched.email && errors.email ? "5px 0 0" : undefined,
                        fontSize: touched.email && errors.email ? "14px" : undefined,
                        color: touched.email && errors.email ? "#d32f2f" : undefined,
                      }}
                    >
                      {touched.email && errors.email}
                    </FormHelperText>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <LoadingButton
                      variant='contained'
                      loading={isSubmitting}
                      onClick={() => {
                        handleSubmit();
                      }}
                      style={{ width: "100%", height: "54px" }}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                  {message && (
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography color={theme.palette.success.main}>{message}</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ForgotPassword;
