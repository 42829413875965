import { LoginRequestData, TenantClientRequest } from "../../Models/ApiRequest/LoginRequestData";
import { IResult } from "../../Models/ApiResponse/Common/IResult";
import { LoginResponse } from "../../Models/ApiResponse/LoginResponse";
import { TenantClientResponse } from "../../Models/ApiResponse/Tenant/TenantReponse";
import { httpClient } from "../APIBase";
import { CatchServerError } from "../Common/ApiHelpers";

export const LoginRequest = function (data: LoginRequestData): Promise<LoginResponse> {
  return new Promise<LoginResponse>((resolve, reject) => {
    httpClient
      .post("identity/token/login", data)
      .then((response) => {
        if (response.status == 200) {
          const res = response.data as IResult<LoginResponse>;
          if (res.succeeded) {
            resolve(res.data);
          } else {
            reject(res.messages);
          }
        }
      })
      .catch(CatchServerError(reject));
  });
};

export const SelectTenantClientRequest = function (data: TenantClientRequest) {
  return httpClient.post<IResult<TenantClientResponse>>("identity/token/tenant-client", data);
};
