import React, { FC, useEffect } from "react";
import { Button, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { BreadcrumbsEnum } from "../../Components/Base/BreadcrumbsBar";
import SearchTextField from "../../Components/Common/SearchTextField/SearchTextField";
import PaperCard from "../../Components/Common/Styled/PaperCard";
import ClientFailedConfigurationModal from "../../Components/Shared/ClientFailedConfigurationModal";
import AddEditTenantForm from "../../Components/Tenant/AddEditTenantForm";
import TenantsGrid from "../../Components/Tenant/TenantsGrid";
import {
  Menu,
  setActiveBreadCumb,
  setActiveMenu,
} from "../../Redux/Slice/Application/ApplicationSlice";
import {
  fetchPagedTenants,
  setLoadMode,
  setQuickSearch,
  showAddEditModal,
  tenantGridSelector,
} from "../../Redux/Slice/Tenants/TenantsGridSlice";
import { useAppDispatch, useStateSelector } from "../../Redux/Store";
import { LoadMode } from "../../Models/ApiResponse/Tenant/TenantInfo";

const ViewTenants: FC = function () {
  const dispatch = useAppDispatch();
  const state = useStateSelector(tenantGridSelector);

  useEffect(() => {
    dispatch(setActiveMenu(Menu.Tenants));
    dispatch(setActiveBreadCumb(BreadcrumbsEnum.Tenants));
  }, []);

  return (
    <>
      {state.isAddEditModalOpen && (
        <AddEditTenantForm
          tenantId={state.tenantId}
          onSuccess={() => {
            dispatch(fetchPagedTenants());
          }}
        />
      )}
      {state.failedConfigurationModalOpen && (
        <ClientFailedConfigurationModal
          tenantId={state.tenantId}
          clientId={0}
        />
      )}
      <PaperCard>
        <Stack
          pb={1}
          direction='row'
          spacing={1}
        >
          <Typography
            variant='h5'
            style={{
              flex: 1,
            }}
          >
            Tenants
          </Typography>
          <SearchTextField
            label='Search'
            placeholder='Search'
            onSearch={(text) => {
              dispatch(setQuickSearch(text));
              dispatch(fetchPagedTenants());
            }}
            size='small'
          />
          <ToggleButtonGroup
            color='primary'
            value={state.loadMode}
            size='small'
            onChange={() => {
              if (state.loadMode === LoadMode.All) {
                dispatch(setLoadMode(LoadMode.ActiveOnly));
              } else {
                dispatch(setLoadMode(LoadMode.All));
              }
              dispatch(fetchPagedTenants());
            }}
          >
            <ToggleButton value={LoadMode.All}>All</ToggleButton>
            <ToggleButton value={LoadMode.ActiveOnly}>Active</ToggleButton>
          </ToggleButtonGroup>
          <Button
            variant='contained'
            onClick={() => {
              dispatch(showAddEditModal(0));
            }}
          >
            Create
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              dispatch(fetchPagedTenants());
            }}
          >
            Reload
          </Button>
        </Stack>
        <TenantsGrid />
      </PaperCard>
    </>
  );
};

export default ViewTenants;
