import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { GetAllAuthTypesRequest } from "../../Api/AuthTypes/AuthTypesEndpoints";
import { SaveClient } from "../../Api/Client/ClientEndpoints";
import { FieldError, ValidationErrorCatcher } from "../../Api/Common/ApiHelpers";
import { GetAllTenantsBasicInfo } from "../../Api/Tenants/TenantEndpoints";
import { GetAllAuthTypeResponse } from "../../Models/ApiResponse/AuthType/GetAllAuthTypeResponse";
import { BasicTenantInfo } from "../../Models/ApiResponse/Tenant/BasicTenantInfo";
import { ApplicationFeatureEnum } from "../../Models/Enums/Applications";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Slice/Application/ApplicationSlice";
import { closeAddModal, fetchClients } from "../../Redux/Slice/Clients/ClientsGridSlice";
import { useAppDispatch } from "../../Redux/Store";
import { MenuProps } from "../Common/Static";
import { SpecialtyEnum } from "../../Models/Enums/SpecialtyEnum";

const validationSchema = Yup.object().shape({
  tenantId: Yup.number().required("Tenant is required"),
  clientName: Yup.string().required("Client Name is required"),
  clientCode: Yup.string()
    .required("Client Code is required")
    .max(10, "Client code must be less than 10 digits"),
  features: Yup.array().min(1, "Features is required"),
});

const AddClientModal = function () {
  const dispatch = useAppDispatch();
  const { tenantId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [tenants, setTenants] = useState<Array<BasicTenantInfo>>([]);
  const [serviceTypes, setServiceTypes] = useState<Array<GetAllAuthTypeResponse>>([]);

  const loadTenants = () => {
    setLoading(true);
    GetAllTenantsBasicInfo()
      .then(({ data: res }) => {
        if (res.succeeded) {
          setTenants(res.data);
        } else {
          dispatch(showErrorSnackbar(res.messages[0]));
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        dispatch(showErrorSnackbar("Failed to load tenants"));
      });
  };

  const loadServiceTypes = (tenantId: number) => {
    if (tenantId > 0) {
      GetAllAuthTypesRequest(tenantId)
        .then(({ data: response }) => {
          if (response.succeeded) {
            setServiceTypes(response.data);
          } else {
            dispatch(showErrorSnackbar(response.messages[0]));
          }
        })
        .catch(() => {
          dispatch(showErrorSnackbar("Failed to load service types."));
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      tenantId: 0,
      clientName: "",
      clientCode: "",
      phoneNumber: "",
      faxNumber: "",
      serviceTypes: Array<number>(),
      features: Array<number>(),
      sourceSystemId: 0,
      specialty: Array<number>(),
      taxId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (form, { setSubmitting }) => {
      SaveClient({
        tenantId: form.tenantId,
        clientId: 0,
        clientName: form.clientName,
        clientCode: form.clientCode,
        phoneNumber: Number(form.phoneNumber),
        faxNumber: Number(form.faxNumber),
        authTypes: form.serviceTypes,
        features: form.features,
        sourceSystemId: form.sourceSystemId,
        specialitIds: form.specialty,
        taxId: Number(form.taxId),
      })
        .then(({ data: res }) => {
          setSubmitting(false);
          if (res.succeeded) {
            dispatch(closeAddModal());
            dispatch(
              fetchClients({
                tenantId: Number(tenantId),
              }),
            );
            dispatch(showSuccessSnackbar("Client Added Successfully."));
          } else {
            dispatch(showErrorSnackbar(res.messages[0]));
          }
        })
        .catch(
          ValidationErrorCatcher((fieldErrors: Array<FieldError>) => {
            fieldErrors.map((fieldError) => {
              formik.setFieldError(fieldError.fieldName, fieldError.errors[0]);
            });
          }),
        )
        .catch(() => {
          setSubmitting(false);
          dispatch(showErrorSnackbar("Failed to create client."));
        });
    },
  });

  const { handleChange, handleBlur, touched, errors, values, isSubmitting, handleSubmit } = formik;

  const tenantSelected = values.tenantId > 0;

  useEffect(() => {
    loadTenants();
  }, []);
  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle>Add Client</DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <FormControl
                fullWidth
                disabled={isSubmitting || loading}
              >
                <InputLabel>Tenant</InputLabel>
                <Select
                  name='tenantId'
                  placeholder='Tenant'
                  label='Tenant'
                  fullWidth
                  value={values.tenantId}
                  onChange={(x) => {
                    loadServiceTypes(Number(x.target.value));
                    handleChange(x);
                  }}
                  onBlur={handleBlur}
                  error={touched.tenantId && !!errors.tenantId}
                >
                  <MenuItem
                    value=''
                    disabled
                  >
                    <em>Select</em>
                  </MenuItem>
                  {tenants.map((tenant, index) => (
                    <MenuItem
                      key={"tenant_" + index}
                      value={tenant.tenantId}
                    >
                      <ListItemText primary={tenant.tenantName} />
                    </MenuItem>
                  ))}
                </Select>
                {touched.tenantId && !!errors.tenantId && (
                  <FormHelperText error={true}>{errors.tenantId}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                name='clientName'
                placeholder='Client Name'
                label='Client Name'
                fullWidth
                value={values.clientName}
                error={touched.clientName && !!errors.clientName}
                helperText={touched.clientName && errors.clientName}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={loading || isSubmitting || !tenantSelected}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                name='clientCode'
                placeholder='Client Code'
                label='Client Code'
                fullWidth
                value={values.clientCode}
                error={touched.clientCode && !!errors.clientCode}
                helperText={touched.clientCode && errors.clientCode}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={loading || isSubmitting || !tenantSelected}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                name='phoneNumber'
                placeholder='Phone Number'
                label='Phone Number'
                fullWidth
                value={values.phoneNumber}
                error={touched.phoneNumber && !!errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={loading || isSubmitting || !tenantSelected}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                name='faxNumber'
                placeholder='Fax Number'
                label='Fax Number'
                fullWidth
                value={values.faxNumber}
                error={touched.faxNumber && !!errors.faxNumber}
                helperText={touched.faxNumber && errors.faxNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={loading || isSubmitting || !tenantSelected}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <FormControl
                fullWidth
                disabled={loading || isSubmitting || !tenantSelected}
              >
                <InputLabel>Service Types</InputLabel>
                <Select
                  name='serviceTypes'
                  placeholder='Service Types'
                  label='Service Types'
                  multiple
                  value={values.serviceTypes}
                  MenuProps={MenuProps}
                  renderValue={(selected) => {
                    return serviceTypes
                      .filter((r) => selected.indexOf(r.id) > -1)
                      .map((r) => r.name)
                      .join(", ");
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.serviceTypes && !!errors.serviceTypes}
                >
                  <MenuItem
                    value=''
                    disabled
                  >
                    <em>Select</em>
                  </MenuItem>
                  {serviceTypes.map((serviceType, index) => (
                    <MenuItem
                      key={"serviceType_" + index}
                      value={serviceType.id}
                    >
                      <Checkbox checked={values.serviceTypes.indexOf(serviceType.id) > -1} />
                      <ListItemText primary={serviceType.name} />
                    </MenuItem>
                  ))}
                </Select>
                {touched.serviceTypes && !!errors.serviceTypes && (
                  <FormHelperText error={true}>{errors.serviceTypes}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <FormControl
                fullWidth
                disabled={loading || isSubmitting || !tenantSelected}
              >
                <InputLabel>Features</InputLabel>
                <Select
                  name='features'
                  placeholder='Features'
                  label='Features'
                  multiple
                  value={values.features}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.features && !!errors.features}
                >
                  <MenuItem
                    value=''
                    disabled
                  >
                    <em>Select</em>
                  </MenuItem>
                  {Object.keys(ApplicationFeatureEnum).map((key, index) => {
                    if (!isNaN(Number(key))) {
                      return (
                        <MenuItem
                          value={key}
                          key={index}
                        >
                          {ApplicationFeatureEnum[Number(key)]}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
                {touched.features && !!errors.features && (
                  <FormHelperText error={true}>{errors.features}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={6}
            >
              <FormControl
                fullWidth
                disabled={loading || isSubmitting || !tenantSelected}
              >
                <InputLabel>Specialty</InputLabel>
                <Select
                  name='specialty'
                  placeholder='Specialty'
                  label='Specialty'
                  multiple={true}
                  value={values.specialty}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.specialty && !!errors.specialty}
                >
                  <MenuItem
                    value=''
                    disabled
                  >
                    <em>Select</em>
                  </MenuItem>
                  {Object.keys(SpecialtyEnum)
                    .filter((key) => !isNaN(Number(key))) // Filter out non-numeric keys
                    .sort((a, b) =>
                      SpecialtyEnum[Number(a)].localeCompare(SpecialtyEnum[Number(b)]),
                    ) // Sort alphabetically
                    .map((key, index) => (
                      <MenuItem
                        value={key}
                        key={index}
                      >
                        {SpecialtyEnum[Number(key)]}
                      </MenuItem>
                    ))}
                </Select>
                {touched.specialty && !!errors.specialty && (
                  <FormHelperText error={true}>{errors.specialty}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={6}
            >
              <TextField
                name='taxId'
                placeholder='Tax ID'
                label='Tax ID'
                fullWidth
                value={values.taxId}
                error={touched.taxId && !!errors.taxId}
                helperText={touched.taxId && errors.taxId}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={loading || isSubmitting || !tenantSelected}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          disabled={isSubmitting}
          color='secondary'
          onClick={() => {
            dispatch(closeAddModal());
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant='contained'
          loading={isSubmitting}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddClientModal;
