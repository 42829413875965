import React, {
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { DeleteClientApiKey } from "../../../Api/Client/ClientEndpoints";
import {
  GetApiIntegrationEnumDisplayName,
} from "../../../Models/Enums/Applications";
import {
  editClientAddEditApiKeyModalIdSelector,
  editClientAddEditApiKeyModalOpenSelector,
  editClientIdSelector,
  editClientTenantIdSelector,
} from "../../../Redux/Selectors/Client/EditClientSelectors";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../Redux/Slice/Application/ApplicationSlice";
import {
  clear,
  clientApiKeysGridSelector,
  fetchClientApiKeys,
  setPage,
  setPageSize,
} from "../../../Redux/Slice/Clients/ClientApiKeysGridSlice";
import {
  openAddEditClientApiKeyModal,
} from "../../../Redux/Slice/Clients/EditClientSlice";
import { useAppDispatch } from "../../../Redux/Store";
import AitDataGrid from "../../Common/DataGrid/AitDataGrid";
import ConfirmModal from "../../Common/Modals/ConfirmModal";
import AddEditClientApiKeyModal from "./ClientApiKey/AddEditClientApiKeyModal";
import { EditClientInfoTabProps } from "./CommonProps";


const ClientApiKeysTab: React.FC<EditClientInfoTabProps> = function ({ active }) {
  const clientId = useSelector(editClientIdSelector);
  const addEditApiKeyModalOpen = useSelector(editClientAddEditApiKeyModalOpenSelector);
  const addEditApiKeyModalId = useSelector(editClientAddEditApiKeyModalIdSelector);
  const tenantId = useSelector(editClientTenantIdSelector);
  const state = useSelector(clientApiKeysGridSelector);
  const dispatch = useAppDispatch();
  const initialConfirmDelete = {
    show: false,
    message: "",
    tenantId: 0,
    clientId: 0,
    apiIntegrationKeyId: 0,
  };
  const [confirmDelete, setConfirmDelete] = useState<{
    show: boolean;
    message: string;
    tenantId: number;
    clientId: number;
    apiIntegrationKeyId: number;
  }>(initialConfirmDelete);

  const columns: Array<GridColDef> = [
    {
      field: "id",
      headerName: "Id",
      width: 70,
      sortable: false,
      flex: 0,
    },
    {
      field: "apiIntegrationId",
      headerName: "Type",
      width: 70,
      sortable: false,
      flex: 0,
      valueGetter: ({ value }) => {
        return GetApiIntegrationEnumDisplayName(Number(value));
      },
    },

    {
      field: "apiKey",
      headerName: "Api Key",
      flex: 0,
      sortable: false,
    },
    {
      field: "apiUrl",
      headerName: "Api Url",
      flex: 1,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      sortable: false,
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            label='Edit'
            key='editClient'
            showInMenu
            onClick={() => {
              dispatch(openAddEditClientApiKeyModal(Number(id.toString())));
            }}
          />,
          <GridActionsCellItem
            label='Delete'
            key='deleteClient'
            showInMenu
            onClick={() => {
              setConfirmDelete({
                show: true,
                message: `Are you sure want to delete api Key ${row["id"]}?`,
                tenantId: tenantId,
                clientId: clientId,
                apiIntegrationKeyId: Number(id),
              });
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    if (clientId > 0) {
      dispatch(fetchClientApiKeys());
    }

    return () => {
      dispatch(clear());
    };
  }, [clientId, state.currentPage, state.pageSize]);

  const confirmModal = () => (
    <ConfirmModal
      title='Confirm'
      message={confirmDelete?.message}
      onNo={() => {
        setConfirmDelete(initialConfirmDelete);
      }}
      onYes={() => {
        setConfirmDelete(initialConfirmDelete);
        DeleteClientApiKey(
          confirmDelete.tenantId,
          confirmDelete.clientId,
          confirmDelete.apiIntegrationKeyId,
        )
          .then(({ data: res }) => {
            if (res.succeeded) {
              dispatch(showSuccessSnackbar(res.messages[0]));
            } else {
              dispatch(showErrorSnackbar(res.messages[0]));
            }
            dispatch(fetchClientApiKeys());
          })
          .catch(() => {
            dispatch(showErrorSnackbar("Failed to delete client api key."));
          });
      }}
      show={confirmDelete.show}
    />
  );

  return (
    <Box hidden={!active} >
      {addEditApiKeyModalOpen && <AddEditClientApiKeyModal apiKeyId={addEditApiKeyModalId || 0} />}
      {confirmDelete.show && confirmModal()}
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <Button
          variant='contained'
          onClick={() => dispatch(openAddEditClientApiKeyModal(null))}
        >
          Create
        </Button>
      </Box>
      <div
        style={{
          height: "200px",
        }}
      >
        <AitDataGrid
          columns={columns}
          keyColumnName='id'
          state={state}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </div>
    </Box>
  );
};

export default ClientApiKeysTab;
