import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import Badge from "@mui/material/Badge";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import {
  fetchPagedTenants,
  setPage,
  setPageSize,
  showAddEditModal,
  showFailedConfigurationModal,
  tenantGridSelector,
} from "../../Redux/Slice/Tenants/TenantsGridSlice";
import { useAppDispatch, useStateSelector } from "../../Redux/Store";
import AitDataGrid from "../Common/DataGrid/AitDataGrid";

const TenantsGrid: FC = function () {
  const dispatch = useAppDispatch();

  const state = useStateSelector(tenantGridSelector);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchPagedTenants());
  }, [state.currentPage, state.pageSize]);

  const columns: GridColDef[] = [
    {
      field: "tenantId",
      headerName: "ID",
      width: 70,
      sortable: false,
      flex: 0,
    },
    {
      field: "tenantIdentifier",
      headerName: "Tenant Identifier",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "tenantName",
      headerName: "Tenant Name",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "serverName",
      headerName: "Database Server",
      width: 150,
      sortable: false,
    },
    {
      field: "databaseName",
      headerName: "Database Name",
      width: 150,
      sortable: false,
      flex: 1,
    },
    {
      field: "createdByName",
      headerName: "Created By",
      type: "string",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      type: "dateTime",
      width: 100,
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "lastModifiedByName",
      headerName: "Modified By",
      type: "string",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "lastModifiedOn",
      headerName: "Modified On",
      type: "dateTime",
      width: 100,
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "isActive",
      headerName: "Is Active",
      type: "boolean",
      width: 100,
      sortable: false,
    },
    {
      field: "tenantFailedConfigurationCount",
      headerName: "Failed Configurations",
      align: "center",
      renderCell: ({ row }) => {
        const failedCount = Number(row["tenantFailedConfigurationCount"]);
        return (
          <Badge
            badgeContent={failedCount.toString()}
            color='error'
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(showFailedConfigurationModal(Number(row["tenantId"])));
            }}
          >
            <ErrorIcon />
          </Badge>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      sortable: false,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            label='Edit'
            key='editClient'
            showInMenu
            onClick={() => {
              dispatch(showAddEditModal(Number(id)));
            }}
          />,
          <GridActionsCellItem
            label='View Clients'
            key='viewClients'
            showInMenu
            onClick={() => {
              navigate(`/admin/tenants/${id}/clients`);
            }}
          />,
          <GridActionsCellItem
            label='View Dashboard'
            key='viewTenantDashboard'
            showInMenu
            onClick={() => {
              navigate(`/admin/tenants/${id}/dashboard`);
            }}
          />,
          <GridActionsCellItem
            label='View Users'
            key='viewTenantUsers'
            showInMenu
            onClick={() => {
              navigate(`/admin/tenants/${id}/users`);
            }}
          />,
          <GridActionsCellItem
            label='View Users Login History'
            key='viewTenantUsersLoginHistory'
            showInMenu
            onClick={() => {
              navigate(`/admin/tenants/${id}/users-login-history`);
            }}
          />,
        ];
      },
    },
  ];

  return (
    <div
      style={{
        height: "500px",
      }}
    >
      <AitDataGrid
        state={state}
        columns={columns}
        setPage={setPage}
        setPageSize={setPageSize}
        keyColumnName='tenantId'
      />
    </div>
  );
};

export default TenantsGrid;
