export enum ServerType {
  Database = 1,
}

export const GetServerTypeDisplayName = (value: string | number): string => {
  const typeValue = Number(value);
  switch (typeValue) {
    case ServerType.Database:
      return "Database";
  }
  return "";
};

export enum AuthenticationType {
  Windows = 1,
  Credentials = 2,
}

export const GetAuthenticationTypeDisplayName = (value: string | number): string => {
  const typeValue = Number(value);
  switch (typeValue) {
    case AuthenticationType.Windows:
      return "Windows";
    case AuthenticationType.Credentials:
      return "Credentials";
  }
  return "";
};
