import React, { FC } from "react";
import { Avatar, AvatarProps } from "@mui/material";


function stringAvatar(name: string) {
  const child =
    name.indexOf(" ") > -1
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : name.split(" ")[0][0];
  return child.toUpperCase();
}

export type NamedAvatarProps = AvatarProps & {
  displayText: string | "";
};

const NamedAvatar: FC<NamedAvatarProps> = function (props) {
  const { displayText, ...newProps } = props;
  const avtarProps: AvatarProps = { ...newProps };
  if (displayText) {
    const strAvtar = stringAvatar(props.displayText);
    avtarProps.children = strAvtar;
    avtarProps.sx = { ...props.sx, bgcolor: "#dd3375" };
  }
  return <Avatar {...avtarProps} />;
};

export default NamedAvatar;
