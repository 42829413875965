export enum ApplicationFeatureEnum {
  Authorizations = 1,
  ClaimStatus = 2,
  ChargeEntry = 3,
}
export enum ApiIntegrationEnum {
  SelfPayEligibility = 1,
}

export const GetApiIntegrationEnumDisplayName = (value: string | number) => {
  switch (value) {
    case ApiIntegrationEnum.SelfPayEligibility:
      return "Self Pay Eligibility";
      break;
  }
};
