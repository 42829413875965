import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetUsersPagination, ToggleUserStatus } from "../../../Api/User/UserEndpoints";
import { IResult } from "../../../Models/ApiResponse/Common/IResult";
import { PaginatedResult } from "../../../Models/ApiResponse/Common/PaginatedResult";
import { UserInfoResponse } from "../../../Models/ApiResponse/User/UserInfoResponse";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { UsersGridState } from "../../State/User/UsersGridState";
import { RootState } from "../../Store";
import {
  showErrorSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar,
} from "../Application/ApplicationSlice";

const initialState: UsersGridState = {
  rows: [],
  status: ApiCallStatus.NoStarted,
  currentPage: 1,
  pageSize: 10,
  totalCount: 0,
  totalPages: 0,
  isAddEditModalOpen: false,
  userId: "",
  quickSearch: "",
};

const fetchPagedUser = createAsyncThunk<
  PaginatedResult<UserInfoResponse>,
  void,
  { state: RootState }
>("users/GetPagedAsync", async (_, thunk) => {
  const { getState } = thunk;
  const state = getState().UserGridSlice;
  const response = await GetUsersPagination(state.currentPage, state.pageSize, state.quickSearch);
  return response.data as PaginatedResult<UserInfoResponse>;
});

const toggleUserStatus = createAsyncThunk<
  void,
  { userId: string; activeUser: boolean },
  { state: RootState }
>("users/toggleUserStatus", async (_, thunk) => {
  const { dispatch } = thunk;
  try {
    dispatch(setLoading(true));
    const response = await ToggleUserStatus({ userId: _.userId, activateUser: _.activeUser });
    const res = response.data as IResult<string>;
    if (res.succeeded) {
      dispatch(
        showSuccessSnackbar(`User ${_.activeUser ? "activated" : "inactivated"} successfully`),
      );
    } else {
      dispatch(showInfoSnackbar(res.messages[0]));
    }
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(showErrorSnackbar("Something went wrong."));
    dispatch(setLoading(false));
  }
});

const UserGridSlice = createSlice({
  name: "userGrid",
  initialState: initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload + 1;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    showAddEditModal: (state, action: PayloadAction<string>) => {
      state.isAddEditModalOpen = true;
      state.userId = action.payload;
    },
    hideAddEditModal: (state) => {
      state.isAddEditModalOpen = false;
      state.userId = "";
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.status = action.payload ? ApiCallStatus.Loading : ApiCallStatus.Success;
    },
    setQuickSearch: (state, action: PayloadAction<string>) => {
      state.quickSearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPagedUser.fulfilled, (state, action) => {
        if (action.payload.succeeded) {
          state.rows = action.payload.data;
          state.currentPage = action.payload.currentPage;
          state.totalCount = action.payload.totalCount;
          state.totalPages = action.payload.totalPages;
          state.status = ApiCallStatus.Success;
        } else {
          state.status = ApiCallStatus.Error;
        }
      })
      .addCase(fetchPagedUser.pending, (state) => {
        state.status = ApiCallStatus.Loading;
      })
      .addCase(fetchPagedUser.rejected, (state) => {
        state.rows = [];
        state.status = ApiCallStatus.Error;
        state.error = "Something went wrong";
      });
  },
});

export { fetchPagedUser, toggleUserStatus };
export const {
  setPage,
  setPageSize,
  showAddEditModal,
  hideAddEditModal,
  setLoading,
  setQuickSearch,
} = UserGridSlice.actions;
export const usersGridSelector = (state: RootState) => state.UserGridSlice;
export default UserGridSlice.reducer;
