import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import ApplicationSlice from "./Slice/Application/ApplicationSlice";
import AutheticatedUserSlice from "./Slice/AuthenticatedUser/AutheticatedUserSlice";
import clientApiKeysGridSlice from "./Slice/Clients/ClientApiKeysGridSlice";
import ClientGridSlice from "./Slice/Clients/ClientsGridSlice";
import EditClientSlice from "./Slice/Clients/EditClientSlice";
import abortedBatchesGridSlice from "./Slice/TenantDashboard/ClaimStatusBatches/AbortedBatchesGridSlice";
import failedConfigurationGridSlice from "./Slice/FailedConfiguration/FailedConfigurationGridSlice";
import PermissionGridSlice from "./Slice/Roles/PermissionGridSlice";
import RolesGridSlice from "./Slice/Roles/RolesGridSlice";
import ServerGridSlice from "./Slice/Servers/ServerGridSlice";
import TenantGridSlice from "./Slice/Tenants/TenantsGridSlice";
import AddUserSlice from "./Slice/Users/AddUserSlice";
import EditUserSlice from "./Slice/Users/EditUserSlice";
import UserGridSlice from "./Slice/Users/UserGridSlice";
import CompletedBatchesGridSlice from "./Slice/TenantDashboard/ClaimStatusBatches/CompletedBatchesGridSlice";
import InProgressBatchesGridSlice from "./Slice/TenantDashboard/ClaimStatusBatches/InProgressBatchesGridSlice";
import deletedBatchesGridSlice from "./Slice/TenantDashboard/ClaimStatusBatches/DeletedBatchesGridSlice";
import userLoginGridHistorySlice from "./Slice/Dashboard/UserLoginHistorySlice";
import ActiveRPAInsuranceGridSlice from "./Slice/TenantDashboard/RPAInsurance/ActiveRPAInsuranceGridSlice";
import InActiveRPAInsuranceGridSlice from "./Slice/TenantDashboard/RPAInsurance/InActiveRPAInsuranceGridSlice";
import TenantAssociatedUsersGridSliceSlice from "./Slice/Tenants/TenantAssociatedUsersGridSlice";
import TenantUserLoginGridHistorySlice from "./Slice/Tenants/TenantUserLoginGridHistorySlice";
import claimStatusBatchHistoriesGridSlice from "./Slice/TenantDashboard/ClaimStatusBatchHistories/ClaimStatusBatchHistoriesSlice";

// ...
const store = configureStore({
  reducer: {
    ApplicationSlice,
    AutheticatedUserSlice,
    ServerGridSlice,
    TenantGridSlice,
    UserGridSlice,
    AddUserSlice,
    EditUserSlice,
    RolesGridSlice,
    PermissionGridSlice,
    ClientGridSlice,
    EditClientSlice,
    clientApiKeysGridSlice,
    failedConfigurationGridSlice,
    abortedBatchesGridSlice,
    CompletedBatchesGridSlice,
    InProgressBatchesGridSlice,
    deletedBatchesGridSlice,
    ActiveRPAInsuranceGridSlice,
    InActiveRPAInsuranceGridSlice,
    userLoginGridHistorySlice,
    TenantAssociatedUsersGridSliceSlice,
    TenantUserLoginGridHistorySlice,
    claimStatusBatchHistoriesGridSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useStateSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
