import { HttpStatusCode } from "axios";
import { ConvertToCamelCase } from "../../Util/Helpers";

const CatchServerError = (reject: any) => {
  return (response: any) => {
    if (response?.status === 500) {
      reject(["Something went wrong."]);
    }
    reject(response);
  };
};

export type FieldError = {
  fieldName: string;
  errors: Array<string>;
};

const ValidationErrorCatcher = (callback: (fieldErrors: Array<FieldError>) => void) => {
  return function (error: any) {
    if (
      error?.response?.status == HttpStatusCode.BadRequest &&
      error?.response?.data?.title == "One or more validation errors occurred."
    ) {
      const errorResponseData = error?.response?.data?.errors;
      const retVal = new Array<FieldError>();
      if (Object.keys(errorResponseData).length > 0) {
        Object.keys(errorResponseData).map((fieldName) => {
          retVal.push({
            fieldName: ConvertToCamelCase(fieldName),
            errors: errorResponseData[fieldName],
          });
        });
      }

      callback(retVal);
    } else {
      return Promise.reject(error);
    }
  };
};

export { CatchServerError, ValidationErrorCatcher };
