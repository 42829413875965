import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../Redux/Store";
import SearchTextField from "../../../Common/SearchTextField/SearchTextField";
import AitDataGrid from "../../../Common/DataGrid/AitDataGrid";
import { GridColDef } from "@mui/x-data-grid";
import {
  fetchClaimStatusBatchHistories,
  setPage,
  setPageSize,
  setSortModel,
  claimStatusBatchHistoriesGridSelector,
} from "../../../../Redux/Slice/TenantDashboard/ClaimStatusBatchHistories/ClaimStatusBatchHistoriesSlice";
import { useSelector } from "react-redux";
import PaperCard from "../../../Common/Styled/PaperCard";

export type ClaimStatusBatchHistoriesGridProps = {
  tenantId: number;
  claimStatusBatchId: number;
};

const ClaimStatusBatchHistoriesGrid: React.FC<ClaimStatusBatchHistoriesGridProps> = function (
  props,
) {
  const { tenantId, claimStatusBatchId } = props;

  const [quickSearch, setQuickSearch] = useState<string>("");

  const theme = useTheme();

  const state = useSelector(claimStatusBatchHistoriesGridSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      fetchClaimStatusBatchHistories({
        tenantId: tenantId,
        claimStatusBatchId: claimStatusBatchId,
        quickSearch,
      }),
    );
  }, [
    state.currentPage,
    state.pageSize,
    state.sortModel.field,
    state.sortModel.order,
    quickSearch,
  ]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 80,
      sortable: true,
      flex: 0,
      type: "string",
      resizable: true,
    },
    {
      field: "claimStatusBatchId",
      headerName: "Claim Status Batch Id",
      width: 80,
      sortable: true,
      flex: 0,
      type: "number",
      resizable: true,
    },
    {
      field: "clientInsuranceId",
      headerName: "Client Insurance Id",
      width: 80,
      sortable: true,
      flex: 0,
      type: "number",
    },
    {
      field: "authTypeId",
      headerName: "Auth Type Id",
      width: 30,
      sortable: true,
      flex: 0,
      type: "number",
    },
    {
      field: "assignedClientRpaConfigurationId",
      headerName: "Assigned Client Rpa Configuration Id",
      width: 50,
      sortable: true,
      flex: 0,
      type: "number",
    },
    {
      field: "assignedDateTimeUtc",
      headerName: "Assigned Date",
      type: "dateTime",
      width: 150,
      flex: 0,
      sortable: true,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "assignedToIpAddress",
      headerName: "Assigned To IP Address",
      width: 100,
      sortable: true,
      flex: 0,
      type: "string",
    },
    {
      field: "assignedToHostName",
      headerName: "Assigned To Host Name",
      width: 150,
      sortable: true,
      flex: 0,
      type: "string",
    },
    {
      field: "assignedToRpaCode",
      headerName: "Assigned To RPA Code",
      width: 100,
      sortable: true,
      flex: 0,
      type: "string",
    },
    {
      field: "clientId",
      headerName: "Client Id",
      width: 30,
      sortable: true,
      flex: 0,
      type: "number",
    },
    {
      field: "assignedToRpaLocalProcessIds",
      headerName: "Assigned To RPA Local Process Ids",
      width: 30,
      sortable: true,
      flex: 0,
      type: "number",
    },
    {
      field: "completedDateTimeUtc",
      headerName: "Completed Date",
      type: "dateTime",
      width: 100,
      flex: 0,
      sortable: true,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "abortedOnUtc",
      headerName: "Aborted On",
      type: "dateTime",
      width: 100,
      flex: 0,
      sortable: true,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "abortedReason",
      headerName: "Aborted Reason",
      width: 100,
      sortable: true,
      flex: 0,
      type: "string",
    },
    {
      field: "reviewedOnUtc",
      headerName: "Reviewed On",
      type: "dateTime",
      width: 100,
      flex: 1,
      sortable: true,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "reviewedBy",
      headerName: "Reviewed By",
      width: 100,
      sortable: true,
      flex: 0,
      type: "string",
    },
    {
      field: "allClaimStatusesResolvedOrExpired",
      headerName: "All Claim Statuses Resolved Or Expired",
      width: 30,
      sortable: true,
      flex: 0,
      type: "boolean",
    },
    {
      field: "createdOn",
      headerName: "Created On",
      type: "dateTime",
      width: 150,
      flex: 0,
      sortable: true,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
      sortable: true,
      flex: 0,
      type: "string",
    },
    {
      field: "lastModifiedOn",
      headerName: "Last Modified On",
      type: "dateTime",
      width: 150,
      flex: 0,
      sortable: true,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "lastModifiedBy",
      headerName: "Last Modified By",
      width: 100,
      sortable: true,
      flex: 0,
      type: "string",
    },
  ];

  return (
    <PaperCard
      variant='elevation'
      style={{
        padding: theme.spacing(2),
      }}
    >
      <Stack
        pb={1}
        direction='row'
        spacing={1}
      >
        <Typography
          variant='h6'
          style={{
            flex: 1,
          }}
        >
          Claim Status Batch Histories
        </Typography>
        <SearchTextField
          label='Search'
          placeholder='Search'
          onSearch={(text) => {
            setQuickSearch(text);
          }}
          size='small'
          searchText={quickSearch}
        />
        <Button
          variant='contained'
          color='secondary'
          onClick={() => {
            dispatch(
              fetchClaimStatusBatchHistories({
                tenantId: tenantId,
                claimStatusBatchId: claimStatusBatchId,
                quickSearch,
              }),
            );
          }}
        >
          Reload
        </Button>
      </Stack>
      <Box
        height='400px'
        minHeight='300px'
      >
        <AitDataGrid
          keyColumnName='id'
          columns={columns}
          setPage={setPage}
          setPageSize={setPageSize}
          sortMode='server'
          state={state}
          sortModel={state.sortModel}
          defaultSortModel={{
            field: "lastModifiedOn",
            order: "desc",
          }}
          onSortModelChange={(field, order) => {
            dispatch(setSortModel({ field, order }));
          }}
        />
      </Box>
    </PaperCard>
  );
};

export default ClaimStatusBatchHistoriesGrid;
