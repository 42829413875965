import React, { FC, useState } from "react";
import { Box, Stepper, Step, StepLabel, useTheme, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { addUserSelector } from "../../../Redux/Slice/Users/AddUserSlice";
import { AddUserStep, StepStatus } from "../../../Redux/State/User/AddUserState";
import UserInfoStep from "./UserInfoStep";
import RolesInfoStep from "./RolesInfoStep";
import TenantAndClientStep from "./TenantAndClientStep";
import SummaryStep from "./SummaryStep";
import { useLeavePrompt } from "../../../hooks/useLeavePrompt";

const AddUserForm: FC = function () {
  const { activeStep, steps } = useSelector(addUserSelector);
  const theme = useTheme();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  useLeavePrompt(showDialog);
  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep - 1}
          >
            <Step
              key={"step_" + AddUserStep.UserInfo}
              active={activeStep == AddUserStep.UserInfo}
              completed={
                steps[AddUserStep.UserInfo] == StepStatus.Completed &&
                activeStep != AddUserStep.UserInfo
              }
            >
              <StepLabel error={steps[AddUserStep.UserInfo] == StepStatus.Error}>
                User Info
              </StepLabel>
            </Step>
            <Step
              key={"step_" + AddUserStep.RolesInfo}
              active={activeStep == AddUserStep.RolesInfo}
              completed={
                steps[AddUserStep.RolesInfo] == StepStatus.Completed &&
                activeStep != AddUserStep.RolesInfo
              }
            >
              <StepLabel error={steps[AddUserStep.RolesInfo] == StepStatus.Error}>Roles</StepLabel>
            </Step>
            <Step
              key={"step_" + AddUserStep.TenantAndClientAssignment}
              active={activeStep == AddUserStep.TenantAndClientAssignment}
              completed={
                steps[AddUserStep.TenantAndClientAssignment] == StepStatus.Completed &&
                activeStep != AddUserStep.TenantAndClientAssignment
              }
            >
              <StepLabel error={steps[AddUserStep.TenantAndClientAssignment] == StepStatus.Error}>
                Tenant and Clients
              </StepLabel>
            </Step>
            <Step
              key={"step_" + AddUserStep.Summary}
              active={activeStep == AddUserStep.Summary}
              completed={steps[AddUserStep.Summary] == StepStatus.Completed}
            >
              <StepLabel error={steps[AddUserStep.Summary] == StepStatus.Error}>Summary</StepLabel>
            </Step>
          </Stepper>
        </Grid>
      </Grid>
      <Grid
        paddingX={theme.spacing(4)}
        paddingTop={theme.spacing(4)}
        container
        justifyContent={"center"}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
        >
          <UserInfoStep
            active={activeStep == AddUserStep.UserInfo}
            onUnsavedChange={(show) => setShowDialog(show)}
          />
          <RolesInfoStep active={activeStep == AddUserStep.RolesInfo} />
          <TenantAndClientStep active={activeStep == AddUserStep.TenantAndClientAssignment} />
          <SummaryStep active={activeStep == AddUserStep.Summary} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddUserForm;
