import React, { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import { FieldError, ValidationErrorCatcher } from "../../Api/Common/ApiHelpers";
import { AddEditServer, GetServerInfo } from "../../Api/Server/ServerEndpoitns";
import { ServerInfo } from "../../Models/ApiResponse/Server/ServerInfo";
import { AuthenticationType, ServerType } from "../../Models/Enums/Server";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Slice/Application/ApplicationSlice";
import { hideAddEditModal } from "../../Redux/Slice/Servers/ServerGridSlice";

type AddEditServerFormProps = {
  serverId: number;
  onSuccess?(): void;
};

const validationSchema = Yup.object().shape({
  serverName: Yup.string()
    .required("Server Name is required.")
    .max(50, "Server Name character limit exceeds"),
  serverAddress: Yup.string().required("Server Address is required."),
  serverType: Yup.number().required("Server Type is required"),
  authenticationType: Yup.number().required("Server Type is required"),
  username: Yup.string().when("authenticationType", (authenticationType, schema) => {
    if (authenticationType[0] == AuthenticationType.Credentials) {
      return schema.required("Username is required");
    }
    return schema;
  }),
  password: Yup.string().when("authenticationType", (authenticationType, schema) => {
    if (authenticationType[0] == AuthenticationType.Credentials) {
      return schema.required("Password is required");
    }
    return schema;
  }),
});

const AddEditServerForm: FC<AddEditServerFormProps> = function (props: AddEditServerFormProps) {
  const dispatch = useDispatch();
  const [formLoading, setFormLoading] = useState(true);
  const isEditMode = !!props.serverId;

  // Load data if edit mode
  useEffect(() => {
    if (isEditMode) {
      GetServerInfo(Number(props.serverId)).then(({ data }) => {
        if (data.succeeded) {
          formik.setValues({
            serverId: data.data.serverId,
            serverName: data.data.serverName,
            serverAddress: data.data.serverAddress,
            serverType: data.data.serverType,
            authenticationType: data.data.authenticationType,
            username: data.data.username,
            password: data.data.password,
          });
        }
        setFormLoading(false);
      });
    } else {
      setFormLoading(false);
    }
  }, []);

  const addEditFormInitialValues = {
    serverName: "",
    serverAddress: "",
    serverType: null,
    authenticationType: null,
    username: "",
    password: "",
  } as ServerInfo;

  const formik = useFormik({
    initialValues: addEditFormInitialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const reqObj = { ...values, serverId: isEditMode ? props.serverId : 0 };
      AddEditServer(reqObj)
        .then(({ data }) => {
          if (data.succeeded) {
            const msg = isEditMode ? "Server Saved Successfully" : "Server Added Successfully";
            dispatch(showSuccessSnackbar(msg));
            dispatch(hideAddEditModal());
            if (props.onSuccess) props.onSuccess();
          } else {
            dispatch(showErrorSnackbar(data.messages[0]));
          }
        })
        .catch(
          ValidationErrorCatcher((fieldErrors: Array<FieldError>) => {
            fieldErrors.map((fieldError) => {
              formik.setFieldError(fieldError.fieldName, fieldError.errors[0]);
            });
          }),
        )
        .catch(() => {
          dispatch(showErrorSnackbar("Something went wrong"));
        });
    },
  });

  const getSkeleton = () => (
    <>
      <DialogContent>
        <Box pt={1}>
          <Grid
            container
            spacing={2}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid
              item
              xs={12}
            >
              {" "}
              <Skeleton
                variant='rounded'
                animation='wave'
                width='100%'
              >
                <TextField fullWidth />
              </Skeleton>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {" "}
              <Skeleton
                variant='rounded'
                animation='wave'
                width='100%'
              >
                <TextField fullWidth />
              </Skeleton>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {" "}
              <Skeleton
                variant='rounded'
                animation='wave'
                width='100%'
              >
                <TextField fullWidth />
              </Skeleton>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {" "}
              <Skeleton
                variant='rounded'
                animation='wave'
                width='100%'
              >
                <TextField fullWidth />
              </Skeleton>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {" "}
              <Skeleton
                variant='rounded'
                animation='wave'
                width='100%'
              >
                <TextField fullWidth />
              </Skeleton>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {" "}
              <Skeleton
                variant='rounded'
                animation='wave'
                width='100%'
              >
                <TextField fullWidth />
              </Skeleton>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Skeleton
          variant='rectangular'
          animation='wave'
        >
          <Button />
          {""}
          <Button />
        </Skeleton>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>{isEditMode ? "Edit" : "Add"} Server</DialogTitle>
      {formLoading ? (
        getSkeleton()
      ) : (
        <>
          <DialogContent>
            <Box pt={1}>
              <Grid
                container
                spacing={2}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    placeholder='Server Name'
                    fullWidth
                    name='serverName'
                    variant='outlined'
                    label='Server Name'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.serverName || ""}
                    error={formik.touched.serverName && !!formik.errors.serverName}
                    helperText={formik.touched.serverName && formik.errors.serverName}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    placeholder='Server Address'
                    fullWidth
                    name='serverAddress'
                    variant='outlined'
                    label='Server Address'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.serverAddress || ""}
                    error={formik.touched.serverAddress && !!formik.errors.serverAddress}
                    helperText={formik.touched.serverAddress && formik.errors.serverAddress}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControl
                    fullWidth
                    disabled={formik.isSubmitting}
                  >
                    <InputLabel>Server Type</InputLabel>
                    <Select
                      name='serverType'
                      fullWidth
                      label='Server Type'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.serverType || null}
                      error={formik.touched.serverType && !!formik.errors.serverType}
                    >
                      <MenuItem
                        value=''
                        disabled
                      >
                        <em>Select</em>
                      </MenuItem>
                      {Object.keys(ServerType).map((key, index) => {
                        if (!isNaN(Number(key))) {
                          return (
                            <MenuItem
                              value={key}
                              key={index}
                            >
                              {ServerType[Number(key)]}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                    {formik.touched.serverType && !!formik.errors.serverType && (
                      <FormHelperText error={true}>{formik.errors.serverType}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControl fullWidth>
                    <InputLabel>Authentication Type</InputLabel>
                    <Select
                      name='authenticationType'
                      fullWidth
                      label='Authentication Type'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.authenticationType || null}
                      error={
                        formik.touched.authenticationType && !!formik.errors.authenticationType
                      }
                      disabled={formik.isSubmitting}
                    >
                      <MenuItem
                        value=''
                        disabled
                      >
                        <em>Select</em>
                      </MenuItem>
                      {Object.keys(AuthenticationType).map((key, index) => {
                        if (!isNaN(Number(key))) {
                          return (
                            <MenuItem
                              value={key}
                              key={index}
                            >
                              {AuthenticationType[Number(key)]}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                    {formik.touched.authenticationType && !!formik.errors.authenticationType && (
                      <FormHelperText error={true}>
                        {formik.errors.authenticationType}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    placeholder='Username'
                    fullWidth
                    name='username'
                    variant='outlined'
                    label='Username'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username || ""}
                    error={formik.touched.username && !!formik.errors.username}
                    helperText={formik.touched.username && formik.errors.username}
                    disabled={
                      Number(formik.values.authenticationType) !== AuthenticationType.Credentials ||
                      formik.isSubmitting
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    placeholder='Password'
                    fullWidth
                    name='password'
                    variant='outlined'
                    label='Password'
                    type='password'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password || ""}
                    error={formik.touched.password && !!formik.errors.password}
                    helperText={formik.touched.password && formik.errors.password}
                    disabled={
                      Number(formik.values.authenticationType) !== AuthenticationType.Credentials ||
                      formik.isSubmitting
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={() => dispatch(hideAddEditModal())}
            >
              Cancel
            </Button>
            <LoadingButton
              variant='contained'
              type='submit'
              color='success'
              loading={formik.isSubmitting}
              onClick={() => formik.handleSubmit()}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default AddEditServerForm;
