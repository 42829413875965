import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";


export interface ConfirmModalProps {
  title: string;
  show: any;
  message: string;
  onNo(): void;
  onYes(): void;
  yesText?: string;
  noText?: string;
}

const ConfirmModal: FC<ConfirmModalProps> = function (props: ConfirmModalProps) {
  return (
    <Dialog
      open={props.show}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onNo}
          variant='contained'
          color='secondary'
        >
          {props.noText || "No"}
        </Button>
        <Button
          onClick={props.onYes}
          variant='contained'
          color='primary'
        >
          {props.yesText || "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
