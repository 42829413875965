import React, { FC } from "react";

import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { editUserSelector } from "../../../Redux/Slice/Users/EditUserSlice";
import ChangePasswordComp from "../CommonComponents/ChangePasswordComp";
import { EditUserTabProps } from "./CommonProps";

const ChangePasswordTab: FC<EditUserTabProps> = function ({ active }) {
  const { userId } = useSelector(editUserSelector);
  return (
    <Box hidden={!active}>
      <ChangePasswordComp userId={userId} />
    </Box>
  );
};

export default ChangePasswordTab;
