import { Box, Button, Paper, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { fetchInActiveRpaInsurance } from "../../../../Redux/Slice/TenantDashboard/RPAInsurance/InActiveRPAInsuranceGridSlice";
import { useAppDispatch } from "../../../../Redux/Store";
import { fetchActiveRpaInsurance } from "../../../../Redux/Slice/TenantDashboard/RPAInsurance/ActiveRPAInsuranceGridSlice";
import ActiveRPAInsurancesGrid from "./ActiveRPAInsurancesGrid";
import InActiveRPAInsurancesGrid from "./InActiveRPAInsurancesGrid";
import SearchTextField from "../../../Common/SearchTextField/SearchTextField";

enum ActiveTabEnum {
  Active = 1,
  InActive = 2,
}

export type RPAInsuranceBaseProps = {
  tenantId: number;
};

const RPAInsuranceBase: React.FC<RPAInsuranceBaseProps> = function ({ tenantId }) {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState<ActiveTabEnum>(ActiveTabEnum.Active);
  const [quickSearch, setQuickSearch] = useState<string>("");
  const dispatch = useAppDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setQuickSearch("");
    setActiveTab(newValue);
  };

  return (
    <div>
      <Paper
        variant='elevation'
        style={{
          padding: theme.spacing(2),
        }}
      >
        <Stack
          pb={1}
          direction='row'
          spacing={1}
        >
          <Typography
            variant='h6'
            style={{
              flex: 1,
            }}
          >
            RPA Insurances
          </Typography>
          <SearchTextField
            label='Search'
            placeholder='Search'
            onSearch={(text) => {
              setQuickSearch(text);
            }}
            size='small'
            searchText={quickSearch}
          />
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              if (activeTab === ActiveTabEnum.InActive) {
                dispatch(fetchInActiveRpaInsurance({ tenantId, quickSearch }));
              } else {
                dispatch(fetchActiveRpaInsurance({ tenantId, quickSearch }));
              }
            }}
          >
            Reload
          </Button>
        </Stack>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={handleChange}
          >
            <Tab
              label='Active'
              value={ActiveTabEnum.Active}
            />
            <Tab
              label='InActive'
              value={ActiveTabEnum.InActive}
            />
          </Tabs>
        </Box>
        <Box paddingTop={theme.spacing(2)}>
          {activeTab === ActiveTabEnum.Active && (
            <ActiveRPAInsurancesGrid
              tenantId={tenantId}
              quickSearch={quickSearch}
            />
          )}
          {activeTab === ActiveTabEnum.InActive && (
            <InActiveRPAInsurancesGrid
              tenantId={tenantId}
              quickSearch={quickSearch}
            />
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default RPAInsuranceBase;
