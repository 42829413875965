import React, { FC, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box, FormControl, IconButton, InputAdornment, OutlinedInput } from "@mui/material";

export type SearchTextFieldProps = {
  placeholder: string;
  label: string;
  size: "medium" | "small";
  searchText?: string;
  onSearch(text: string): void;
};

const SearchTextField: FC<SearchTextFieldProps> = function (props: SearchTextFieldProps) {
  const [search, setSearch] = useState("");

  const doSearch = () => {
    props?.onSearch(search);
  };

  useEffect(() => {
    doSearch();
  }, [search]);

  useEffect(() => {
    setSearch(props.searchText || "");
  }, [props.searchText]);

  return (
    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
      <FormControl
        sx={{ width: "25ch" }}
        variant='outlined'
      >
        <OutlinedInput
          size={props.size}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label={props.label}
                edge='end'
                onClick={() => {
                  doSearch();
                }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          placeholder={props.placeholder}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </FormControl>
    </Box>
  );
};

export default SearchTextField;
