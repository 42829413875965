import React from "react";
import { Box, CircularProgress } from "@mui/material";

const ApplicationLoader = function () {
  return (
    <Box
      bgcolor='#fff'
      position={"fixed"}
      display={"flex"}
      height={"100vh"}
      width={"100vw"}
      top={0}
      left={0}
      bottom={0}
      right={0}
      zIndex={1203}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <CircularProgress color='inherit' />
    </Box>
  );
};

export default ApplicationLoader;
