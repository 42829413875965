import { GetClientByIdResponse } from "../../../Models/ApiResponse/Client/ClientByIdResponse";
import { ApiCallStatus } from "../Common/GenericApiState";

export enum EditClientTabs {
  BasicClientInfo = 1,
  DepartmentKPI = 2,
  ApiKeys = 3,
}

export interface EditClientState {
  activeTab: EditClientTabs;
  tenantId: number;
  clientId: number;
  addEditApiKeyModalOpen: boolean;
  addEditApiKeyId: number | null;
  data?: GetClientByIdResponse | null;
  loading: ApiCallStatus;
}
