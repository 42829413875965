import React, { FC, useEffect } from "react";

import { useSelector } from "react-redux";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useAppDispatch } from "../../../../Redux/Store";
import {
  abortedBatchesGridSelector,
  fetchAbortedBatchesClaims,
  setPage,
  setPageSize,
  setSortModel,
} from "../../../../Redux/Slice/TenantDashboard/ClaimStatusBatches/AbortedBatchesGridSlice";
import AitDataGrid from "../../../Common/DataGrid/AitDataGrid";
import {
  DeleteClaimStatusBatch,
  UnassignClaimStatusBatch,
} from "../../../../Api/Tenants/TenantDashboardEndpoints";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../../Redux/Slice/Application/ApplicationSlice";
import { useNavigate } from "react-router";

export type AbortedBatchesGrid = {
  tenantId: number;
  quickSearch: string;
};

const AbortedBatchesGrid: FC<AbortedBatchesGrid> = function ({ tenantId, quickSearch }) {
  const state = useSelector(abortedBatchesGridSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 20,
      sortable: false,
      flex: 0,
      type: "string",
    },
    {
      field: "clientCode",
      headerName: "Client Code",
      width: 80,
      flex: 1,
      sortable: true,
    },
    {
      field: "batchNumber",
      headerName: "Batch Number",
      width: 80,
      flex: 1,
      sortable: true,
    },
    {
      field: "lineItems",
      headerName: "Line Items",
      width: 20,
      sortable: true,
    },
    {
      field: "payer",
      headerName: "Payer",
      width: 100,
      flex: 1,
      sortable: true,
    },
    {
      field: "rpa",
      headerName: "RPA",
      width: 100,
      flex: 1,
      sortable: true,
    },
    {
      field: "abortedOnUtc",
      headerName: "Aborted On",
      type: "dateTime",
      width: 200,
      flex: 1,
      sortable: true,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "abortedReason",
      headerName: "Aborted Reason",
      width: 100,
      flex: 1,
      sortable: true,
    },
    {
      field: "assignedDateTimeUtc",
      headerName: "Assigned Date",
      type: "dateTime",
      width: 100,
      flex: 1,
      sortable: true,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "assignedToIpAddress",
      headerName: "Assigned To Ip Address",
      width: 150,
      flex: 1,
      sortable: true,
    },
    {
      field: "assignedToHostName",
      headerName: "Assigned To Host Name",
      width: 150,
      sortable: true,
    },
    {
      field: "assignedToRpaLocalProcessIds",
      headerName: "Assigned To Rpa Local ProcessIds",
      width: 80,
      sortable: true,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      type: "dateTime",
      width: 100,
      flex: 1,
      sortable: true,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "lastModifiedOn",
      headerName: "Last Modified On",
      type: "dateTime",
      width: 100,
      flex: 1,
      sortable: true,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "completedDateTimeUtc",
      headerName: "Completed Date",
      type: "dateTime",
      width: 100,
      flex: 1,
      sortable: true,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      sortable: false,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            label='View Histories'
            key='viewHistories'
            showInMenu
            onClick={() => {
              navigate(`/admin/tenants/${tenantId}/claimStatusBatch/${Number(id)}/histories`);
            }}
          />,
          <GridActionsCellItem
            label='Unassign'
            key='unassignBatch'
            showInMenu
            onClick={() => {
              UnassignClaimStatusBatch(tenantId, Number(id))
                .then((data) => {
                  if (data.data.succeeded) {
                    dispatch(fetchAbortedBatchesClaims({ tenantId, quickSearch }));
                    dispatch(
                      showSuccessSnackbar("Claim status batch " + id + " unassigned successfully."),
                    );
                  }
                })
                .catch(() => {
                  dispatch(showErrorSnackbar("Failed to unassign claim status batch Id: " + id));
                });
            }}
          />,
          <GridActionsCellItem
            label='Delete'
            key='deleteBatch'
            showInMenu
            onClick={() => {
              DeleteClaimStatusBatch(tenantId, Number(id))
                .then((data) => {
                  if (data.data.succeeded) {
                    dispatch(fetchAbortedBatchesClaims({ tenantId, quickSearch }));
                    dispatch(
                      showSuccessSnackbar("Claim status batch " + id + " deleted successfully."),
                    );
                  } else {
                    dispatch(showErrorSnackbar("Failed to delete claim status batch Id: " + id));
                  }
                })
                .catch(() => {
                  dispatch(showErrorSnackbar("Failed to delete claim status batch Id: " + id));
                });
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchAbortedBatchesClaims({ tenantId, quickSearch }));
  }, [
    state.currentPage,
    state.pageSize,
    state.sortModel.field,
    state.sortModel.order,
    quickSearch,
  ]);

  return (
    <>
      <div
        style={{
          height: "400px",
        }}
      >
        <AitDataGrid
          keyColumnName='id'
          columns={columns}
          state={state}
          setPage={setPage}
          setPageSize={setPageSize}
          sortMode='server'
          sortModel={state.sortModel}
          defaultSortModel={{
            field: "lastModifiedOn",
            order: "desc",
          }}
          onSortModelChange={(field, order) => {
            dispatch(setSortModel({ field, order }));
          }}
        />
      </div>
    </>
  );
};

export default AbortedBatchesGrid;
