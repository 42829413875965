import React, { FC, useEffect } from "react";
import { Grid } from "@mui/material";
import { BreadcrumbsEnum } from "../../Components/Base/BreadcrumbsBar";
import PaperCard from "../../Components/Common/Styled/PaperCard";
import AddUserForm from "../../Components/User/AddUser/AddUserFormBase";
import {
  Menu,
  setActiveBreadCumb,
  setActiveMenu,
} from "../../Redux/Slice/Application/ApplicationSlice";
import { useAppDispatch } from "../../Redux/Store";

const AddUser: FC = function () {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveMenu(Menu.Users));
    dispatch(setActiveBreadCumb(BreadcrumbsEnum.CreateUser));
  }, []);
  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Grid
        item
        lg={6}
        md={7}
        xs={12}
        sm={12}
      >
        <PaperCard
          sx={{
            minHeight: "200px",
          }}
        >
          <AddUserForm />
        </PaperCard>
      </Grid>
    </Grid>
  );
};

export default AddUser;
