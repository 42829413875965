import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AdminBaseLayout from "../Components/Base/AdminBaseLayout";
import NotFound from "../Components/Common/NotFound";
import EditPermission from "../Components/Roles/EditPermissions";
import Login from "../Pages/Auth/Login";
import SelectTenantClient from "../Pages/Auth/SelectTenantClient";
import EditClient from "../Pages/Clients/EditClient";
import ViewClients from "../Pages/Clients/ViewClients";
import Dashboard from "../Pages/Dashboard/Dashboard";
import ViewRoles from "../Pages/Roles/ViewRoles";
import ViewServers from "../Pages/Servers/ViewServers";
import ViewTenants from "../Pages/Tenants/ViewTenants";
import AddUser from "../Pages/Users/AddUser";
import EditUser from "../Pages/Users/EditUser";
import ViewUsers from "../Pages/Users/ViewUsers";
import ProtectedRoute from "./ProtectedRoutes/ProtectedRoute";
import ConfirmationEmail from "../Pages/Auth/ConfirmEmail";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import PasswordResetConfirmation from "../Pages/Auth/PasswordResetConfirmation";
import TenantDashboard from "../Components/Tenant/Dashboard/TenantDashboard";
import UnAuthorized from "../Components/Common/UnAuthorized";
import ViewTenantUsers from "../Pages/Tenants/ViewTenantUsers";
import ViewTenantUserLoginHistory from "../Pages/Tenants/ViewTenantUserLoginHistory";
import ViewClaimStatusBatchHistories from "../Pages/Tenants/Dashboard/ViewClaimStatusBatchHistories";

const Router = function () {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={<Login />}
        ></Route>
        <Route
          path='/login'
          element={<Login />}
        />

        <Route
          path='/forgotPassword'
          element={<ForgotPassword />}
        />

        <Route
          path='/unAuthorized'
          element={<UnAuthorized />}
        />
        <Route
          path='/passwordResetConfirmation'
          element={<PasswordResetConfirmation />}
        />

        <Route
          path='/SelectTenantClient'
          element={
            <ProtectedRoute>
              <SelectTenantClient />
            </ProtectedRoute>
          }
        />
        <Route
          path='/confirmationemail'
          element={<ConfirmationEmail />}
        />
        <Route
          path='/admin'
          element={
            <ProtectedRoute>
              <AdminBaseLayout />
            </ProtectedRoute>
          }
        >
          <Route
            path='dashboard'
            element={<Dashboard />}
          />
          <Route path='servers'>
            <Route
              index
              element={<ViewServers />}
            />
          </Route>
          <Route path='tenants'>
            <Route
              index
              element={<ViewTenants />}
            />
            <Route path=':tenantId'>
              <Route
                path='dashboard'
                element={<TenantDashboard />}
              />
              <Route path='claimStatusBatch'>
                <Route path=':claimStatusBatchId'>
                  <Route
                    path='histories'
                    element={<ViewClaimStatusBatchHistories />}
                  />
                </Route>
              </Route>

              <Route path='users'>
                <Route
                  index
                  element={<ViewTenantUsers />}
                />
                <Route path=':userId'>
                  <Route
                    path='edit'
                    element={<EditUser />}
                  />
                </Route>
              </Route>
              <Route
                path='users-login-history'
                element={<ViewTenantUserLoginHistory />}
              />
              <Route path='clients'>
                <Route
                  index
                  element={<ViewClients />}
                />
                <Route path=':clientId'>
                  <Route
                    path='edit'
                    element={<EditClient />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path='users'>
            <Route
              index
              element={<ViewUsers />}
            />
            <Route path=':userId'>
              <Route
                path='edit'
                element={<EditUser />}
              />
            </Route>
            <Route
              path='create'
              element={<AddUser />}
            />
          </Route>
          <Route path='roles'>
            <Route
              index
              element={<ViewRoles />}
            />
            <Route
              path=':id/editPermission'
              element={<EditPermission />}
            />
          </Route>

          <Route
            path='*'
            element={<NotFound />}
          />
        </Route>
        <Route
          path='*'
          element={
            <Navigate
              to='/admin/'
              replace={true}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
