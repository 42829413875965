import React, { FC, useEffect } from "react";
import { Button } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ServerInfo } from "../../Models/ApiResponse/Server/ServerInfo";
import {
  GetAuthenticationTypeDisplayName,
  GetServerTypeDisplayName,
} from "../../Models/Enums/Server";
import { showErrorSnackbar } from "../../Redux/Slice/Application/ApplicationSlice";
import {
  fetchPagedSevers,
  serverGridSelector,
  setPage,
  setPageSize,
  showAddEditModal,
} from "../../Redux/Slice/Servers/ServerGridSlice";
import { useAppDispatch, useStateSelector } from "../../Redux/Store";
import AitDataGrid from "../Common/DataGrid/AitDataGrid";

const ServerGrid: FC = function () {
  const dispatch = useAppDispatch();

  const state = useStateSelector(serverGridSelector);

  const rednerEditButton = (props: GridRenderCellParams<ServerInfo, any>) => {
    // eslint-disable-next-line react/prop-types
    const { serverId } = props.row;
    return (
      <Button
        variant='outlined'
        onClick={() => {
          dispatch(showAddEditModal(serverId));
        }}
      >
        Edit
      </Button>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "serverId",
      headerName: "ID",
      width: 70,
      sortable: false,
      flex: 0,
    },
    {
      field: "serverName",
      headerName: "Server Name",
      width: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "serverAddress",
      headerName: "Server Address",
      width: 250,
      flex: 1,
      sortable: false,
    },
    {
      field: "serverType",
      headerName: "Server Type",
      type: "string",
      width: 120,
      sortable: false,
      valueGetter: ({ value }) => {
        return GetServerTypeDisplayName(value);
      },
      flex: 1,
    },
    {
      field: "authenticationType",
      headerName: "Authentication Type",
      type: "string",
      width: 150,
      valueGetter: ({ value }) => {
        return GetAuthenticationTypeDisplayName(value);
      },
      sortable: false,
      flex: 1,
    },
    {
      field: "username",
      headerName: "Username",
      type: "string",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "createdByName",
      headerName: "Created By",
      type: "string",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      type: "dateTime",
      width: 100,
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "lastModifiedByName",
      headerName: "Modified By",
      type: "string",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "lastModifiedOn",
      headerName: "Modified On",
      type: "dateTime",
      width: 100,
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "action",
      headerName: "Actions",
      type: "action",
      width: 100,
      flex: 1,
      sortable: false,
      renderCell: rednerEditButton,
    },
  ];

  useEffect(() => {
    dispatch(fetchPagedSevers());
  }, [state.currentPage, state.pageSize]);

  useEffect(() => {
    if (state.error) {
      dispatch(showErrorSnackbar(state.error));
    }
  }, [state.error]);

  return (
    <div
      style={{
        height: "500px",
      }}
    >
      <AitDataGrid
        state={state}
        keyColumnName='serverId'
        columns={columns}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export default ServerGrid;
