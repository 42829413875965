import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetClientById } from "../../../Api/Client/ClientEndpoints";
import { GetClientByIdResponse } from "../../../Models/ApiResponse/Client/ClientByIdResponse";
import { EditClientState, EditClientTabs } from "../../State/Client/EditClientState";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { RootState } from "../../Store";
import { showErrorSnackbar } from "../Application/ApplicationSlice";

export const fetchClientById = createAsyncThunk<GetClientByIdResponse, void, { state: RootState }>(
  "editClient/fetchClientById",
  async (_, thunk) => {
    const { getState, dispatch, rejectWithValue } = thunk;
    const state = getState().EditClientSlice;
    try {
      const response = await GetClientById(state.tenantId, state.clientId);
      if (!response.data.succeeded) {
        dispatch(showErrorSnackbar("Failed to load client"));
        return rejectWithValue(null);
      }
      return response.data.data as GetClientByIdResponse;
    } catch (error) {
      dispatch(showErrorSnackbar("Failed to load client"));
      return rejectWithValue(null);
    }
  },
);

const getInitialState = () => {
  return {
    activeTab: EditClientTabs.BasicClientInfo,
    clientId: 0,
    tenantId: 0,
    data: null,
    addEditApiKeyModalOpen: false,
    addEditApiKeyId: 0,
    loading: ApiCallStatus.NoStarted,
  } as EditClientState;
};

const editClientSlice = createSlice({
  name: "editClient",
  initialState: getInitialState(),
  reducers: {
    setActiveTab: (state, action: PayloadAction<EditClientTabs>) => {
      state.activeTab = action.payload;
    },
    setLoading: (state, action: PayloadAction<ApiCallStatus>) => {
      state.loading = action.payload;
    },
    setTenantAndClientId: (
      state,
      action: PayloadAction<{ clientId: number; tenantId: number }>,
    ) => {
      state.tenantId = action.payload.tenantId;
      state.clientId = action.payload.clientId;
    },
    openAddEditClientApiKeyModal: (state, action: PayloadAction<number | null>) => {
      state.addEditApiKeyModalOpen = true;
      state.addEditApiKeyId = action.payload ?? 0;
    },
    closeAddEditClientApiKeyModal: (state) => {
      state.addEditApiKeyModalOpen = false;
      state.addEditApiKeyId = 0;
    },
    clear: () => {
      return { ...getInitialState() };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchClientById.pending, (state) => {
        state.loading = ApiCallStatus.Loading;
      })
      .addCase(fetchClientById.fulfilled, (state, action: PayloadAction<GetClientByIdResponse>) => {
        state.loading = ApiCallStatus.Success;
        state.data = action.payload;
      })
      .addCase(fetchClientById.rejected, (state) => {
        state.loading = ApiCallStatus.Error;
      });
  },
});

export const {
  setActiveTab,
  setLoading,
  setTenantAndClientId,
  openAddEditClientApiKeyModal,
  closeAddEditClientApiKeyModal,
  clear,
} = editClientSlice.actions;
export default editClientSlice.reducer;
