import React, { FC } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { SaveRole } from "../../Api/Roles/RolesEndpoints";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Slice/Application/ApplicationSlice";
import { closeRoleModal, fetchPaginatedRoles } from "../../Redux/Slice/Roles/RolesGridSlice";
import { useAppDispatch } from "../../Redux/Store";

export interface AddEditRoleProps {
  roleId?: string;
  roleName?: string;
}

const validationSchema = Yup.object().shape({
  roleName: Yup.string()
    .required("Role Name is required.")
    .matches(/^[a-zA-Z0-9_-]+$/i, "Only numbers and Alphabets and (-,_) are allowed."),
});

const AddEditRoles: FC<AddEditRoleProps> = function ({ roleId, roleName }) {
  const dispatch = useAppDispatch();
  const isEditMode = !!roleId;

  const formik = useFormik({
    initialValues: {
      roleName: roleName || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      SaveRole({
        id: isEditMode ? roleId?.toString() : "",
        name: values.roleName,
      })
        .then(({ data: res }) => {
          setSubmitting(false);
          if (res.succeeded) {
            dispatch(showSuccessSnackbar("Role saved successfully"));
            dispatch(closeRoleModal());
            dispatch(fetchPaginatedRoles());
          } else {
            if (res.messages.length > 0) {
              setFieldError("roleName", res.messages[0]);
            }
          }
        })
        .catch(() => {
          dispatch(showErrorSnackbar("Failed to save role"));
        });
    },
  });

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>{isEditMode ? `Edit Role - ${roleId}` : "Add Role"}</DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <TextField
            fullWidth
            name='roleName'
            placeholder='Role Name'
            label='Role Name'
            disabled={formik.isSubmitting}
            value={formik.values.roleName}
            onChange={formik.handleChange}
            helperText={formik.touched.roleName && formik.errors.roleName}
            error={formik.touched.roleName && !!formik.errors.roleName}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='secondary'
          disabled={formik.isSubmitting}
          onClick={() => {
            dispatch(closeRoleModal());
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant='contained'
          loading={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditRoles;
