import React, { FC } from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { clearSnackbar, SnackbarMessageType } from "../../Redux/Slice/Application/ApplicationSlice";
import { useAppDispatch, useStateSelector } from "../../Redux/Store";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant='filled'
      {...props}
    />
  );
});

const GlobalSnackbar: FC = function () {
  const dispatch = useAppDispatch();

  const { snackbar } = useStateSelector((state) => state.ApplicationSlice);

  const handleClose = () => {
    dispatch(clearSnackbar());
  };

  const getComponent = (message: string, type: SnackbarMessageType) => {
    switch (type) {
      default:
      case SnackbarMessageType.Info:
        return (
          <Snackbar
            open={!!snackbar}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            message={message}
          />
        );
      case SnackbarMessageType.Success:
        return (
          <Snackbar
            open={!!snackbar}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity='success'
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        );
      case SnackbarMessageType.Error:
        return (
          <Snackbar
            open={!!snackbar}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity='error'
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        );
    }
  };

  return (
    <div className='snackbar-container'>
      {snackbar && getComponent(snackbar?.message, snackbar?.type)}
    </div>
  );
};

export default GlobalSnackbar;
