import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FieldError, ValidationErrorCatcher } from "../../../Api/Common/ApiHelpers";
import { GetAllRoles } from "../../../Api/Roles/RolesEndpoints";
import { GetAllTenantsBasicInfo, GetAllTenantsClients } from "../../../Api/Tenants/TenantEndpoints";
import { CreateUser } from "../../../Api/User/UserEndpoints";
import { CreateUserRequest } from "../../../Models/ApiRequest/Admin/User/CreateUserRequest";
import { RoleNamesResponse } from "../../../Models/ApiResponse/Role/RoleNamesResponse";
import { BasicTenantClientResponse } from "../../../Models/ApiResponse/Tenant/BasicTenantClientResponse";
import { BasicTenantInfo } from "../../../Models/ApiResponse/Tenant/BasicTenantInfo";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { AddUserState, AddUserStep, StepStatus, UserInfoStep } from "../../State/User/AddUserState";
import { RootState } from "../../Store";
import { showErrorSnackbar, showSuccessSnackbar } from "../Application/ApplicationSlice";

const initialState: AddUserState = {
  activeStep: AddUserStep.UserInfo,
  loading: ApiCallStatus.NoStarted,
  steps: {
    [AddUserStep.UserInfo]: StepStatus.NotStarted,
    [AddUserStep.RolesInfo]: StepStatus.NotStarted,
    [AddUserStep.TenantAndClientAssignment]: StepStatus.NotStarted,
    [AddUserStep.Summary]: StepStatus.NotStarted,
  },
  userInfoStep: {
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: null,
    autoConfirmEmail: true,
    isActive: true,
    password: "",
    fieldErrors: [],
  },
  rolesInfoStep: {
    roles: [],
    selectedRoles: [],
  },
  tenantAndClientAssignmentStep: {
    tenants: [],
    selectedTenants: [],
    clients: [],
    selectedClients: [],
  },
};

const fetchRoles = createAsyncThunk<Array<RoleNamesResponse>, void, { state: RootState }>(
  "adduser/fetchRoles",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await GetAllRoles();

      if (!response.data.succeeded) {
        dispatch(showErrorSnackbar(response.data.messages[0]));
        return rejectWithValue(response.data.messages[0]);
      }
      return response.data.data;
    } catch (error) {
      dispatch(showErrorSnackbar("Something went wrong"));
      return rejectWithValue("Something went wrong");
    }
  },
);

const fetchTenants = createAsyncThunk<Array<BasicTenantInfo>, void, { state: RootState }>(
  "adduser/fetchTenants",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await GetAllTenantsBasicInfo();

      if (!response.data.succeeded) {
        dispatch(showErrorSnackbar(response.data.messages[0]));
        return rejectWithValue(response.data.messages[0]);
      }
      return response.data.data;
    } catch (error) {
      dispatch(showErrorSnackbar("Something went wrong"));
      return rejectWithValue("Something went wrong");
    }
  },
);

const fetchClients = createAsyncThunk<
  Array<BasicTenantClientResponse>,
  Array<number>,
  { state: RootState }
>("adduser/fetchClients", async (tenantIds: Array<number>, { rejectWithValue, dispatch }) => {
  try {
    const response = await GetAllTenantsClients(tenantIds.join(","));
    if (!response.data.succeeded) {
      dispatch(showErrorSnackbar(response.data.messages[0]));
      return rejectWithValue(response.data.messages[0]);
    }
    return response.data.data;
  } catch (error) {
    dispatch(showErrorSnackbar("Something went wrong"));
    return rejectWithValue("Something went wrong");
  }
});

const createUserThunk = createAsyncThunk<void, void, { state: RootState }>(
  "addUser/Create",
  async (_, { getState, fulfillWithValue, rejectWithValue, dispatch }) => {
    dispatch(setLoading(ApiCallStatus.Loading));
    const state = getState().AddUserSlice;

    const tenantClients: { [key: number]: number[] } = {};

    state.tenantAndClientAssignmentStep.selectedClients.forEach((tenantClient) => {
      const tenantId = parseInt(tenantClient.split("|")[0]);
      const clientId = parseInt(tenantClient.split("|")[1]);

      if (!tenantClients[tenantId]) {
        tenantClients[tenantId] = [];
      }

      tenantClients[tenantId].push(clientId);
    });

    const request = {
      userName: state.userInfoStep.userName,
      firstName: state.userInfoStep.firstName,
      lastName: state.userInfoStep.lastName,
      email: state.userInfoStep.email,
      isActive: state.userInfoStep.isActive,
      phoneNumber: state.userInfoStep.phoneNumber,
      autoConfirmEmail: state.userInfoStep.autoConfirmEmail,
      password: state.userInfoStep.password,
      roles: state.rolesInfoStep.selectedRoles,
      tenants: state.tenantAndClientAssignmentStep.selectedTenants,
      tenantClients: tenantClients,
    } as CreateUserRequest;

    CreateUser(request)
      .then((res) => {
        if (res.data.succeeded) {
          dispatch(showSuccessSnackbar(res.data.messages[0]));
          dispatch(setLoading(ApiCallStatus.Success));
          fulfillWithValue(res.data);
          dispatch(setStepCompleted(AddUserStep.Summary));
        } else {
          dispatch(showErrorSnackbar(res.data.messages[0]));
        }
      })
      .catch(
        ValidationErrorCatcher((fieldErrors: Array<FieldError>) => {
          dispatch(setUserInfoFieldError(fieldErrors));
        }),
      )
      .catch(() => {
        dispatch(showErrorSnackbar("Failed to created user."));
        dispatch(setLoading(ApiCallStatus.Error));
        rejectWithValue("");
      });
  },
);

const AddUserSlice = createSlice({
  initialState: initialState,
  name: "addUser",
  reducers: {
    setActiveStep: (state, action: PayloadAction<AddUserStep>) => {
      state.activeStep = action.payload;
    },
    setStepNotStarted: (state, action: PayloadAction<AddUserStep>) => {
      state.steps[action.payload] = StepStatus.NotStarted;
    },
    setStepCompleted: (state, action: PayloadAction<AddUserStep>) => {
      state.steps[action.payload] = StepStatus.Completed;
    },
    setStepError: (state, action: PayloadAction<AddUserStep>) => {
      state.steps[action.payload] = StepStatus.Error;
    },
    setUserInfo: (state, action: PayloadAction<UserInfoStep>) => {
      state.userInfoStep = action.payload;
      state.steps[AddUserStep.UserInfo] = StepStatus.Completed;
    },
    setUserInfoFieldError: (state, action: PayloadAction<Array<FieldError>>) => {
      state.userInfoStep.fieldErrors = action.payload;
      state.steps[AddUserStep.UserInfo] = StepStatus.Error;
    },
    setRolesInfo: (state, action: PayloadAction<Array<string>>) => {
      state.rolesInfoStep.selectedRoles = action.payload;
      state.steps[AddUserStep.RolesInfo] = StepStatus.Completed;
    },
    setTenantAndClientStep: (
      state,
      action: PayloadAction<{ selectedTenants: Array<number>; selectedClients: Array<string> }>,
    ) => {
      state.tenantAndClientAssignmentStep.selectedTenants = action.payload.selectedTenants;
      state.tenantAndClientAssignmentStep.selectedClients = action.payload.selectedClients;
      state.steps[AddUserStep.TenantAndClientAssignment] = StepStatus.Completed;
    },
    setLoading: (state, action: PayloadAction<ApiCallStatus>) => {
      state.loading = action.payload;
    },
    reset: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.rolesInfoStep.roles = action.payload;
      })
      .addCase(fetchRoles.rejected, (state) => {
        state.rolesInfoStep.roles = [];
      })
      .addCase(fetchTenants.fulfilled, (state, action) => {
        state.tenantAndClientAssignmentStep.tenants = action.payload;
      });

    builder
      .addCase(fetchTenants.rejected, (state) => {
        state.tenantAndClientAssignmentStep.tenants = [];
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.tenantAndClientAssignmentStep.clients = action.payload;
      })
      .addCase(fetchClients.rejected, (state) => {
        state.tenantAndClientAssignmentStep.clients = [];
      });

    builder
      .addCase(createUserThunk.fulfilled, (state) => {
        state.loading = ApiCallStatus.Success;
      })
      .addCase(createUserThunk.rejected, (state) => {
        state.loading = ApiCallStatus.Error;
      });
  },
});

export { createUserThunk, fetchClients, fetchRoles, fetchTenants };
export const {
  setActiveStep,
  setUserInfoFieldError,
  setStepCompleted,
  setStepError,
  setUserInfo,
  setRolesInfo,
  setTenantAndClientStep,
  reset,
  setLoading,
} = AddUserSlice.actions;
export const addUserSelector = (state: RootState) => state.AddUserSlice;
export default AddUserSlice.reducer;
