import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import AitDataGrid from "../../Common/DataGrid/AitDataGrid";
import { useSelector } from "react-redux";
import {
  fetchUserLoginGridData,
  setPage,
  setPageSize,
  setQuickSearch,
  userLoginGridHistorySelector,
} from "../../../Redux/Slice/Dashboard/UserLoginHistorySlice";
import { GridColDef } from "@mui/x-data-grid";
import { useAppDispatch } from "../../../Redux/Store";
import SearchTextField from "../../Common/SearchTextField/SearchTextField";
import PaperCard from "../../Common/Styled/PaperCard";

const UserLoginHistoryGrid: React.FC = function () {
  const state = useSelector(userLoginGridHistorySelector);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchUserLoginGridData());
  }, []);

  const columns: Array<GridColDef> = [
    {
      field: "id",
      headerName: "Id",
      width: 10,
      sortable: false,
      flex: 0,
    },
    {
      field: "userId",
      headerName: "User Id",
      width: 80,
      flex: 1,
      sortable: false,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "loginTime",
      headerName: "Login Time",
      width: 150,
      flex: 1,
      sortable: false,
      type: "dateTime",
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "logoutTime",
      headerName: "Logout Time",
      width: 150,
      flex: 1,
      sortable: false,
      type: "dateTime",
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
  ];

  return (
    <PaperCard>
      <Stack
        pb={1}
        direction='row'
        spacing={1}
      >
        <Typography
          variant='h5'
          style={{
            flex: 1,
          }}
        >
          User Login History
        </Typography>
        <SearchTextField
          label='Search'
          placeholder='Search'
          onSearch={(text) => {
            dispatch(setQuickSearch(text));
            dispatch(fetchUserLoginGridData());
          }}
          size='small'
        />
        <Button
          variant='contained'
          color='secondary'
          onClick={() => {
            dispatch(fetchUserLoginGridData());
          }}
        >
          Reload
        </Button>
      </Stack>
      <div
        style={{
          height: "500px",
        }}
      >
        <AitDataGrid
          columns={columns}
          state={state}
          setPage={setPage}
          setPageSize={setPageSize}
          keyColumnName='id'
        />
      </div>
    </PaperCard>
  );
};

export default UserLoginHistoryGrid;
