export interface TenantInfo {
  tenantId: number;
  serverId: number;
  serverName: string;
  tenantIdentifier: string;
  tenantName: string;
  databaseName: string;
  adminEmail: string;
  isActive: boolean;
  validUpto: string;
  tenantFailedConfigurationCount: number;
  isProductionTenant: boolean;
}

export enum LoadMode {
  All = "All",
  ActiveOnly = "ActiveOnly",
}

export interface TenantFormInfo {
  tenantId: number;
  databaseServerId: number | null;
  tenantIdentifier: string;
  tenantName: string;
  databaseName: string;
  adminEmail: string;
  isActive: boolean;
  validUpto: string;
  isProductionTenant: boolean;
}
