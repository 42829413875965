import React, { FC, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { GetAllTenantsBasicInfo, GetAllTenantsClients } from "../../../Api/Tenants/TenantEndpoints";
import {
  GetAssignedUserTenantClients,
  UpdateUserTenantClients,
} from "../../../Api/User/UserEndpoints";
import { BasicTenantClientResponse } from "../../../Models/ApiResponse/Tenant/BasicTenantClientResponse";
import { BasicTenantInfo } from "../../../Models/ApiResponse/Tenant/BasicTenantInfo";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../Redux/Slice/Application/ApplicationSlice";
import { useAppDispatch } from "../../../Redux/Store";
import { MenuProps } from "../../Common/Static";

export interface EditTenantClientModalProps {
  nameToDisplay: string;
  userId: string;
  onCancel(): void;
  onSave(): void;
}

const EditTenantClientModal: FC<EditTenantClientModalProps> = function ({
  nameToDisplay,
  userId,
  onCancel,
  onSave,
}) {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [tenants, setTenants] = useState<Array<BasicTenantInfo>>([]);
  const [clients, setClients] = useState<Array<BasicTenantClientResponse>>([]);

  const [selectedTenants, setSelectedTenants] = useState<Array<number>>([]);
  const [selectedClients, setSelectedClients] = useState<Array<string>>([]);

  useEffect(() => {
    setLoading(true);
    GetAllTenantsBasicInfo()
      .then(({ data: res }) => {
        if (res.succeeded) {
          setTenants(res.data);
        } else {
          dispatch(showErrorSnackbar(res.messages[0]));
        }
        setLoading(false);
      })
      .then(() => {
        setLoading(true);
        GetAssignedUserTenantClients(userId).then(({ data: res }) => {
          if (res.succeeded) {
            loadClients(res.data.map((x) => x.tenantId));
            setSelectedTenants(res.data.map((x) => x.tenantId));
            const tempClient: Array<string> = [];
            res.data.forEach((x) => {
              x.clients.forEach((c) => {
                tempClient.push(getTenantClientStr(x.tenantId, c.clientId));
              });
            });
            setSelectedClients(tempClient);
          } else {
            dispatch(showErrorSnackbar(res.messages[0]));
          }
          setLoading(false);
        });
      })
      .catch(() => {
        dispatch(showErrorSnackbar("Failed to load tenants"));
      });
  }, []);

  const loadClients = async (tenantIds: Array<number>) => {
    if (tenantIds.length > 0) {
      GetAllTenantsClients(tenantIds.join(","))
        .then(({ data: res }) => {
          if (res.succeeded) {
            setClients(res.data);
          } else {
            dispatch(showErrorSnackbar(res.messages[0]));
          }
        })
        .catch(() => {
          dispatch(showErrorSnackbar("Failed to load clients"));
        });
    }
  };

  const handleTenantChange = (event: SelectChangeEvent<Array<number>>) => {
    const {
      target: { value },
    } = event;
    const selectedTenants =
      typeof value === "string" ? value.split(",").map((x) => parseInt(x)) : value;

    setSelectedTenants(selectedTenants);

    const updatedClientIds: Array<string> = [];

    selectedClients.map((tenantClientId) => {
      const tenantCId = parseInt(tenantClientId?.split("|")[0]);
      const clientId = parseInt(tenantClientId?.split("|")[1]);
      if (selectedTenants.indexOf(tenantCId) > -1) {
        updatedClientIds.push(getTenantClientStr(tenantCId, clientId));
      }
    });

    setSelectedClients(updatedClientIds);
    loadClients(selectedTenants);
  };

  const handleSave = () => {
    const tenantClientsArr: { [key: number]: number[] } = {};

    selectedTenants.forEach((tenantId) => {
      tenantClientsArr[tenantId] = [];
    });

    selectedClients.forEach((tenantClient) => {
      const tenantId = parseInt(tenantClient.split("|")[0]);
      const clientId = parseInt(tenantClient.split("|")[1]);

      if (!tenantClientsArr[tenantId]) {
        tenantClientsArr[tenantId] = [];
      }

      tenantClientsArr[tenantId].push(clientId);
    });

    setSubmitting(true);

    UpdateUserTenantClients(userId, {
      tenantClients: tenantClientsArr,
    })
      .then(({ data: res }) => {
        if (res.succeeded) {
          onSave();
          dispatch(showSuccessSnackbar(res.messages[0]));
        } else {
          dispatch(showErrorSnackbar(res.messages[0]));
        }
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
        dispatch(showErrorSnackbar("Failed to update tenant and client"));
      });
  };

  const getTenantClientStr = (tenantId: number, clientId: number) => {
    return `${tenantId}|${clientId}`;
  };

  const getSkeleton = () => (
    <>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <FormControl>
                <InputLabel>Tenants</InputLabel>
                <Select fullWidth></Select>
              </FormControl>
            </Skeleton>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <FormControl>
                <InputLabel>Clients</InputLabel>
                <Select fullWidth></Select>
              </FormControl>
            </Skeleton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Skeleton
          variant='rectangular'
          animation='wave'
        >
          <Button />
          <Button />
        </Skeleton>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>Manage Tenant and Clients - {nameToDisplay}</DialogTitle>
      {loading ? (
        getSkeleton()
      ) : (
        <>
          <DialogContent>
            <Box pt={1}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <FormControl fullWidth>
                    <InputLabel htmlFor='tenant-dropdown'>Tenants</InputLabel>
                    <Select
                      labelId='tenant-dropdown'
                      id='tenant-dropdown'
                      multiple
                      fullWidth
                      value={selectedTenants}
                      input={<OutlinedInput label='Tag' />}
                      renderValue={(selected) => {
                        return tenants
                          .filter((r) => selected.indexOf(r.tenantId) > -1)
                          .map((r) => r.tenantName)
                          .join(", ");
                      }}
                      MenuProps={MenuProps}
                      onChange={handleTenantChange}
                    >
                      {tenants.map((tenant, index) => (
                        <MenuItem
                          key={"tenant_" + index}
                          value={tenant.tenantId}
                        >
                          <Checkbox checked={selectedTenants.indexOf(tenant.tenantId) > -1} />
                          <ListItemText primary={tenant.tenantName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControl fullWidth>
                    <InputLabel htmlFor='client-dropdown'>Clients</InputLabel>
                    <Select
                      labelId='client-dropdown'
                      id='client-dropdown'
                      multiple
                      fullWidth
                      disabled={selectedTenants.length == 0}
                      value={selectedClients}
                      input={<OutlinedInput label='Tag' />}
                      renderValue={(selected) => {
                        const retVal: Array<string> = [];
                        clients.forEach((tenant) => {
                          tenant.clients.forEach((client) => {
                            if (
                              selected.indexOf(
                                getTenantClientStr(tenant.tenantId, client.clientId),
                              ) > -1
                            ) {
                              retVal.push(`${client.clientName} (${tenant.tenantName})`);
                            }
                          });
                        });
                        return retVal.join(", ");
                      }}
                      MenuProps={MenuProps}
                    >
                      {clients.map((tenantClient, index) => (
                        <div key={index}>
                          <ListSubheader key={"tenantClient_" + index}>
                            {tenantClient.tenantName}
                          </ListSubheader>
                          {tenantClient.clients.map((client, cIndex) => {
                            return (
                              <MenuItem
                                key={"client_" + cIndex}
                                value={getTenantClientStr(tenantClient.tenantId, client.clientId)}
                              >
                                <Checkbox
                                  checked={
                                    selectedClients.findIndex(
                                      (x) =>
                                        x ==
                                        getTenantClientStr(tenantClient.tenantId, client.clientId),
                                    ) >= 0
                                  }
                                  onChange={({ target: { checked } }) => {
                                    const checkBoxValue = getTenantClientStr(
                                      tenantClient.tenantId,
                                      client.clientId,
                                    );
                                    if (checked && selectedClients.indexOf(checkBoxValue) == -1) {
                                      setSelectedClients([...selectedClients, checkBoxValue]);
                                    }
                                    if (!checked && selectedClients.indexOf(checkBoxValue) > -1) {
                                      const newArr = [...selectedClients];
                                      newArr.splice(
                                        newArr.findIndex((item) => item === checkBoxValue),
                                        1,
                                      );
                                      setSelectedClients(newArr);
                                    }
                                  }}
                                />
                                <ListItemText primary={client.clientName} />
                              </MenuItem>
                            );
                          })}
                        </div>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              disabled={submitting}
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant='contained'
              type='submit'
              color='success'
              loading={submitting}
              onClick={handleSave}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default EditTenantClientModal;
