import { GetAbortedClaimBatches } from "../../../../Api/Tenants/TenantDashboardEndpoints";
import { PaginatedResult } from "../../../../Models/ApiResponse/Common/PaginatedResult";
import { GetAbortedClaimBatchesQueryResponse } from "../../../../Models/ApiResponse/TenantDashboard/GetAbortedClaimBatchesQueryResponse";
import { AbortedBatchesGridState } from "../../../State/TenantDashboard/AbortedBatchesGrid/AbortedBatchesGridState";
import { RootState } from "../../../Store";
import { ApiCallStatus } from "../../../State/Common/GenericApiState";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SortModel } from "../../../State/Common/GenericGridState";
import { EntityPrefix, GetColumnPrefix } from "../../../../Util/ColumnHelper";

export const fetchAbortedBatchesClaims = createAsyncThunk<
  PaginatedResult<GetAbortedClaimBatchesQueryResponse>,
  { tenantId: number; quickSearch?: string },
  { state: RootState }
>("dashboard/fetchAbortedBatchesClaims", async (_, thunk) => {
  const { getState } = thunk;
  const state = getState().abortedBatchesGridSlice;
  const res = await GetAbortedClaimBatches(
    _.tenantId,
    state.currentPage,
    state.pageSize,
    _.quickSearch || "",
    GetColumnPrefix(EntityPrefix.ClaimStatusBatches, state.sortModel.field),
    state.sortModel.order,
  );
  return res.data as PaginatedResult<GetAbortedClaimBatchesQueryResponse>;
});

const getInitialState = () => {
  return {
    currentPage: 1,
    pageSize: 10,
    status: ApiCallStatus.NoStarted,
    totalPages: 0,
    totalCount: 0,
    rows: [],
    sortModel: {
      field: "lastModifiedOn",
      order: "desc",
    },
  } as AbortedBatchesGridState;
};

const abortedBatchesGridSlice = createSlice({
  name: "abortedBatchesGridSlice",
  initialState: getInitialState(),
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload + 1;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSortModel: (state, action: PayloadAction<SortModel>) => {
      state.sortModel = action.payload;
    },
  },
  extraReducers(builder) {
    //tofo
    builder
      .addCase(fetchAbortedBatchesClaims.pending, (state) => {
        state.status = ApiCallStatus.Loading;
      })
      .addCase(fetchAbortedBatchesClaims.fulfilled, (state, action) => {
        if (action.payload.succeeded) {
          state.rows = action.payload.data;
          state.currentPage = action.payload.currentPage;
          state.totalCount = action.payload.totalCount;
          state.totalPages = action.payload.totalPages;
          state.status = ApiCallStatus.Success;
        } else {
          state.status = ApiCallStatus.Error;
        }
      })
      .addCase(fetchAbortedBatchesClaims.rejected, (state) => {
        state.status = ApiCallStatus.Error;
      });
  },
});
export const { setPage, setPageSize, setSortModel } = abortedBatchesGridSlice.actions;
export const abortedBatchesGridSelector = (state: RootState) => state.abortedBatchesGridSlice;
export default abortedBatchesGridSlice.reducer;
