import React from "react";

import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridInputRowSelectionModel,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

import { ApiCallStatus } from "../../../Redux/State/Common/GenericApiState";
import GenericGridState, { SortModel } from "../../../Redux/State/Common/GenericGridState";
import { useAppDispatch } from "../../../Redux/Store";
import CustomPagination from "./CustomPagination";

export type GridFeatureMode = "client" | "server";

export interface AitDataGridProps<T> {
  state: GenericGridState<T>;
  keyColumnName: string;
  columns: GridColDef[];
  setPage: ActionCreatorWithPayload<number, any>;
  setPageSize: ActionCreatorWithPayload<number, any>;
  isRowSelectable?: boolean;
  checkboxSelection?: boolean;
  disableRowSelectionOnClick?: boolean;
  paginationMode?: GridFeatureMode;
  onRowSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails,
  ) => void;
  rowSelectionModel?: GridInputRowSelectionModel;
  sortMode?: GridFeatureMode;
  sortModel?: SortModel;
  defaultSortModel?: SortModel;
  onSortModelChange?: (field: string, order: string) => void;
}

const AitDataGrid = function <T>(props: AitDataGridProps<T>) {
  const {
    state: { rows, currentPage, pageSize, totalCount, status },
    keyColumnName,
    columns,
    setPage,
    setPageSize,
    checkboxSelection,
    disableRowSelectionOnClick,
    paginationMode,
    isRowSelectable,
    onRowSelectionModelChange,
    rowSelectionModel,
    sortMode,
    sortModel,
    onSortModelChange,
    defaultSortModel,
  } = props;

  const dispatch = useAppDispatch();

  const getSortModel = () => {
    if (!sortModel) {
      return undefined;
    }
    return [
      {
        field: sortModel.field,
        sort: sortModel.order.toLowerCase() === "asc" ? "asc" : "desc",
      },
    ] as GridSortModel;
  };

  return (
    <DataGrid
      sx={{
        border: 0,
      }}
      columns={columns}
      rows={rows}
      getRowId={(row) => row[keyColumnName]}
      paginationModel={{
        page: currentPage - 1,
        pageSize: pageSize,
      }}
      rowCount={totalCount}
      pageSizeOptions={[10, 20, 50]}
      isRowSelectable={() => !!isRowSelectable}
      loading={status == ApiCallStatus.Loading}
      paginationMode={paginationMode || "server"}
      checkboxSelection={!!checkboxSelection}
      disableRowSelectionOnClick={!!disableRowSelectionOnClick}
      initialState={{
        pagination: {
          paginationModel: {
            page: 0,
            pageSize: 10,
          },
        },
      }}
      sortModel={getSortModel()}
      sortingMode={sortMode}
      slots={{
        pagination: CustomPagination,
      }}
      onPaginationModelChange={(model) => {
        dispatch(setPage(model.page));
        if (model.pageSize != pageSize) {
          dispatch(setPageSize(model.pageSize));
        }
      }}
      onRowSelectionModelChange={onRowSelectionModelChange}
      rowSelectionModel={rowSelectionModel}
      onSortModelChange={(model) => {
        if (model.length > 0) {
          const columnToSort = model[0];
          onSortModelChange && onSortModelChange(columnToSort.field, columnToSort.sort || "asc");
        } else {
          onSortModelChange &&
            defaultSortModel &&
            onSortModelChange(defaultSortModel.field, defaultSortModel.order);
        }
      }}
    />
  );
};

export default AitDataGrid;
