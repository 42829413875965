import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FieldError, ValidationErrorCatcher } from "../../Api/Common/ApiHelpers";
import { GetAllServersInfo } from "../../Api/Server/ServerEndpoitns";
import { GetTenantInfo, SaveTenant } from "../../Api/Tenants/TenantEndpoints";
import { AddEditTenantRequest } from "../../Models/ApiRequest/Admin/Tenant/AddEditTenantRequest";
import { BasicServerInfo } from "../../Models/ApiResponse/Server/BasicServerInfo";
import { TenantFormInfo } from "../../Models/ApiResponse/Tenant/TenantInfo";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Slice/Application/ApplicationSlice";
import { hideAddEditModal } from "../../Redux/Slice/Tenants/TenantsGridSlice";
import { useAppDispatch } from "../../Redux/Store";

type Props = {
  tenantId: number;
  onSuccess?(): void;
};

const validationSchema = Yup.object().shape({
  tenantIdentifier: Yup.string()
    .required("Tenant Idetifier is required")
    .max(20, "Tenant Idetifier character limit exceeds")
    .matches(/^[a-zA-Z0-9_-]+$/i, "Only numbers and Alphabets and (-,_) are allowed."),
  tenantName: Yup.string()
    .required("Tenant Name is required")
    .max(30, "Tenant Name character limit exceeds")
    .matches(/^[a-zA-Z0-9_-]+$/i, "Only numbers and Alphabets and (-,_) are allowed."),
  databaseServerId: Yup.number().required("Database Server is required"),
  databaseName: Yup.string()
    .required("Database Name is required")
    .max(30, "Database Name character limit exceeds")
    .matches(/^[a-zA-Z0-9_-]+$/i, "Only numbers and Alphabets and (-,_) are allowed."),
  adminEmail: Yup.string().required("Admin Email is required").email("Admin Email is invalid"),
  validUpto: Yup.date().required("Valid Upto is required"),
});

const AddEditTenantForm = function (props: Props) {
  const isEditMode = props.tenantId !== 0;
  const dispatch = useAppDispatch();

  const [formLoading, setformLoading] = useState(true);

  const [servers, setServers] = useState<Array<BasicServerInfo>>(new Array<BasicServerInfo>());

  useEffect(() => {
    GetAllServersInfo()
      .then((res) => {
        if (res.data.succeeded) {
          setServers(res.data.data);
        } else {
          dispatch(showErrorSnackbar(res.data.messages[0]));
        }
      })
      .catch(() => {
        dispatch(showErrorSnackbar("Something went wrong"));
      });

    if (isEditMode) {
      GetTenantInfo(props.tenantId).then(function (res) {
        if (res.data.succeeded) {
          const {
            tenantId,
            tenantIdentifier,
            tenantName,
            databaseName,
            serverId,
            adminEmail,
            validUpto,
            isActive,
            isProductionTenant,
          } = res.data.data;
          formik.setValues({
            tenantIdentifier,
            tenantName,
            databaseName,
            databaseServerId: serverId,
            adminEmail,
            validUpto: dayjs(validUpto).toString(),
            isActive,
            tenantId,
            isProductionTenant,
          });
          setformLoading(false);
        }
      });
    } else {
      setformLoading(false);
    }
  }, []);

  const addEditFormInitialValues = {
    databaseServerId: null,
    tenantName: "",
    tenantIdentifier: "",
    databaseName: "",
    isActive: true,
    validUpto: dayjs(new Date()).add(1, "year").toString(),
    adminEmail: "",
    isProductionTenant: true,
  } as TenantFormInfo;

  const formik = useFormik({
    initialValues: addEditFormInitialValues,
    validationSchema,
    onSubmit: (values) => {
      const addEditTenantRequest = {
        tenantId: props.tenantId,
        tenantIdentifier: values.tenantIdentifier,
        tenantName: values.tenantName,
        databaseName: values.databaseName,
        databaseServerId: values.databaseServerId,
        adminEmail: values.adminEmail,
        isActive: values.isActive,
        validUpto: values.validUpto,
        isProductionTenant: values.isProductionTenant,
      } as AddEditTenantRequest;

      SaveTenant(addEditTenantRequest)
        .then(function ({ data }) {
          if (data.succeeded) {
            const msg = isEditMode ? "Tenant Saved Successfully" : "Tenant Added Successfully";
            dispatch(showSuccessSnackbar(msg));
            dispatch(hideAddEditModal());
            if (props.onSuccess) props.onSuccess();
          }
        })
        .catch(
          ValidationErrorCatcher((fieldErrors: Array<FieldError>) => {
            fieldErrors.map((fieldError) => {
              formik.setFieldError(fieldError.fieldName, fieldError.errors[0]);
            });
          }),
        )
        .catch(() => {
          dispatch(showErrorSnackbar("Something went wrong"));
        });
    },
  });

  const getSkeleton = () => (
    <>
      <DialogContent>
        <Box pt={1}>
          <Grid
            container
            spacing={2}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid
              item
              xs={12}
            >
              {" "}
              <Skeleton
                variant='rounded'
                animation='wave'
                width='100%'
              >
                <TextField fullWidth />
              </Skeleton>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {" "}
              <Skeleton
                variant='rounded'
                animation='wave'
                width='100%'
              >
                <TextField fullWidth />
              </Skeleton>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {" "}
              <Skeleton
                variant='rounded'
                animation='wave'
                width='100%'
              >
                <TextField fullWidth />
              </Skeleton>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {" "}
              <Skeleton
                variant='rounded'
                animation='wave'
                width='100%'
              >
                <TextField fullWidth />
              </Skeleton>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {" "}
              <Skeleton
                variant='rounded'
                animation='wave'
                width='100%'
              >
                <TextField fullWidth />
              </Skeleton>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {" "}
              <Skeleton
                variant='rounded'
                animation='wave'
                width='100%'
              >
                <TextField fullWidth />
              </Skeleton>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Skeleton
          variant='rectangular'
          animation='wave'
        >
          <Button />
          {""}
          <Button />
        </Skeleton>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>{isEditMode ? "Edit" : "Add"} Tenant</DialogTitle>
      {formLoading ? (
        getSkeleton()
      ) : (
        <>
          <DialogContent>
            <Box pt={1}>
              <Grid
                container
                spacing={2}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    name='tenantIdentifier'
                    placeholder='Tenant Identifier'
                    label='Tenant Identifier'
                    variant='outlined'
                    fullWidth
                    value={formik.values.tenantIdentifier || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tenantIdentifier && !!formik.errors.tenantIdentifier}
                    helperText={formik.touched.tenantIdentifier && formik.errors.tenantIdentifier}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    name='tenantName'
                    placeholder='Tenant Name'
                    label='Tenant Name'
                    variant='outlined'
                    fullWidth
                    value={formik.values.tenantName || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tenantName && !!formik.errors.tenantName}
                    helperText={formik.touched.tenantName && formik.errors.tenantName}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControl
                    fullWidth
                    disabled={formik.isSubmitting}
                  >
                    <InputLabel>Database Server</InputLabel>
                    <Select
                      name='databaseServerId'
                      fullWidth
                      label='Database Server'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.databaseServerId}
                      error={formik.touched.databaseServerId && !!formik.errors.databaseServerId}
                    >
                      <MenuItem
                        value=''
                        disabled
                      >
                        <em>Select</em>
                      </MenuItem>
                      {servers.map((server, index) => {
                        return (
                          <MenuItem
                            value={server.serverId}
                            key={index}
                          >
                            {server.serverName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formik.touched.databaseServerId && !!formik.errors.databaseServerId && (
                      <FormHelperText error={true}>{formik.errors.databaseServerId}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    name='databaseName'
                    placeholder='Database Name'
                    label='Database Name'
                    variant='outlined'
                    fullWidth
                    value={formik.values.databaseName || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.databaseName && !!formik.errors.databaseName}
                    helperText={formik.touched.databaseName && formik.errors.databaseName}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    name='adminEmail'
                    placeholder='Admin Email'
                    label='Admin Email'
                    variant='outlined'
                    fullWidth
                    value={formik.values.adminEmail || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.adminEmail && !!formik.errors.adminEmail}
                    helperText={formik.touched.adminEmail && formik.errors.adminEmail}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={formik.isSubmitting}
                      sx={{
                        width: "100%",
                      }}
                      minDate={dayjs(new Date())}
                      format='MM/DD/YYYY'
                      onChange={(value) => {
                        formik.setFieldValue("validUpto", dayjs(value).format("MM/DD/YYYY"), true);
                      }}
                      value={dayjs(formik.values.validUpto)}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          helperText: formik.touched.validUpto && formik.errors.validUpto,
                          error: formik.touched.validUpto && !!formik.errors.validUpto,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='isActive'
                          checked={formik.values.isActive}
                          onChange={formik.handleChange}
                          disabled={formik.isSubmitting}
                        />
                      }
                      label='Active?'
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='isProductionTenant'
                          checked={formik.values.isProductionTenant}
                          onChange={formik.handleChange}
                          disabled={formik.isSubmitting}
                        />
                      }
                      label='Production Use'
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={() => dispatch(hideAddEditModal())}
            >
              Cancel
            </Button>
            <LoadingButton
              variant='contained'
              type='submit'
              color='success'
              loading={formik.isSubmitting}
              onClick={() => formik.handleSubmit()}
            >
              <span>Save</span>
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default AddEditTenantForm;
