import { MasterUserInfoResponse } from "../../../Models/ApiResponse/User/UserInfoResponse";
import { ApiCallStatus } from "../Common/GenericApiState";

export enum EditUserTabs {
  BasicUserInfo = 1,
  RolesInfo = 2,
  TenantAndClient = 3,
  ChangePassword = 4,
  ChangePin = 5,
}

export interface EditUserState {
  activeTab: EditUserTabs;
  userId: string;
  userRecord: MasterUserInfoResponse;
  loading: ApiCallStatus;
}
