import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const PasswordResetConfirmation: React.FC = function () {
  return (
    <div
      style={{
        minHeight: "calc(100vh - 18px)",
        display: "flex",
        flexDirection: "column",
        paddingTop: "150px",
        alignItems: "center",
      }}
    >
      <p
        style={{
          fontSize: "20px",
          marginBottom: "20px",
        }}
      >
        Your “forgot password” request has been received.
      </p>
      <p
        style={{
          fontSize: "18px",
          margin: "0px 0px 20px",
        }}
      >
        If an account exists that is linked to the provided email address, an email will be sent
        that will guide you through resetting your password.
      </p>
      <Typography>
        <Link to={"/login"}>You may close this page</Link>
      </Typography>
    </div>
  );
};

export default PasswordResetConfirmation;
