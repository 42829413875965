import { GetAllPagedRpaInsurances } from "../../../../Api/Tenants/TenantDashboardEndpoints";
import { PaginatedResult } from "../../../../Models/ApiResponse/Common/PaginatedResult";
import { GetAllPagedRpaInsurancesQueryResponse } from "../../../../Models/ApiResponse/Dashboard/GetAllPagedRpaInsurancesQueryResponse";
import { ActiveRpaInsuraceGridState } from "../../../State/Dashboard/ActiveInactiveRpaInsuraceGrid/ActiveInactiveRpaInsuraceGridState";

import { RootState } from "../../../Store";
import { ApiCallStatus } from "../../../State/Common/GenericApiState";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EntityPrefix, GetColumnPrefix } from "../../../../Util/ColumnHelper";
import { SortModel } from "../../../State/Common/GenericGridState";

export const fetchActiveRpaInsurance = createAsyncThunk<
  PaginatedResult<GetAllPagedRpaInsurancesQueryResponse>,
  { tenantId: number; quickSearch: string },
  { state: RootState }
>("dashboard/fetchActiveRpaInsurance", async (_, thunk) => {
  const { getState } = thunk;
  const state = getState().ActiveRPAInsuranceGridSlice;
  const res = await GetAllPagedRpaInsurances(
    _.tenantId,
    state.currentPage,
    state.pageSize,
    _.quickSearch,
    GetColumnPrefix(EntityPrefix.RPAInsurances, state.sortModel.field),
    state.sortModel.order,
    true,
  );
  return res.data as PaginatedResult<GetAllPagedRpaInsurancesQueryResponse>;
});

const getInitialState = () => {
  return {
    currentPage: 1,
    pageSize: 10,
    status: ApiCallStatus.NoStarted,
    totalPages: 0,
    totalCount: 0,
    rows: [],
    sortModel: {
      field: "id",
      order: "desc",
    },
  } as ActiveRpaInsuraceGridState;
};

const ActiveRpaInsuraceGridSlice = createSlice({
  name: "ActiveRpaInsuraceGridSlice",
  initialState: getInitialState(),
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload + 1;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSortModel: (state, action: PayloadAction<SortModel>) => {
      state.sortModel = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchActiveRpaInsurance.pending, (state) => {
        state.status = ApiCallStatus.Loading;
      })
      .addCase(fetchActiveRpaInsurance.fulfilled, (state, action) => {
        state.status = ApiCallStatus.Success;
        state.currentPage = action.payload.currentPage;
        state.totalCount = action.payload.totalCount;
        state.totalPages = action.payload.totalPages;
        state.rows = action.payload.data;
      })
      .addCase(fetchActiveRpaInsurance.rejected, (state) => {
        state.status = ApiCallStatus.Error;
      });
  },
});
export const { setPage, setPageSize, setSortModel } = ActiveRpaInsuraceGridSlice.actions;
export const ActiveRpaInsuraceGridSelector = (state: RootState) =>
  state.ActiveRPAInsuranceGridSlice;
export default ActiveRpaInsuraceGridSlice.reducer;
