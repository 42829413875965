import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetRolePermissions } from "../../../Api/Roles/RolesEndpoints";
import { PermissionResponse } from "../../../Models/ApiResponse/Role/PermissionResponse";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { PermissionGridState } from "../../State/Role/PermissionGridState";
import { RootState } from "../../Store";
import { showSuccessSnackbar } from "../Application/ApplicationSlice";

const fetchRolePermissions = createAsyncThunk<PermissionResponse, void, { state: RootState }>(
  "roles/fetchPermission",
  async (_, thunk) => {
    const { getState, dispatch, rejectWithValue } = thunk;
    const state = getState().PermissionGridSlice;
    try {
      const response = await GetRolePermissions(state.roleId);
      if (!response.data.succeeded) {
        dispatch(showSuccessSnackbar("Failed to load permission"));
        return rejectWithValue(null);
      }
      return response.data.data as PermissionResponse;
    } catch (error) {
      dispatch(showSuccessSnackbar("Failed to load permission"));
      return rejectWithValue(null);
    }
  },
);

const getInitialState = () => {
  return {
    roleId: "",
    roleName: "",
    currentPage: 1,
    pageSize: 10,
    rows: [],
    status: ApiCallStatus.NoStarted,
    totalCount: 0,
    totalPages: 0,
    error: "",
  } as PermissionGridState;
};

const permissionGridSlice = createSlice({
  name: "permissionGridSlice",
  initialState: getInitialState(),
  reducers: {
    setRoleId: (state, action: PayloadAction<string>) => {
      state.roleId = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload + 1;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    reset: () => {
      return { ...getInitialState() };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRolePermissions.pending, (state) => {
        state.status = ApiCallStatus.Loading;
      })
      .addCase(fetchRolePermissions.fulfilled, (state, action) => {
        state.roleName = action.payload.roleName;
        state.rows = action.payload.roleClaims;
        state.totalCount = action.payload.roleClaims.length;
        state.totalPages = action.payload.roleClaims.length / state.pageSize;
        state.status = ApiCallStatus.Success;
      })
      .addCase(fetchRolePermissions.rejected, (state) => {
        state.rows = [];
        state.status = ApiCallStatus.Error;
        state.error = "Something went wrong";
      });
  },
});

export { fetchRolePermissions };
export const { setPage, setPageSize, setRoleId, reset } = permissionGridSlice.actions;
export const permissionGridSelector = (state: RootState) => state.PermissionGridSlice;
export default permissionGridSlice.reducer;
