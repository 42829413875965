import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { ResendConfirmationEmail } from "../../Api/User/UserEndpoints";
import { IResult } from "../../Models/ApiResponse/Common/IResult";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Slice/Application/ApplicationSlice";
import {
  fetchPagedUser,
  setPage,
  setPageSize,
  toggleUserStatus,
  usersGridSelector,
} from "../../Redux/Slice/Users/UserGridSlice";
import { useAppDispatch } from "../../Redux/Store";
import AitDataGrid from "../Common/DataGrid/AitDataGrid";
import ConfirmModal from "../Common/Modals/ConfirmModal";
import ChangePasswordModal from "./GridActions/ChangePasswordModal";
import ChangePinModal from "./GridActions/ChangePinModal";
import EditRolesModal from "./GridActions/EditRolesModal";
import EditTenantClientModal from "./GridActions/EditTenantClientModal";
import ManageEmployeeInClientsModal from "./GridActions/ManageEmoloyeeInClientsModal";

const UsersGrid: FC = function () {
  const toggleUserActive = function (userId: string, makeUserActive: boolean, userName: string) {
    const msg = `Are you sure want to ${
      makeUserActive ? "active" : "inactive"
    } the user ${userName}?`;
    setConfirmStatus({ show: true, userId: userId, active: makeUserActive, message: msg });
  };

  const initialConfirmStatus = { show: false, message: "", userId: "", active: false };
  const [confirmStatus, setConfirmStatus] = useState<{
    show: boolean;
    message: string;
    userId: string;
    active: boolean;
  }>(initialConfirmStatus);

  const initialEditRolesModalState = { show: false, userName: "", userId: "" };
  const initialEditTenantClientModalState = { show: false, userName: "", userId: "" };
  const initialChangePasswordModalState = { show: false, userName: "", userId: "" };
  const initialChangePinModalState = { show: false, userName: "", userId: "" };
  const initialManageEmployeeModalState = { show: false, userName: "", userId: "" };

  const [editRolesModalState, setEditRolesModalState] = useState<{
    show: boolean;
    userName: string;
    userId: string;
  }>(initialEditRolesModalState);

  const [editTenantClientModalState, setEditTenantClientModalState] = useState<{
    show: boolean;
    userName: string;
    userId: string;
  }>(initialEditTenantClientModalState);

  const [changePasswordModalState, setChangePasswordModalState] = useState<{
    show: boolean;
    userName: string;
    userId: string;
  }>(initialChangePasswordModalState);

  const [changePinModalState, setChangePinModalState] = useState<{
    show: boolean;
    userName: string;
    userId: string;
  }>(initialChangePinModalState);

  const [manageEmployeeModalState, setManageEmployeeModalState] = useState<{
    show: boolean;
    userName: string;
    userId: string;
  }>(initialManageEmployeeModalState);

  //EN-146
  const resendConfirmationEmail = ({ userId }: { userId: string }) => {
    ResendConfirmationEmail({ userId }) // Wrap userId in an object
      .then((response) => {
        const data: IResult<string> = response.data;
        if (data.succeeded) {
          dispatch(showSuccessSnackbar("Confirmation Email Resent Successfully"));
        } else {
          dispatch(showErrorSnackbar(data.messages[0]));
        }
      })
      .catch((error) => {
        console.error("Error resending confirmation email:", error);
        dispatch(showErrorSnackbar("Failed to resend confirmation email."));
      });
  };

  const columns: GridColDef[] = [
    {
      field: "userName",
      headerName: "Username",
      type: "string",
      flex: 1,
      sortable: false,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      sortable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      flex: 1,
      sortable: false,
    },
    {
      field: "emailConfirmed",
      headerName: "Email Confirmed",
      type: "boolean",
      sortable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      sortable: false,
    },
    {
      field: "isActive",
      type: "boolean",
      headerName: "Is Active",
      sortable: false,
    },
    {
      field: "createdByName",
      headerName: "Created By",
      type: "string",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      type: "dateTime",
      width: 100,
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "lastModifiedByName",
      headerName: "Modified By",
      type: "string",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "lastModifiedOn",
      headerName: "Modified On",
      type: "dateTime",
      width: 100,
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      sortable: false,
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            label='Edit User'
            key='editUser'
            showInMenu
            onClick={() => navigate(`/admin/users/${row.id}/edit`)}
          />,
          <GridActionsCellItem
            label='Edit Roles'
            key='editRoles'
            showInMenu
            onClick={() =>
              setEditRolesModalState({ show: true, userId: id.toString(), userName: row.userName })
            }
          />,
          <GridActionsCellItem
            label='Edit Tenants/Clients'
            key='editTenantsClients'
            showInMenu
            onClick={() =>
              setEditTenantClientModalState({
                show: true,
                userId: id.toString(),
                userName: row.userName,
              })
            }
          />,
          <GridActionsCellItem
            label='Change Password'
            key='changePassword'
            showInMenu
            onClick={() =>
              setChangePasswordModalState({
                show: true,
                userId: id.toString(),
                userName: row.userName,
              })
            }
          />,
          <GridActionsCellItem
            label={row.isActive ? "Make Inactive" : "Make Active"}
            key='makeUserActiveOption'
            showInMenu
            onClick={() => toggleUserActive(id.toString(), !row.isActive, row.userName)}
          />,
          <GridActionsCellItem
            label='Change Pin'
            key='changePin'
            showInMenu
            onClick={() =>
              setChangePinModalState({
                show: true,
                userId: id.toString(),
                userName: row.userName,
              })
            }
          />,
          <GridActionsCellItem
            label={row.isActive ? "Make Inactive" : "Make Active"}
            key='makeUserActiveOption'
            showInMenu
            onClick={() => toggleUserActive(id.toString(), !row.isActive, row.userName)}
          />,
          <GridActionsCellItem
            label='Resend Confirmation Email'
            key='resendConfirmationEmail'
            disabled={row.emailConfirmed}
            showInMenu
            onClick={() =>
              resendConfirmationEmail({
                userId: id.toString(),
              })
            }
          />,
          <GridActionsCellItem
            label='Manage Employee In Clients'
            key='manageEmployeeInClients'
            showInMenu
            onClick={() =>
              setManageEmployeeModalState({
                show: true,
                userId: id.toString(),
                userName: row.userName,
              })
            }
          />,
        ];
      },
    },
  ];

  const state = useSelector(usersGridSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPagedUser());
  }, [state.currentPage, state.pageSize]);

  const confirmModal = () => (
    <ConfirmModal
      title='Confirm'
      message={confirmStatus?.message}
      onNo={() => {
        setConfirmStatus(initialConfirmStatus);
      }}
      onYes={() => {
        dispatch(
          toggleUserStatus({ userId: confirmStatus.userId, activeUser: confirmStatus.active }),
        );
        setConfirmStatus(initialConfirmStatus);
      }}
      show={confirmStatus.show}
    />
  );

  const editRolesModalPopup = () => (
    <EditRolesModal
      nameToDisplay={editRolesModalState.userName}
      userId={editRolesModalState.userId}
      onCancel={() => setEditRolesModalState(initialEditRolesModalState)}
      onSave={() => setEditRolesModalState(initialEditRolesModalState)}
    />
  );
  const editTenantClientModalPopup = () => (
    <EditTenantClientModal
      nameToDisplay={editTenantClientModalState.userName}
      userId={editTenantClientModalState.userId}
      onCancel={() => setEditTenantClientModalState(initialEditTenantClientModalState)}
      onSave={() => setEditTenantClientModalState(initialEditTenantClientModalState)}
    />
  );

  const changePasswordModalPopup = () => (
    <ChangePasswordModal
      nameToDisplay={changePasswordModalState.userName}
      userId={changePasswordModalState.userId}
      onCancel={() => setChangePasswordModalState(initialChangePasswordModalState)}
      onSave={() => setChangePasswordModalState(initialChangePasswordModalState)}
    />
  );

  const changePinModalPopup = () => (
    <ChangePinModal
      nameToDisplay={changePinModalState.userName}
      userId={changePinModalState.userId}
      onCancel={() => setChangePinModalState(initialChangePinModalState)}
      onSave={() => setChangePinModalState(initialChangePinModalState)}
    />
  );

  const manageEmployeeInClientsModalPopup = () => (
    <ManageEmployeeInClientsModal
      nameToDisplay={manageEmployeeModalState.userName}
      userId={manageEmployeeModalState.userId}
      onCancel={() => setManageEmployeeModalState({ show: false, userName: "", userId: "" })}
      onSave={() => {
        // Add your save logic here
        setManageEmployeeModalState({ show: false, userName: "", userId: "" });
      }}
    />
  );

  return (
    <div
      style={{
        height: "500px",
      }}
    >
      {confirmStatus.show && confirmModal()}
      {editRolesModalState.show && editRolesModalPopup()}
      {editTenantClientModalState.show && editTenantClientModalPopup()}
      {changePasswordModalState.show && changePasswordModalPopup()}
      {changePinModalState.show && changePinModalPopup()}
      {manageEmployeeModalState.show && manageEmployeeInClientsModalPopup()}

      <AitDataGrid
        state={state}
        columns={columns}
        setPage={setPage}
        setPageSize={setPageSize}
        keyColumnName='id'
        key='users'
      />
    </div>
  );
};

export default UsersGrid;
