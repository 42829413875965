import { AddEditClientRequest } from "../../Models/ApiRequest/Admin/Client/AddClientRequest";
import { ApiKeyViewModel } from "../../Models/ApiRequest/Admin/Client/ApiKeyViewModel";
import { ClientApiKeyGridResponse } from "../../Models/ApiResponse/Client/ClientApiKeyGridResponse";
import {
  ClientKpiDto,
  GetClientByIdResponse,
} from "../../Models/ApiResponse/Client/ClientByIdResponse";
import { ClientGridResponse } from "../../Models/ApiResponse/Client/ClientGridResponse";
import { IResult } from "../../Models/ApiResponse/Common/IResult";
import { PaginatedResult } from "../../Models/ApiResponse/Common/PaginatedResult";
import { httpClient } from "../APIBase";

const clientBasePath = "/admin/clients";

export const GetClientsPagination = function (
  pageNumber: number,
  pageSize: number,
  tenantId: number,
  search: string,
) {
  return httpClient.get<PaginatedResult<ClientGridResponse>>(`${clientBasePath}/page`, {
    params: {
      pageNumber,
      pageSize,
      tenantId,
      search,
    },
  });
};

export const GetClientById = function (tenantId: number, clientId: number) {
  return httpClient.get<IResult<GetClientByIdResponse>>(`${clientBasePath}/${clientId}`, {
    params: {
      tenantId,
    },
  });
};

export const SaveClient = function (request: AddEditClientRequest) {
  return httpClient.post<IResult<number>>(clientBasePath, request);
};

export const DeleteClient = function (tenantId: number, clientId: number) {
  return httpClient.delete<IResult<number>>(clientBasePath, {
    params: {
      tenantId,
      clientId,
    },
  });
};

export const SaveClientKpi = function (
  tenantId: number,
  clientId: number,
  clientKpi: ClientKpiDto,
) {
  return httpClient.post<IResult<number>>(`${clientBasePath}/${clientId}/clientKpi`, clientKpi, {
    params: {
      tenantId,
    },
  });
};

export const GetClientApiKeys = function (
  tenantId: number,
  clientId: number,
  pageNumber: number,
  pageSize: number,
  searchString: string,
) {
  return httpClient.get<PaginatedResult<ClientApiKeyGridResponse>>(
    `${clientBasePath}/${clientId}/ClientApiKey/pagination`,
    {
      params: {
        tenantId,
        pageNumber,
        pageSize,
        searchString,
      },
    },
  );
};

export const GetClientApiKeyById = function (
  tenantId: number,
  clientId: number,
  clientApiIntegrationId: number,
) {
  return httpClient.get<IResult<ClientApiKeyGridResponse>>(
    `${clientBasePath}/${clientId}/ClientApiKey/${clientApiIntegrationId}`,
    {
      params: {
        tenantId,
      },
    },
  );
};

export const SaveClientApiKey = function (
  tenantId: number,
  clientId: number,
  data: ApiKeyViewModel,
) {
  return httpClient.post<IResult<number>>(`${clientBasePath}/${clientId}/ClientApiKey`, data, {
    params: {
      tenantId,
    },
  });
};

export const DeleteClientApiKey = function (
  tenantId: number,
  clientId: number,
  clientApiIntegrationId: number,
) {
  return httpClient.delete<IResult<number>>(
    `${clientBasePath}/${clientId}/ClientApiKey/${clientApiIntegrationId}`,
    {
      params: {
        tenantId,
      },
    },
  );
};
