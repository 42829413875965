import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Button } from "@mui/base";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  useTheme,
} from "@mui/material";
import { GetAllAuthTypesRequest } from "../../../Api/AuthTypes/AuthTypesEndpoints";
import { SaveClient } from "../../../Api/Client/ClientEndpoints";
import { FieldError, ValidationErrorCatcher } from "../../../Api/Common/ApiHelpers";
import { GetAllAuthTypeResponse } from "../../../Models/ApiResponse/AuthType/GetAllAuthTypeResponse";
import { ApplicationFeatureEnum } from "../../../Models/Enums/Applications";
import { editClientSelector } from "../../../Redux/Selectors/Client/EditClientSelectors";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../Redux/Slice/Application/ApplicationSlice";
import { closeAddModal } from "../../../Redux/Slice/Clients/ClientsGridSlice";
import { ApiCallStatus } from "../../../Redux/State/Common/GenericApiState";
import { useAppDispatch } from "../../../Redux/Store";
import { MenuProps } from "../../Common/Static";
import { EditClientInfoTabProps } from "./CommonProps";
import { SourceSystemEnum } from "../../../Models/Enums/SourceSystem";
import { SpecialtyEnum } from "../../../Models/Enums/SpecialtyEnum";

const validationSchema = Yup.object().shape({
  tenantId: Yup.number().required("Tenant is required"),
  clientName: Yup.string().required("Client Name is required"),
  clientCode: Yup.string()
    .required("Client Code is required")
    .max(10, "Client code must be less than 10 digits"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  faxNumber: Yup.string().required("Fax Number is required"),
  serviceTypes: Yup.array().min(1, "Service Type is required"),
  features: Yup.array().min(1, "Features is required"),
});

const BasicClientInfoTab: React.FC<EditClientInfoTabProps> = function ({ active }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { loading, data, clientId } = useSelector(editClientSelector);

  const [serviceTypeLoading, setServiceTypeLoading] = useState(false);

  const [serviceTypes, setServiceTypes] = useState<Array<GetAllAuthTypeResponse>>([]);

  useEffect(() => {
    if (loading == ApiCallStatus.Success && data != null) {
      console.log(data);
      setValues({
        clientName: data.name,
        clientCode: data.clientCode,
        phoneNumber: data.phoneNumber?.toString() || "",
        features: data.clientApplicationFeatures,
        serviceTypes: data.clientAuthTypes,
        tenantId: data.tenantId,
        faxNumber: data.faxNumber?.toString() || "",
        sourceSystem: data.sourceSystemId,
        specialty: data.specialityIds,
        taxId: data.taxId?.toString() || "",
      });

      loadServiceTypes(data.tenantId);
    }
  }, [loading, data]);

  const loadServiceTypes = (tenantId: number) => {
    if (tenantId > 0) {
      setServiceTypeLoading(true);
      GetAllAuthTypesRequest(tenantId)
        .then(({ data: response }) => {
          if (response.succeeded) {
            setServiceTypes(response.data);
          } else {
            dispatch(showErrorSnackbar(response.messages[0]));
          }
          setServiceTypeLoading(false);
        })
        .catch(() => {
          dispatch(showErrorSnackbar("Failed to load service types."));
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      tenantId: 0,
      clientName: "",
      clientCode: "",
      phoneNumber: "",
      faxNumber: "",
      serviceTypes: Array<number>(),
      features: Array<number>(),
      sourceSystem: data?.sourceSystemId ?? 0,
      specialty: Array<number>(),
      taxId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (form, { setSubmitting }) => {
      SaveClient({
        tenantId: form.tenantId,
        clientId: clientId,
        clientName: form.clientName,
        clientCode: form.clientCode,
        phoneNumber: Number(form.phoneNumber),
        faxNumber: Number(form.faxNumber),
        authTypes: form.serviceTypes,
        features: form.features,
        sourceSystemId: form.sourceSystem,
        specialitIds: form.specialty,
        taxId: Number(form.taxId),
      })
        .then(({ data: res }) => {
          console.log(data);
          setSubmitting(false);
          if (res.succeeded) {
            dispatch(closeAddModal());
            dispatch(showSuccessSnackbar("Client Info Saved Successfully."));
          } else {
            dispatch(showErrorSnackbar(res.messages[0]));
          }
        })
        .catch(
          ValidationErrorCatcher((fieldErrors: Array<FieldError>) => {
            fieldErrors.map((fieldError) => {
              formik.setFieldError(fieldError.fieldName, fieldError.errors[0]);
            });
          }),
        )
        .catch(() => {
          setSubmitting(false);
          dispatch(showErrorSnackbar("Failed to create client."));
        });
    },
  });

  const getSkeleton = () => {
    return (
      <Box>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <TextField />
            </Skeleton>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <TextField />
            </Skeleton>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <TextField />
            </Skeleton>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <TextField />
            </Skeleton>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <FormControl>
                <InputLabel>Service Types</InputLabel>
                <Select />
              </FormControl>
            </Skeleton>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <FormControl>
                <InputLabel>Service Types</InputLabel>
                <Select />
              </FormControl>
            </Skeleton>
          </Grid>
        </Grid>
        <Grid
          container
          direction={"row-reverse"}
          paddingTop={theme.spacing(2)}
        >
          <Grid item>
            <Skeleton>
              <Button />
            </Skeleton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
  } = formik;
  return (
    <Box
      hidden={!active}
      paddingTop={theme.spacing(1)}
    >
      {loading == ApiCallStatus.Loading ? (
        getSkeleton()
      ) : (
        <>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <TextField
                name='clientName'
                placeholder='Client Name'
                label='Client Name'
                fullWidth
                value={values.clientName}
                error={touched.clientName && !!errors.clientName}
                helperText={touched.clientName && errors.clientName}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                name='clientCode'
                placeholder='Client Code'
                label='Client Code'
                fullWidth
                value={values.clientCode}
                error={touched.clientCode && !!errors.clientCode}
                helperText={touched.clientCode && errors.clientCode}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                name='phoneNumber'
                placeholder='Phone Number'
                label='Phone Number'
                fullWidth
                value={values.phoneNumber}
                error={touched.phoneNumber && !!errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                name='faxNumber'
                placeholder='Fax Number'
                label='Fax Number'
                fullWidth
                value={values.faxNumber}
                error={touched.faxNumber && !!errors.faxNumber}
                helperText={touched.faxNumber && errors.faxNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              {serviceTypeLoading ? (
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  width='100%'
                >
                  <FormControl>
                    <InputLabel>Service Types</InputLabel>
                    <Select />
                  </FormControl>
                </Skeleton>
              ) : (
                <FormControl
                  fullWidth
                  disabled={isSubmitting}
                >
                  <InputLabel>Service Types</InputLabel>
                  <Select
                    name='serviceTypes'
                    placeholder='Service Types'
                    label='Service Types'
                    multiple
                    value={values.serviceTypes}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                      return serviceTypes
                        .filter((r) => selected.indexOf(r.id) > -1)
                        .map((r) => r.name)
                        .join(", ");
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.serviceTypes && !!errors.serviceTypes}
                  >
                    <MenuItem
                      value=''
                      disabled
                    >
                      <em>Select</em>
                    </MenuItem>
                    {serviceTypes.map((serviceType, index) => (
                      <MenuItem
                        key={"serviceType_" + index}
                        value={serviceType.id}
                      >
                        <Checkbox checked={values.serviceTypes.indexOf(serviceType.id) > -1} />
                        <ListItemText primary={serviceType.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.serviceTypes && !!errors.serviceTypes && (
                    <FormHelperText error={true}>{errors.serviceTypes}</FormHelperText>
                  )}
                </FormControl>
              )}
            </Grid>
            <Grid
              item
              xs={6}
            >
              <FormControl
                fullWidth
                disabled={isSubmitting}
              >
                <InputLabel>Features</InputLabel>
                <Select
                  name='features'
                  placeholder='Features'
                  label='Features'
                  multiple
                  value={values.features}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.features && !!errors.features}
                >
                  <MenuItem
                    value=''
                    disabled
                  >
                    <em>Select</em>
                  </MenuItem>
                  {Object.keys(ApplicationFeatureEnum).map((key, index) => {
                    if (!isNaN(Number(key))) {
                      return (
                        <MenuItem
                          value={key}
                          key={index}
                        >
                          {ApplicationFeatureEnum[Number(key)]}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
                {touched.features && !!errors.features && (
                  <FormHelperText error={true}>{errors.features}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={6}
            >
              <FormControl
                fullWidth
                disabled={isSubmitting}
              >
                <InputLabel>Specialty</InputLabel>
                <Select
                  name='specialty'
                  placeholder='Specialty'
                  label='Specialty'
                  multiple={true}
                  value={values.specialty}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.specialty && !!errors.specialty}
                >
                  <MenuItem
                    value=''
                    disabled
                  >
                    <em>Select</em>
                  </MenuItem>
                  {SpecialtyEnum &&
                    Object.keys(SpecialtyEnum)
                      .filter((key) => !isNaN(Number(key))) // Filter out non-numeric keys
                      .sort(
                        (a, b) =>
                          SpecialtyEnum[Number(a)]?.localeCompare(SpecialtyEnum[Number(b)]) || 0,
                      ) // Sort alphabetically
                      .map((key, index) => (
                        <MenuItem
                          value={key}
                          key={index}
                        >
                          {SpecialtyEnum[Number(key)]}
                        </MenuItem>
                      ))}
                </Select>
                {touched.specialty && !!errors.specialty && (
                  <FormHelperText error={true}>{errors.specialty}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={6}
            >
              <FormControl
                fullWidth
                disabled={isSubmitting}
              >
                <InputLabel>Source System</InputLabel>
                <Select
                  name='sourceSystem'
                  placeholder='Source System'
                  label='Source System'
                  multiple={false}
                  value={values.sourceSystem}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.sourceSystem && !!errors.sourceSystem}
                >
                  <MenuItem
                    value=''
                    disabled
                  >
                    <em>Select</em>
                  </MenuItem>
                  {Object.keys(SourceSystemEnum).map((key, index) => {
                    if (!isNaN(Number(key))) {
                      return (
                        <MenuItem
                          value={key}
                          key={index}
                        >
                          {SourceSystemEnum[Number(key)]}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
                {touched.sourceSystem && !!errors.sourceSystem && (
                  <FormHelperText error={true}>{errors.sourceSystem}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                name='taxId'
                placeholder='Tax ID'
                label='Tax ID'
                fullWidth
                value={values.taxId}
                helperText={touched.taxId && errors.taxId}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction={"row-reverse"}
            paddingTop={theme.spacing(2)}
          >
            <Grid item>
              <LoadingButton
                variant='contained'
                loading={isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save Client Info
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default BasicClientInfoTab;
