import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";

const NotFound = function () {
  return (
    <Container>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingTop={20}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant='h1'>
            <ReportProblemRoundedIcon
              color='warning'
              fontSize='inherit'
            />
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant='h3'>404 - Not Found</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant='h6'>{"Page you are looking for is not found. "}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound;
