export enum ApiCallStatus {
  NoStarted,
  Loading,
  Success,
  Error,
}

export interface GenericApiState<T> {
  data: T;
  status: ApiCallStatus;
}
