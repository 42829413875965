import React, { FC, useEffect } from "react";
import { Outlet } from "react-router";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import { applicationSelector } from "../../Redux/Selectors/Application/ApplicationSelector";
import { useStateSelector } from "../../Redux/Store";
import { IsTokenExpired, IsUserAuthenticated, RedirectToLogin } from "../../Util/AuthHelper";
import BreadcrumbsBar, { BreadcrumbsEnum } from "./BreadcrumbsBar";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

const drawerWidth = 280;
const closedDrawerWidthWithPadding = 105;
const navbarHeight = 64;

type MainPrpos = {
  open: boolean;
};

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<MainPrpos>(
  ({ theme, open }) => ({
    ...theme.typography,
    background: theme.palette.background.default,
    height: `calc(100vh - ${navbarHeight})`,
    marginTop: navbarHeight,
    padding: "20px",
    flexGrow: 1,
    transition: theme.transitions.create(
      "margin",
      open
        ? {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }
        : {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          },
    ),

    width: `calc(100% - ${open ? drawerWidth : closedDrawerWidthWithPadding}px)`,
    maxWidth: `calc(100% - ${open ? drawerWidth : closedDrawerWidthWithPadding}px)`,
  }),
);

const AdminBaseLayout: FC = function () {
  const {
    isSidebarOpen,
    activeMenu,
    activeBreadCumbChip,
    activeBreadCumbChipHelpingText,
    activeBreadCumbPathHelpingTexts,
    subPathReplaceText,
    activeBreadCumbChipLinkParams,
  } = useStateSelector(applicationSelector);

  useEffect(() => {
    if (IsUserAuthenticated() && IsTokenExpired()) {
      RedirectToLogin();
    }
  }, []);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbar isSidebarOpen={isSidebarOpen} />
        <Sidebar
          isOpen={isSidebarOpen}
          activeMenu={activeMenu}
        />
        <Main open={isSidebarOpen}>
          <BreadcrumbsBar
            activeBreadcrumb={activeBreadCumbChip || BreadcrumbsEnum.AdminConsole}
            activeBreadCumbChipLinkParams={activeBreadCumbChipLinkParams || []}
            activeBreadcrumbHelpingText={activeBreadCumbChipHelpingText || ""}
            activeBreadCumbPathHelpingTexts={activeBreadCumbPathHelpingTexts || []}
            subPathReplaceText={subPathReplaceText || []}
          />
          <Outlet />
        </Main>
      </Box>
    </>
  );
};

export default AdminBaseLayout;
