import React, { FC, useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  useTheme,
} from "@mui/material";

import { UpdatePin } from "../../../Api/User/UserEndpoints";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../Redux/Slice/Application/ApplicationSlice";
import { useAppDispatch } from "../../../Redux/Store";
import { editUserSelector } from "../../../Redux/Slice/Users/EditUserSlice";
import { useSelector } from "react-redux";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const pinRegex = /^[a-zA-Z0-9]{5}$/;

const validationSchema = Yup.object().shape({
  pin: Yup.string()
    .matches(pinRegex, "PIN must be 5 letters or digits.")
    .required("Pin is required"),
  confirmPin: Yup.string()
    .required("Confirm Pin is required")
    .oneOf([Yup.ref("pin")], "Pin and Confirm Pin must match")
    .test(
      "not-same-as-current",
      "Current Pin and Confirm Pin must not be the same",
      function (value) {
        return value !== this.parent.currentPin;
      },
    ),
});

export type ChangePinCompProps = {
  userId: string;
  showCancel?: boolean;
  onCancel?(): void;
  onSave?(): void;
};

const ChangePinComp: FC<ChangePinCompProps> = function ({ userId, showCancel, onCancel, onSave }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const state = useSelector(editUserSelector);
  const [showPassword, setShowPassword] = useState(false);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    currentPin: state.userRecord.pin || "",
    pin: "",
    confirmPin: "",
  };

  useEffect(() => {
    formik.setValues({
      currentPin: state.userRecord.pin,
      pin: "",
      confirmPin: "",
    });
    formik.setTouched({
      currentPin: false,
      pin: false,
      confirmPin: false,
    });

    console.log(state.userRecord);
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      UpdatePin(userId, values.pin)
        .then(({ data }) => {
          if (data.succeeded) {
            dispatch(showSuccessSnackbar(data.messages[0]));
            formik.resetForm();
            onSave && onSave();
            navigate("/admin/users");
          } else {
            dispatch(showErrorSnackbar(data.messages[0]));
          }
          setSubmitting(false);
        })
        .catch(() => {
          dispatch(showErrorSnackbar("Something went wrong"));
          setSubmitting(false);
        });
    },
  });
  return (
    <Box>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <FormControl
            sx={{ width: "100%" }}
            variant='outlined'
          >
            <InputLabel htmlFor='outlined-adornment-password'>Current Pin</InputLabel>
            <OutlinedInput
              value={state.userRecord.pin}
              id='outlined-adornment-password'
              type={showPassword ? "text" : "password"}
              readOnly
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label='Current Pin'
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <TextField
            fullWidth
            placeholder='Enter PIN (1 letter, 4 digits)'
            label='Pin'
            name='pin'
            type='password'
            value={formik.values.pin || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.pin && !!formik.errors.pin}
            helperText={formik.touched.pin && formik.errors.pin}
            disabled={formik.isSubmitting}
            inputProps={{ maxLength: 5 }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            fullWidth
            placeholder='Confirm Pin'
            label='Confirm Pin'
            name='confirmPin'
            type='password'
            value={formik.values.confirmPin || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.confirmPin && !!formik.errors.confirmPin}
            helperText={formik.touched.confirmPin && formik.errors.confirmPin}
            disabled={formik.isSubmitting}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row-reverse"}
        paddingTop={theme.spacing(2)}
        spacing={1}
      >
        <Grid item>
          <LoadingButton
            variant='contained'
            type='submit'
            loading={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            Save Changes
          </LoadingButton>
        </Grid>
        {showCancel && (
          <Grid item>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => onCancel && onCancel()}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ChangePinComp;
