import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BreadcrumbsEnum } from "../../../Components/Base/BreadcrumbsBar";

export enum Menu {
  None = "None",
  Dashboard = "Dashboard",
  Servers = "Servers",
  Tenants = "Tenants",
  Clients = "Clients",
  Users = "Users",
  Roles = "Roles",
}

export enum SnackbarMessageType {
  Info = 1,
  Success = 2,
  Error = 3,
}

export type SnackbarValues = {
  message: string;
  type: SnackbarMessageType;
};
export interface IApplicationState {
  isSidebarOpen: boolean;
  isApplicationLoading: boolean;
  activeMenu?: Menu;
  activeBreadCumbChip?: BreadcrumbsEnum;
  activeBreadCumbChipLinkParams?: Array<{ path: BreadcrumbsEnum; params: Record<string, string> }>;
  activeBreadCumbChipHelpingText?: string;
  activeBreadCumbPathHelpingTexts?: Array<{ path: BreadcrumbsEnum; helpingText: string }>;
  subPathReplaceText?: Array<{ path: BreadcrumbsEnum; replaceText: string }>;
  snackbar?: SnackbarValues | null;
}

const getInitialState = () => {
  return {
    isSidebarOpen: false,
    isApplicationLoading: false,
    activeMenu: Menu.None,
    activeBreadCumbChip: BreadcrumbsEnum.AdminConsole,
    activeBreadCumbChipLinkParams: [],
    activeBreadCumbChipHelpingText: "",
    activeBreadCumbPathHelpingTexts: [],
    subPathReplaceText: [],
  } as IApplicationState;
};

const ApplicationSlice = createSlice({
  name: "Application",
  initialState: getInitialState(),
  reducers: {
    setApplicationLoading: (state, action: PayloadAction<boolean>) => {
      state.isApplicationLoading = action.payload;
    },
    setSidebar: (state, action: PayloadAction<boolean>) => {
      state.isSidebarOpen = action.payload;
    },
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    setActiveMenu: (state, action: PayloadAction<Menu>) => {
      state.activeMenu = action.payload;
    },
    setActiveBreadCumb: (state, action: PayloadAction<BreadcrumbsEnum>) => {
      state.activeBreadCumbChip = action.payload;
      state.activeBreadCumbChipHelpingText = "";
      state.activeBreadCumbPathHelpingTexts = [];
    },
    setActiveBreadCumbChipLinkParams: (
      state,
      action: PayloadAction<Array<{ path: BreadcrumbsEnum; params: Record<string, string> }>>,
    ) => {
      state.activeBreadCumbChipLinkParams = action.payload;
    },
    setActiveBreadCumbHelpingText: (state, action: PayloadAction<string>) => {
      state.activeBreadCumbChipHelpingText = action.payload;
    },
    setBreadCumbDisplayText: (
      state,
      action: PayloadAction<Array<{ path: BreadcrumbsEnum; replaceText: string }>>,
    ) => {
      state.subPathReplaceText = action.payload;
    },
    setActiveBreadCumbPathHelpingTexts: (
      state,
      action: PayloadAction<Array<{ path: BreadcrumbsEnum; helpingText: string }>>,
    ) => {
      state.activeBreadCumbPathHelpingTexts = action.payload;
    },
    showSnackbar: (state, action: PayloadAction<SnackbarValues>) => {
      state.snackbar = action.payload;
    },
    showInfoSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbar = {
        message: action.payload,
        type: SnackbarMessageType.Info,
      };
    },
    showSuccessSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbar = {
        message: action.payload,
        type: SnackbarMessageType.Success,
      };
    },
    showErrorSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbar = {
        message: action.payload,
        type: SnackbarMessageType.Error,
      };
    },
    clearSnackbar: (state) => {
      state.snackbar = null;
    },
  },
});

export const {
  setApplicationLoading,
  setSidebar,
  toggleSidebar,
  setActiveMenu,
  setActiveBreadCumb,
  showSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar,
  showErrorSnackbar,
  clearSnackbar,
  setActiveBreadCumbHelpingText,
  setActiveBreadCumbPathHelpingTexts,
  setBreadCumbDisplayText,
  setActiveBreadCumbChipLinkParams,
} = ApplicationSlice.actions;
export default ApplicationSlice.reducer;
