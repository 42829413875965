import { FieldError } from "../../../Api/Common/ApiHelpers";
import { RoleNamesResponse } from "../../../Models/ApiResponse/Role/RoleNamesResponse";
import { BasicTenantClientResponse } from "../../../Models/ApiResponse/Tenant/BasicTenantClientResponse";
import { BasicTenantInfo } from "../../../Models/ApiResponse/Tenant/BasicTenantInfo";
import { ApiCallStatus } from "../Common/GenericApiState";

export enum AddUserStep {
  UserInfo = 1,
  RolesInfo = 2,
  TenantAndClientAssignment = 3,
  Summary = 4,
}

export interface UserInfoStep {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: number | null;
  autoConfirmEmail: boolean;
  isActive: boolean;
  password: string;
  fieldErrors: Array<FieldError>;
}

export interface RolesInfoStep {
  roles: Array<RoleNamesResponse>;
  selectedRoles: Array<string>;
}

export interface TenantAndClientAssignmentStep {
  tenants: Array<BasicTenantInfo>;
  selectedTenants: Array<number>;
  clients: Array<BasicTenantClientResponse>;
  selectedClients: Array<string>;
}

export enum StepStatus {
  NotStarted = 1,
  Completed = 2,
  Error = 3,
}

export interface AddUserState {
  activeStep: AddUserStep;
  loading: ApiCallStatus;
  userInfoStep: UserInfoStep;
  rolesInfoStep: RolesInfoStep;
  tenantAndClientAssignmentStep: TenantAndClientAssignmentStep;
  steps: Record<AddUserStep, StepStatus>;
}
