import { PaginatedResult } from "../../Models/ApiResponse/Common/PaginatedResult";
import { httpClient } from "../APIBase";
import { GetAbortedClaimBatchesQueryResponse } from "../../Models/ApiResponse/TenantDashboard/GetAbortedClaimBatchesQueryResponse";
import { GetInProgressClaimStatusBatchesQueryResponse } from "../../Models/ApiResponse/TenantDashboard/GetInProgressClaimStatusBatchesQueryResponse";
import { GetCompletedClaimStatusBatchesQueryResponse } from "../../Models/ApiResponse/TenantDashboard/GetCompletedClaimStatusBatchesQueryResponse";
import { GetDeletedClaimBatchesQueryResponse } from "../../Models/ApiResponse/TenantDashboard/GetDeletedClaimBatchesQueryResponse";
import { IResult } from "../../Models/ApiResponse/Common/IResult";
import { GetAllPagedRpaInsurancesQueryResponse } from "../../Models/ApiResponse/Dashboard/GetAllPagedRpaInsurancesQueryResponse";
import { GetClaimStatusBatchesHistoriesQueryResponse } from "../../Models/ApiResponse/TenantDashboard/GetClaimStatusBatchesHistoriesQueryResponse";

const getBaseTenantPath = (tenantId: number): string => `/admin/tenants/${tenantId}/dashboard`;

export const GetAbortedClaimBatches = function (
  tenantId: number,
  pageNumber: number,
  pageSize: number,
  quickSearch: string,
  sortField: string,
  sortOrder: string,
) {
  return httpClient.get<PaginatedResult<GetAbortedClaimBatchesQueryResponse>>(
    getBaseTenantPath(tenantId) + "/aborted-claim-batches",
    {
      params: {
        pageNumber,
        pageSize,
        quickSearch,
        sortField,
        sortOrder,
      },
    },
  );
};

export const GetCompletedClaimBatches = function (
  tenantId: number,
  pageNumber: number,
  pageSize: number,
  quickSearch: string,
  sortField: string,
  sortOrder: string,
) {
  return httpClient.get<PaginatedResult<GetCompletedClaimStatusBatchesQueryResponse>>(
    getBaseTenantPath(tenantId) + "/completed-claim-batches",
    {
      params: {
        pageNumber,
        pageSize,
        quickSearch,
        sortField,
        sortOrder,
      },
    },
  );
};
export const GetInProgressClaimBatches = function (
  tenantId: number,
  pageNumber: number,
  pageSize: number,
  quickSearch: string,
  sortField: string,
  sortOrder: string,
) {
  return httpClient.get<PaginatedResult<GetInProgressClaimStatusBatchesQueryResponse>>(
    getBaseTenantPath(tenantId) + "/inprogress-claim-batches",
    {
      params: {
        pageNumber,
        pageSize,
        quickSearch,
        sortField,
        sortOrder,
      },
    },
  );
};
export const GetDeletedClaimBatches = function (
  tenantId: number,
  pageNumber: number,
  pageSize: number,
  quickSearch: string,
  sortField: string,
  sortOrder: string,
) {
  return httpClient.get<PaginatedResult<GetDeletedClaimBatchesQueryResponse>>(
    getBaseTenantPath(tenantId) + "/deleted-claim-batches",
    {
      params: {
        pageNumber,
        pageSize,
        quickSearch,
        sortField,
        sortOrder,
      },
    },
  );
};

export const UnassignClaimStatusBatch = function (tenantId: number, claimStatusBatchId: number) {
  return httpClient.put<IResult<boolean>>(
    getBaseTenantPath(tenantId) + `/unassign-claim-batch/${claimStatusBatchId}`,
  );
};

export const DeleteClaimStatusBatch = function (tenantId: number, claimStatusBatchId: number) {
  return httpClient.delete<IResult<boolean>>(
    getBaseTenantPath(tenantId) + `/delete-claim-batch/${claimStatusBatchId}`,
  );
};

export const RestoreClaimStatusBatch = function (tenantId: number, claimStatusBatchId: number) {
  return httpClient.put<IResult<boolean>>(
    getBaseTenantPath(tenantId) + `/restore-claim-batch/${claimStatusBatchId}`,
  );
};

export const GetAllPagedRpaInsurances = function (
  tenantId: number,
  pageNumber: number,
  pageSize: number,
  quickSearch: string,
  sortField: string,
  sortOrder: string,
  isActiveOnly: boolean,
) {
  return httpClient.get<PaginatedResult<GetAllPagedRpaInsurancesQueryResponse>>(
    getBaseTenantPath(tenantId) + "/active-inactive-rpainsurance",
    {
      params: {
        pageNumber,
        pageSize,
        quickSearch,
        sortField,
        sortOrder,
        isActiveOnly,
      },
    },
  );
};

export const UpdateActiveInactiveRpaInsurance = function (
  tenantId: number,
  rpaInsuranceId: number,
  isActive: boolean,
) {
  return httpClient.put<IResult<boolean>>(
    getBaseTenantPath(tenantId) + "/active-inactive-rpainsurance",
    null,
    {
      params: { rpaInsuranceId, isActive },
    },
  );
};

export const GetClaimStatusBatchHistories = function (
  tenantId: number,
  claimStatusBatchId: number,
  pageNumber: number,
  pageSize: number,
  quickSearch: string,
  sortField: string,
  sortOrder: string,
) {
  return httpClient.get<PaginatedResult<GetClaimStatusBatchesHistoriesQueryResponse>>(
    getBaseTenantPath(tenantId) + "/claim-status-batch-histories",
    {
      params: {
        claimStatusBatchId,
        pageNumber,
        pageSize,
        quickSearch,
        sortField,
        sortOrder,
      },
    },
  );
};
