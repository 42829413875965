import React, { useState, useEffect } from "react";
import {
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Skeleton,
} from "@mui/material";
import { useAppDispatch } from "../../../Redux/Store";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../Redux/Slice/Application/ApplicationSlice";
import {
  GetAssignedUserTenantClients,
  GetTenantClientEmployees,
  UpdateTenantClientEmployees,
} from "../../../Api/User/UserEndpoints";
import { BasicTenantClientResponse } from "../../../Models/ApiResponse/Tenant/BasicTenantClientResponse";
import { GetAllTenantsClients } from "../../../Api/Tenants/TenantEndpoints";

interface ManageEmployeeInClientsModalProps {
  nameToDisplay: string;
  userId: string;
  onCancel: () => void;
  onSave: () => void;
}

const ManageEmployeeInClientsModal: React.FC<ManageEmployeeInClientsModalProps> = function ({
  nameToDisplay,
  userId,
  onCancel,
  onSave,
}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [clients, setClients] = useState<Array<BasicTenantClientResponse>>([]);
  const [selectedClientEmployees, setSelectedClientEmployees] = useState<Array<string>>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchUserTenantClients();
  }, []);

  const fetchUserTenantClients = async () => {
    try {
      setLoading(true);
      const response = await GetAssignedUserTenantClients(userId);
      if (response.data.succeeded) {
        const tenantIds = response.data.data.map((x) => x.tenantId).join(",");

        Promise.all([GetAllTenantsClients(tenantIds), GetTenantClientEmployees(userId)]).then(
          ([clientsResponse, employeesResponse]) => {
            if (clientsResponse.data.succeeded && employeesResponse.data.succeeded) {
              setClients(clientsResponse.data.data);
              const tempSelectedClientEmployees: Array<string> = [];

              Object.keys(employeesResponse.data.data).forEach((tenantId: any) => {
                employeesResponse.data.data[tenantId].forEach((clientId: number) => {
                  tempSelectedClientEmployees.push(getTenantClientStr(tenantId, clientId));
                });
              });

              setSelectedClientEmployees(tempSelectedClientEmployees);
              setLoading(false);
            } else {
              dispatch(showErrorSnackbar("Failed to fetch user tenant clients"));
              setLoading(false);
            }
          },
        );
      } else {
        setLoading(false);
        dispatch(showErrorSnackbar(response.data.messages[0]));
      }
    } catch (error) {
      dispatch(showErrorSnackbar("Failed to fetch user tenant clients"));
    }
  };

  const handleSave = async () => {
    try {
      const tenantClientsEmployeeArr: { [key: number]: number[] } = {};

      selectedClientEmployees.forEach((tenantClient) => {
        const tenantId = parseInt(tenantClient.split("|")[0]);
        const clientId = parseInt(tenantClient.split("|")[1]);

        if (!tenantClientsEmployeeArr[tenantId]) {
          tenantClientsEmployeeArr[tenantId] = [];
        }

        tenantClientsEmployeeArr[tenantId].push(clientId);
      });

      setSubmitting(true);

      const response = await UpdateTenantClientEmployees(userId, tenantClientsEmployeeArr);
      if (response.data.succeeded) {
        dispatch(showSuccessSnackbar("Clients Employee updated successfully"));
        setSubmitting(false);
        onSave();
      } else {
        dispatch(showErrorSnackbar(response.data.messages[0]));
        setSubmitting(false);
      }
    } catch (error) {
      setSubmitting(false);
      dispatch(showErrorSnackbar("Failed to update employee clients"));
    }
  };

  const getTenantClientStr = (tenantId: number, clientId: number) => `${tenantId}|${clientId}`;

  const getSkeleton = () => (
    <>
      <DialogContent>
        <Skeleton
          variant='rounded'
          animation='wave'
          width='100%'
        >
          <FormControl>
            <InputLabel>Manage Employee In Clients for</InputLabel>
            <Select fullWidth></Select>
          </FormControl>
        </Skeleton>
      </DialogContent>
      <DialogActions>
        <Skeleton
          variant='rectangular'
          animation='wave'
        >
          <Button />
          <Button />
        </Skeleton>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>Manage Employee In Clients for {nameToDisplay}</DialogTitle>
      {loading ? (
        getSkeleton()
      ) : (
        <>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel htmlFor='make-employee-client-dropdown'>
                Make Employee In Client
              </InputLabel>
              <Select
                labelId='make-employee-client-dropdown'
                id='make-employee-client-dropdown'
                multiple
                fullWidth
                disabled={submitting}
                value={selectedClientEmployees}
                input={<OutlinedInput label='Make Employee In Client' />}
                renderValue={(selected) => {
                  const retVal = clients.flatMap((tenant) =>
                    tenant.clients
                      .filter((client) =>
                        selected.includes(getTenantClientStr(tenant.tenantId, client.clientId)),
                      )
                      .map((client) => `${client.clientName} (${tenant.tenantName})`),
                  );
                  return retVal.join(", ");
                }}
              >
                {clients.map((tenantClient, index) => (
                  <div key={index}>
                    <MenuItem disabled>{tenantClient.tenantName}</MenuItem>
                    {tenantClient.clients.map((client, cIndex) => (
                      <MenuItem
                        key={`employeeClient_${cIndex}`}
                        value={getTenantClientStr(tenantClient.tenantId, client.clientId)}
                      >
                        <Checkbox
                          checked={selectedClientEmployees.includes(
                            getTenantClientStr(tenantClient.tenantId, client.clientId),
                          )}
                          onChange={({ target: { checked } }) => {
                            const checkBoxValue = getTenantClientStr(
                              tenantClient.tenantId,
                              client.clientId,
                            );
                            if (checked && !selectedClientEmployees.includes(checkBoxValue)) {
                              setSelectedClientEmployees([
                                ...selectedClientEmployees,
                                checkBoxValue,
                              ]);
                            }
                            if (!checked && selectedClientEmployees.includes(checkBoxValue)) {
                              setSelectedClientEmployees(
                                selectedClientEmployees.filter((item) => item !== checkBoxValue),
                              );
                            }
                          }}
                        />
                        <ListItemText primary={client.clientName} />
                      </MenuItem>
                    ))}
                  </div>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onCancel}
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button
              disabled={submitting}
              onClick={handleSave}
              color='primary'
            >
              Save
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ManageEmployeeInClientsModal;
