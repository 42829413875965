import React, { useEffect } from "react";
import { useParams } from "react-router";
import ClaimStatusBatchHistoriesGrid from "../../../Components/Tenant/Dashboard/ClaimStatusBatchHistories/ClaimStatusBatchHistoriesGrid";
import { useDispatch } from "react-redux";
import {
  Menu,
  setActiveBreadCumb,
  setActiveBreadCumbPathHelpingTexts,
  setActiveMenu,
  setBreadCumbDisplayText,
} from "../../../Redux/Slice/Application/ApplicationSlice";
import { BreadcrumbsEnum } from "../../../Components/Base/BreadcrumbsBar";
import { GetTenantNameById } from "../../../Api/Tenants/TenantEndpoints";

const ViewClaimStatusBatchHistories: React.FC = function () {
  const { tenantId, claimStatusBatchId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveMenu(Menu.Tenants));
    dispatch(setActiveBreadCumb(BreadcrumbsEnum.ViewClaimStatusBatchHistories));
    GetTenantNameById(Number(tenantId)).then(({ data }) => {
      if (data.succeeded) {
        dispatch(
          setBreadCumbDisplayText([
            { path: BreadcrumbsEnum.TenantInstance, replaceText: data.data },
          ]),
        );
        dispatch(
          setActiveBreadCumbPathHelpingTexts([
            { path: BreadcrumbsEnum.ViewClaimStatusBatch, helpingText: claimStatusBatchId || "" },
          ]),
        );
      }
    });
  }, []);
  return (
    <ClaimStatusBatchHistoriesGrid
      tenantId={Number(tenantId)}
      claimStatusBatchId={Number(claimStatusBatchId)}
    />
  );
};

export default ViewClaimStatusBatchHistories;
