import axios from "axios";
import {
  ClearAuthenticationValues,
  GetAuthenticationToken,
  IsTokenExpired,
  IsUserAuthenticated,
} from "../Util/AuthHelper";
import { IsDevEnvironment } from "../Util/Helpers";

const getAuthToken = () => (IsUserAuthenticated() ? `Bearer ${GetAuthenticationToken()}` : "");

const MINUTES = 60000;

const instance = axios.create({
  baseURL: IsDevEnvironment() ? `${process.env.REACT_APP_API_URL}api/` : "/api/",
  timeout: 2 * MINUTES,
});

instance.interceptors.request.use((req) => {
  req.headers.Authorization = getAuthToken();
  return req;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 || IsTokenExpired()) {
      ClearAuthenticationValues();
      window.location.href = "/login";
    } else if (error?.response?.status === 403) {
      window.location.href = "/unauthorized";
    }
    return Promise.reject(error);
  },
);

export { instance as httpClient };
