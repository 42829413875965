import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  GetTermsAndConditions,
  GetPrivacyPolicy,
} from "../../Api/TermAndConditionEndPoint/TermAndConditionEndPoint"; // Import the new functions

interface DocumentDialogProps {
  open: boolean;
  onClose: () => void;
  documentType: "terms" | "privacy";
  title: string;
}

const DocumentDialog: React.FC<DocumentDialogProps> = function ({
  open,
  onClose,
  documentType,
  title,
}) {
  const [docContent, setDocContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false); // State for loading indicator

  useEffect(() => {
    if (open) {
      let fetchDocument: () => Promise<{ data: string }>;

      switch (documentType) {
        case "terms":
          fetchDocument = GetTermsAndConditions;
          break;
        case "privacy":
          fetchDocument = GetPrivacyPolicy;
          break;
        default:
          throw new Error("Invalid document type");
      }

      setLoading(true); // Set loading to true when fetching starts

      fetchDocument()
        .then((response: any) => {
          setDocContent(response);
        })
        .catch((error) => {
          console.error("Error fetching document:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when fetching completes
        });
    }
  }, [open, documentType]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {loading ? ( // Show loading indicator if loading is true
          <div
            style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200 }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: docContent }} />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color='primary'
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentDialog;
