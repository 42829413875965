import { AddEditTenantRequest } from "../../Models/ApiRequest/Admin/Tenant/AddEditTenantRequest";
import { IResult } from "../../Models/ApiResponse/Common/IResult";
import { PaginatedResult } from "../../Models/ApiResponse/Common/PaginatedResult";
import { GetErrorOrFailedClientInsuranceRpaConfigByClientIdResponse } from "../../Models/ApiResponse/Shared/GetFailedClientInsuranceRpaConfigurationsResponse";
import { BasicTenantClientResponse } from "../../Models/ApiResponse/Tenant/BasicTenantClientResponse";
import { BasicTenantInfo } from "../../Models/ApiResponse/Tenant/BasicTenantInfo";
import { GetTenantUsersUserLoginHistoryGridResponse } from "../../Models/ApiResponse/Tenant/GetTenantUsersUserLoginHistoryGridResponse";
import { TenantAssociatedUserResponse } from "../../Models/ApiResponse/Tenant/TenantAssociatedUserResponse";
import { TenantInfo } from "../../Models/ApiResponse/Tenant/TenantInfo";
import { httpClient } from "../APIBase";

const baseTenantPath = "/admin/tenants";

const GetTenantsPageRequest = function (
  currentPage: number,
  pageSize: number,
  search: string,
  isActiveOnly: boolean,
) {
  return httpClient.get<PaginatedResult<TenantInfo>>(baseTenantPath, {
    params: {
      currentPage,
      pageSize,
      search,
      isActiveOnly,
    },
  });
};

const GetAllTenantsBasicInfo = function () {
  return httpClient.get<IResult<Array<BasicTenantInfo>>>(`${baseTenantPath}/all`);
};

const GetAllTenantsClients = function (tenantIds: string) {
  return httpClient.get<IResult<Array<BasicTenantClientResponse>>>(`${baseTenantPath}/clients`, {
    params: {
      ids: tenantIds,
    },
  });
};

const GetTenantInfo = function (tenantId: number) {
  return httpClient.get<IResult<TenantInfo>>(`${baseTenantPath}/${tenantId}`);
};

const SaveTenant = function (addEditTenantRequest: AddEditTenantRequest) {
  return httpClient.post<IResult<number>>(baseTenantPath, addEditTenantRequest, {
    timeout: 600000,
  });
};

const GetFailedConfiguration = function (tenantId: number, clientId?: number) {
  return httpClient.get<IResult<Array<GetErrorOrFailedClientInsuranceRpaConfigByClientIdResponse>>>(
    `${baseTenantPath}/${tenantId}/failed-configurations`,
    {
      params: {
        clientId: clientId || 0,
      },
    },
  );
};

const GetTenantNameById = function (tenantId: number) {
  return httpClient.get<IResult<string>>(`${baseTenantPath}/${tenantId}/name`);
};

const GetTenantAssociatedUsersGrid = function (
  tenantId: number,
  currentPage: number,
  pageSize: number,
  search: string,
) {
  return httpClient.get<PaginatedResult<TenantAssociatedUserResponse>>(
    `${baseTenantPath}/${tenantId}/associated-users`,
    {
      params: {
        currentPage,
        pageSize,
        search,
      },
    },
  );
};

const GetTenantAssociatedUsersLoginHistoryGrid = function (
  tenantId: number,
  currentPage: number,
  pageSize: number,
  search: string,
) {
  return httpClient.get<PaginatedResult<GetTenantUsersUserLoginHistoryGridResponse>>(
    `${baseTenantPath}/${tenantId}/associated-users-logins`,
    {
      params: {
        currentPage,
        pageSize,
        search,
      },
    },
  );
};

export {
  GetTenantAssociatedUsersLoginHistoryGrid,
  GetTenantAssociatedUsersGrid,
  GetAllTenantsBasicInfo,
  GetAllTenantsClients,
  GetFailedConfiguration,
  GetTenantInfo,
  GetTenantNameById,
  GetTenantsPageRequest,
  SaveTenant,
};
