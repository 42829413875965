import { IResult } from "../../Models/ApiResponse/Common/IResult";
import { httpClient } from "../APIBase";
import { CatchServerError } from "../Common/ApiHelpers";

export const GetTermsAndConditions = function (): Promise<IResult<string>> {
  return new Promise<IResult<string>>((resolve, reject) => {
    httpClient
      .get("v1/termAndConditionDocument/terms")
      .then((response) => {
        if (response.status == 200) {
          const res = response.data;
          console.log(typeof response.data);
          if (res) {
            resolve(res);
          }
        }
      })
      .catch(CatchServerError(reject));
  });
};

export const GetPrivacyPolicy = function (): Promise<IResult<string>> {
  return new Promise<IResult<string>>((resolve, reject) => {
    httpClient
      .get("v1/termAndConditionDocument/privacy")
      .then((response) => {
        if (response.status == 200) {
          const res = response.data;
          console.log(typeof response.data);
          if (res) {
            resolve(res);
          }
        }
      })
      .catch(CatchServerError(reject));
  });
};
