import { createSelector } from "@reduxjs/toolkit";
import { RootSelector } from "../RootSelector";

export const authUserSelector = createSelector(
  RootSelector,
  (state) => state.AutheticatedUserSlice,
);

export const authUserIdSelector = createSelector(authUserSelector, (state) => state.userId);

export const isUserAuthenticatedSelector = createSelector(
  authUserSelector,
  (state) => state.userId,
);

export const authUserInfoSelector = createSelector(authUserSelector, (state) => state.userInfo);

export const authUserFirstNameSelector = createSelector(
  authUserInfoSelector,
  (state) => state?.firstName,
);

export const authUserDisplayNameSelector = createSelector(
  authUserInfoSelector,
  (state) => state?.firstName + " " + state?.lastName,
);
