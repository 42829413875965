import {
  colors,
  createTheme,
  ExtendedTypographyVariantsOptions,
} from "@mui/material";


const defaultTheme = createTheme({});
const aitTheme = createTheme(defaultTheme, {
  typography: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    allVariants: {
      fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    },
    h1: {
      fontSize: "6rem",
      fontWeight: 300,
      fineHeight: 1.167,
      letterSpacing: "-.01562em",
    },
    h2: {
      fontSize: "3.75rem",
      fontWeight: 300,
      lineHeight: 1.2,
      letterSpacing: "-.00833em",
    },
    h3: {
      fontSize: "3rem",
      fontWeight: 400,
      lineHeight: 1.167,
      letterSpacing: "0",
    },
    h4: {
      fontSize: "2.125rem",
      fontWeight: 400,
      lineHeight: 1.235,
      letterSpacing: ".00735em",
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 400,
      lineHeight: 1.334,
      letterSpacing: "0",
    },
    h6: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: 1.6,
      letterSpacing: ".0075em",
    },
    button: {
      fontSize: ".875rem",
      fontWeight: 500,
      lineHeight: 1.75,
      letterSpacing: ".02857em",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: ".00938em",
    },
    body2: {
      fontSize: ".875rem",
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: ".01071em",
    },
    caption: {
      fontSize: ".75rem",
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: ".03333em",
    },
    subtitle2: {
      fontSize: ".875rem",
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: ".00714em",
    },
    fieldlabel: {
      fontSize: ".875rem",
      fontWeight: 1000,
      lineHeight: 1.5,
      letterSpacing: ".00714em",
    },
    fieldlabelValue: {
      fontSize: ".875rem",
      fontWeight: 100,
      lineHeight: 1.5,
      letterSpacing: ".00714em",
    },
  } as ExtendedTypographyVariantsOptions,
  palette: {
    primary: {
      main: "#1E88E5",
    },
    background: {
      default: colors.grey[100],
    },
    success: {
      main: "#007E33",
    },
  },
  shape: {
    borderRadius: 3,
  },
});

export { aitTheme };
