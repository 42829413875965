import React, { FC, useEffect } from "react";
import { UserStepProps } from "./CommonProps";
import { Box, Button, Divider, Grid, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../../Redux/Store";
import {
  addUserSelector,
  createUserThunk,
  setActiveStep,
} from "../../../Redux/Slice/Users/AddUserSlice";
import { AddUserStep, StepStatus } from "../../../Redux/State/User/AddUserState";
import { useSelector } from "react-redux";
import { ApiCallStatus } from "../../../Redux/State/Common/GenericApiState";
import { useNavigate } from "react-router-dom";

const SummaryStep: FC<UserStepProps> = function ({ active }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    userInfoStep: { firstName, lastName, email, isActive, autoConfirmEmail, userName, phoneNumber },
    rolesInfoStep: { roles, selectedRoles },
    tenantAndClientAssignmentStep: { selectedTenants, tenants, selectedClients, clients },
    steps,
    loading,
  } = useSelector(addUserSelector);

  useEffect(() => {
    if (steps[AddUserStep.Summary] == StepStatus.Completed) {
      navigate("/admin/users", { replace: true });
    }
  }, [steps[AddUserStep.Summary]]);

  return (
    <Box hidden={!active}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant='h6'>User Information</Typography>
          <Divider />
          <table>
            <tbody>
              <tr>
                <td>
                  <Typography variant='fieldlabel'>Username:</Typography>
                </td>
                <td>
                  <Typography variant='fieldlabelValue'>{userName}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant='fieldlabel'>First Name:</Typography>
                </td>
                <td>
                  <Typography variant='fieldlabelValue'>{firstName}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant='fieldlabel'>Last Name:</Typography>
                </td>
                <td>
                  <Typography variant='fieldlabelValue'>{lastName}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant='fieldlabel'>Email:</Typography>
                </td>
                <td>
                  <Typography variant='fieldlabelValue'>{email}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant='fieldlabel'>Phone Number:</Typography>
                </td>
                <td>
                  <Typography variant='fieldlabelValue'>{phoneNumber}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant='fieldlabel'>Is Active?:</Typography>
                </td>
                <td>
                  <Typography variant='fieldlabelValue'>{isActive ? "Yes" : "No"}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant='fieldlabel'>Auto Confirm Email:</Typography>
                </td>
                <td>
                  <Typography variant='fieldlabelValue'>
                    {autoConfirmEmail ? "Yes" : "No"}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography variant='h6'>Roles</Typography>
          <Divider />
          <table>
            <tbody>
              <tr>
                <td>
                  <Typography variant='fieldlabel'>Roles:</Typography>
                </td>
                <td>
                  <Typography variant='fieldlabelValue'>
                    {selectedRoles.length == 0
                      ? "No Roles Selected"
                      : selectedRoles
                          .map((x) => {
                            return roles.find((r) => r.normalizedName == x)?.name;
                          })
                          .join(", ")}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography variant='h6'>Tenant and Clients</Typography>
          <Divider />
          <table>
            <tbody>
              <tr>
                <td>
                  <Typography variant='fieldlabel'>Tenants:</Typography>
                </td>
                <td>
                  <Typography variant='fieldlabelValue'>
                    {selectedTenants.length == 0
                      ? "No Tenants Selected"
                      : selectedTenants
                          .map((x) => {
                            return tenants.find((r) => r.tenantId == x)?.tenantName;
                          })
                          .join(", ")}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant='fieldlabel'>Clients:</Typography>
                </td>
                <td>
                  <Typography variant='fieldlabelValue'>
                    {selectedClients.length == 0
                      ? "No Clients Selected"
                      : selectedClients
                          .map((x) => {
                            const tenantId = parseInt(x.split("|")[0]);
                            const clientId = parseInt(x.split("|")[1]);
                            const tenantClient = clients.find((r) => r.tenantId == tenantId);
                            const tenantName = tenantClient?.tenantName;
                            const clientName = tenantClient?.clients.find(
                              (c) => c.clientId == clientId,
                            )?.clientName;
                            return `${clientName} (${tenantName})`;
                          })
                          .join(", ")}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row-reverse"}
        spacing={1}
        paddingTop={theme.spacing(2)}
      >
        <Grid item>
          <LoadingButton
            variant='contained'
            loading={loading == ApiCallStatus.Loading}
            onClick={() => {
              dispatch(createUserThunk());
            }}
          >
            Confirm and Save
          </LoadingButton>
        </Grid>
        <Grid item>
          <Button
            color='secondary'
            disabled={loading == ApiCallStatus.Loading}
            variant='contained'
            onClick={() => {
              dispatch(setActiveStep(AddUserStep.TenantAndClientAssignment));
            }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SummaryStep;
