import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetServersPageRequest } from "../../../Api/Server/ServerEndpoitns";
import { PaginatedResult } from "../../../Models/ApiResponse/Common/PaginatedResult";
import { ServerInfo } from "../../../Models/ApiResponse/Server/ServerInfo";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { ServerGridState } from "../../State/Server/ServerGridState";
import { RootState } from "../../Store";

const getInitialState = (): ServerGridState => {
  return {
    rows: [],
    status: ApiCallStatus.NoStarted,
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
    isAddEditModalOpen: false,
    serverId: 0,
    quickSearch: "",
  } as ServerGridState;
};

const fetchPagedSevers = createAsyncThunk<PaginatedResult<ServerInfo>, void, { state: RootState }>(
  "servers/fetchAll",
  async (_, thunk) => {
    const { getState } = thunk;
    const state = getState().ServerGridSlice;
    const response = await GetServersPageRequest(
      state.currentPage,
      state.pageSize,
      state.quickSearch,
    );
    return response.data as PaginatedResult<ServerInfo>;
  },
);

const serverGrid = createSlice({
  name: "ServerGrid",
  initialState: getInitialState(),
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload + 1;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    showAddEditModal: (state, action: PayloadAction<number>) => {
      state.isAddEditModalOpen = true;
      state.serverId = action.payload;
    },
    hideAddEditModal: (state) => {
      state.isAddEditModalOpen = false;
      state.serverId = 0;
    },
    setQuickSearch: (state, action: PayloadAction<string>) => {
      state.quickSearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPagedSevers.fulfilled, (state, action) => {
      if (action.payload.succeeded) {
        state.rows = action.payload.data;
        state.currentPage = action.payload.currentPage;
        state.totalCount = action.payload.totalCount;
        state.totalPages = action.payload.totalPages;
        state.status = ApiCallStatus.Success;
      } else {
        state.status = ApiCallStatus.Error;
      }
    });
    builder.addCase(fetchPagedSevers.pending, (state) => {
      state.status = ApiCallStatus.Loading;
    });
    builder.addCase(fetchPagedSevers.rejected, (state) => {
      state.rows = [];
      state.status = ApiCallStatus.Error;
      state.error = "Something went wrong";
    });
  },
});

export const { setPage, setPageSize, showAddEditModal, hideAddEditModal, setQuickSearch } =
  serverGrid.actions;
export const serverGridSelector = (state: RootState) => state.ServerGridSlice;
export { fetchPagedSevers };
export default serverGrid.reducer;
