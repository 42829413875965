import React, {
  FC,
  useEffect,
} from "react";
import {
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { BreadcrumbsEnum } from "../../Components/Base/BreadcrumbsBar";
import SearchTextField
  from "../../Components/Common/SearchTextField/SearchTextField";
import PaperCard from "../../Components/Common/Styled/PaperCard";
import AddEditServerForm from "../../Components/Server/AddEditServerForm";
import ServerGrid from "../../Components/Server/ServerGrid";
import {
  Menu,
  setActiveBreadCumb,
  setActiveMenu,
} from "../../Redux/Slice/Application/ApplicationSlice";
import {
  fetchPagedSevers,
  serverGridSelector,
  setQuickSearch,
  showAddEditModal,
} from "../../Redux/Slice/Servers/ServerGridSlice";
import { useAppDispatch, useStateSelector } from "../../Redux/Store";


const ViewServers: FC = function () {
  const dispatch = useAppDispatch();
  const state = useStateSelector(serverGridSelector);

  useEffect(() => {
    dispatch(setActiveMenu(Menu.Servers));
    dispatch(setActiveBreadCumb(BreadcrumbsEnum.Servers));
  }, []);

  return (
    <>
      {state.isAddEditModalOpen && (
        <AddEditServerForm
          serverId={state.serverId}
          onSuccess={() => dispatch(fetchPagedSevers())}
        />
      )}
      <PaperCard>
        <Stack
          pb={1}
          direction="row"
          spacing={1}
        >
          <Typography variant='h5' style={{
            flex: 1
          }}>Servers</Typography>
          <SearchTextField
            label="Search"
            placeholder="Search"
            onSearch={(text) => {
              dispatch(setQuickSearch(text));
              dispatch(fetchPagedSevers());
            }}
            size="small" />
          <Button
            variant='contained'
            onClick={() => dispatch(showAddEditModal(0))}
          >
            Create
          </Button>
          <Button
            variant='contained'
            color="secondary"
            onClick={() => {
              dispatch(fetchPagedSevers());
            }}
          >
            Reload
          </Button>
        </Stack>
        <ServerGrid />
      </PaperCard>
    </>
  );
};

export default ViewServers;
