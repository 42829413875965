import React, { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container, Grid, Paper, TextField, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate } from "react-router";
import { ConfirmUserEmail, ResetPassword } from "../../Api/User/UserEndpoints";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "../../Redux/Slice/Application/ApplicationSlice";
import { useAppDispatch } from "../../Redux/Store";

// Define validation schema for the reset password form
const validationSchema = Yup.object().shape({
  password: Yup.string().min(8, "Password must be at least 8 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm password is required"),
});

const ConfirmationEmail: FC = function () {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // Use URLSearchParams to parse the query parameters from the location.search string
  const queryParams = new URLSearchParams(location.search);

  // Get the values of the 'userId' and 'code' query parameters
  const userIdpara = queryParams.get("userId");
  const codepara = queryParams.get("code");

  useEffect(() => {
    if (userIdpara && userIdpara.length) {
      ConfirmUserEmail(userIdpara, codepara)
        .then(({ data }) => {
          if (data.succeeded) {
            dispatch(showSuccessSnackbar(data.messages[0]));
          } else {
            dispatch(showErrorSnackbar(data.messages[0]));
          }
        })
        .catch(() => {
          dispatch(showErrorSnackbar("Something went wrong"));
        });
    }
  }, []);

  const theme = useTheme();
  const [message] = useState("");

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (userIdpara && userIdpara.length) {
        ResetPassword({ userId: userIdpara, password: values.password })
          .then(({ data }) => {
            if (data.succeeded) {
              dispatch(showSuccessSnackbar(data.messages[0]));
              formik.resetForm();
              //  onSave();
            } else {
              dispatch(showErrorSnackbar(data.messages[0]));
            }
            setSubmitting(false);
            navigate("/login");
          })
          .catch(() => {
            dispatch(showErrorSnackbar("Something went wrong"));
            setSubmitting(false);
          });
      }
    },
  });

  const { touched, errors, handleChange, values, handleSubmit, isSubmitting } = formik;

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        background: theme.palette.background.default,
      }}
    >
      <Container>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          paddingTop={20}
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={5}
          >
            <Paper
              elevation={3}
              style={{ padding: 30 }}
            >
              <Grid container>
                <Grid
                  item
                  container
                  spacing={2}
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid item>
                    <Typography variant='h4'>Reset Password</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Enter your new password.</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      placeholder='Password'
                      name='password'
                      type='password'
                      variant='outlined'
                      label='Password'
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      onChange={handleChange}
                      value={values.password}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      placeholder='Confirm Password'
                      name='confirmPassword'
                      type='password'
                      variant='outlined'
                      label='Confirm Password'
                      error={touched.confirmPassword && !!errors.confirmPassword}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      onChange={handleChange}
                      value={values.confirmPassword}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <LoadingButton
                      variant='contained'
                      loading={isSubmitting}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                  {message && (
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography color={theme.palette.success.main}>{message}</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ConfirmationEmail;
