import React, { useEffect } from "react";
import AitDataGrid from "../../../Common/DataGrid/AitDataGrid";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import {
  ActiveRpaInsuraceGridSelector,
  fetchActiveRpaInsurance,
  setPage,
  setPageSize,
  setSortModel,
} from "../../../../Redux/Slice/TenantDashboard/RPAInsurance/ActiveRPAInsuranceGridSlice";
import { useAppDispatch } from "../../../../Redux/Store";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../../Redux/Slice/Application/ApplicationSlice";
import { UpdateActiveInactiveRpaInsurance } from "../../../../Api/Tenants/TenantDashboardEndpoints";

export type ActiveRPAInsurancesGridProps = {
  tenantId: number;
  quickSearch: string;
};

const ActiveRPAInsurancesGrid: React.FC<ActiveRPAInsurancesGridProps> = function ({
  tenantId,
  quickSearch,
}) {
  const state = useSelector(ActiveRpaInsuraceGridSelector);
  const dispatch = useAppDispatch();
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 20,
      sortable: true,
      flex: 0,
      type: "string",
    },
    {
      field: "rpaCode",
      headerName: "RPA Code",
      width: 100,
      flex: 1,
      sortable: true,
    },
    {
      field: "targetUrl",
      headerName: "Target URL",
      width: 100,
      flex: 1,
      sortable: true,
    },
    {
      field: "groupName",
      headerName: "Group Name",
      width: 80,
      sortable: true,
    },
    {
      field: "defaultTargetUrl",
      headerName: "Default Target URL",
      width: 100,
      flex: 1,
      sortable: true,
    },
    {
      field: "claimBilledOnWaitDays",
      headerName: "Claim Billed On Wait Days",
      width: 20,
      flex: 1,
      sortable: true,
    },
    {
      field: "approvalWaitPeriodDays",
      headerName: "Approval Wait Period Days",
      width: 100,
      flex: 1,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      sortable: false,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            label='Make Inactive'
            key='MakeInactive'
            showInMenu
            onClick={() => {
              UpdateActiveInactiveRpaInsurance(tenantId, Number(id), false)
                .then((data) => {
                  if (data.data.succeeded) {
                    dispatch(fetchActiveRpaInsurance({ tenantId, quickSearch }));
                    dispatch(
                      showSuccessSnackbar("RPA Insurance " + id + " inactivated successfully."),
                    );
                  } else {
                    dispatch(showErrorSnackbar("Failed to inactive RPA Insurance Id: " + id));
                  }
                })
                .catch(() => {
                  dispatch(showErrorSnackbar("Failed to inactive RPA Insurance Id: " + id));
                });
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchActiveRpaInsurance({ tenantId, quickSearch }));
  }, [
    state.currentPage,
    state.pageSize,
    state.sortModel.field,
    state.sortModel.order,
    quickSearch,
  ]);

  return (
    <>
      <div
        style={{
          height: "400px",
        }}
      >
        <AitDataGrid
          keyColumnName='id'
          columns={columns}
          state={state}
          setPage={setPage}
          setPageSize={setPageSize}
          sortMode='server'
          sortModel={state.sortModel}
          defaultSortModel={{
            field: "id",
            order: "desc",
          }}
          onSortModelChange={(field, order) => {
            dispatch(setSortModel({ field, order }));
          }}
        />
      </div>
    </>
  );
};

export default ActiveRPAInsurancesGrid;
