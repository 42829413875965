import React, { useEffect } from "react";
import { ThemeProvider } from "@emotion/react";
import ApplicationLoader from "./Components/Common/ApplicationLoader";
import GlobalSnackbar from "./Components/Common/GlobalSnackbar";
import { applicationLoadingSelector } from "./Redux/Selectors/Application/ApplicationSelector";
import { isUserAuthenticatedSelector } from "./Redux/Selectors/AuthenticatedUser/AuthenticatedUserSelectors";
import { fetchAuthUserInfo } from "./Redux/Slice/AuthenticatedUser/AutheticatedUserSlice";
import { useAppDispatch, useStateSelector } from "./Redux/Store";
import Router from "./Routes/Router";
import { aitTheme } from "./Style/Theme/AitTheme";

const Footer = function () {
  const copyrightStyle = {
    marginBottom: "0px",
    marginTop: "0px",
    fontSize: "small",
    marginleft: "5px",
  };
  return (
    <div className="copyright-footer">
      <p className='copyright-text' style={copyrightStyle}>Copyright 2024 Automated Integration Technologies, LLC ©</p>
    </div>
  );
};


const App = function () {
  const isApplicationLoading = useStateSelector(applicationLoadingSelector);
  const isUserAuthenticated = useStateSelector(isUserAuthenticatedSelector);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(fetchAuthUserInfo());
    }
  }, [isUserAuthenticated]);

  return (
    <div className='App'>
      {isApplicationLoading ? (
        <ApplicationLoader />
      ) : (
        <ThemeProvider theme={aitTheme}>
          <GlobalSnackbar />
          <Router />
          <Footer />
        </ThemeProvider>
      )}
    </div>
  );
};

export default App;
