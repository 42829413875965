import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetAuthUserInfo } from "../../../Api/User/UserEndpoints";
import { IResult } from "../../../Models/ApiResponse/Common/IResult";
import { UserInfoResponse } from "../../../Models/ApiResponse/User/UserInfoResponse";
import {
  ClearAuthenticationValues,
  GetAutheticatedUserId,
  IsUserAuthenticated,
} from "../../../Util/AuthHelper";
import { RootState } from "../../Store";
import { setApplicationLoading } from "../Application/ApplicationSlice";

export interface IAuthenticatedUserState {
  isUserAuthenticated: boolean;
  userId: string;
  userInfo?: UserInfoResponse | null;
}

const getInitialState = () => {
  return {
    isUserAuthenticated: IsUserAuthenticated(),
    userId: GetAutheticatedUserId(),
  } as IAuthenticatedUserState;
};

const fetchAuthUserInfo = createAsyncThunk<IResult<UserInfoResponse>, void, { state: RootState }>(
  "users/GetAuthUserInfo",
  async (_, thunk) => {
    const { dispatch } = thunk;
    dispatch(setApplicationLoading(true));
    const response = await GetAuthUserInfo();
    dispatch(setApplicationLoading(false));
    return response.data as IResult<UserInfoResponse>;
  },
);

const AutheticatedUserSlice = createSlice({
  name: "AutheticatedUserSlice",
  initialState: getInitialState(),
  reducers: {
    setUserAuthenticated: (state, action: PayloadAction<IAuthenticatedUserState>) => {
      state.isUserAuthenticated = action.payload.isUserAuthenticated;
      state.userId = action.payload.userId;
    },
    clearAuthentication: (state) => {
      state.isUserAuthenticated = false;
      state.userId = "";
      ClearAuthenticationValues();
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAuthUserInfo.fulfilled, (state, action) => {
      if (action.payload.succeeded) {
        state.userInfo = action.payload.data;
      }
    });
  },
});
export { fetchAuthUserInfo };
export const authenticatedUserSelector = (state: RootState) => state.AutheticatedUserSlice;
export const { setUserAuthenticated, clearAuthentication } = AutheticatedUserSlice.actions;
export default AutheticatedUserSlice.reducer;
