import React, { useEffect, useState } from "react";
import { EditUserTabProps } from "./CommonProps";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { MenuProps } from "../../Common/Static";
import { useAppDispatch } from "../../../Redux/Store";
import { editUserSelector } from "../../../Redux/Slice/Users/EditUserSlice";
import { useSelector } from "react-redux";
import { GetAllTenantsClients } from "../../../Api/Tenants/TenantEndpoints";
import {
  setBreadCumbDisplayText,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../Redux/Slice/Application/ApplicationSlice";
import { BasicTenantClientResponse } from "../../../Models/ApiResponse/Tenant/BasicTenantClientResponse";
import { BreadcrumbsEnum } from "../../Base/BreadcrumbsBar";
import { UpdateUserClients } from "../../../Api/User/UserEndpoints";

export type ClientTabProps = EditUserTabProps & {
  tenantId: number;
};
const ClientTab: React.FC<ClientTabProps> = function ({ tenantId, active }) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { userId, userRecord } = useSelector(editUserSelector);

  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [clients, setClients] = useState<Array<BasicTenantClientResponse>>([]);

  const [selectedClients, setSelectedClients] = useState<Array<number>>([]);

  useEffect(() => {
    setLoading(true);
    if (tenantId > 0) {
      GetAllTenantsClients([tenantId].join(","))
        .then(({ data: res }) => {
          setLoading(false);
          if (res.succeeded) {
            setClients(res.data);
            const tenantClientsArr = userRecord.tenantClients.find((x) => x.tenantId === tenantId);
            if (tenantClientsArr) {
              dispatch(
                setBreadCumbDisplayText([
                  {
                    path: BreadcrumbsEnum.TenantInstance,
                    replaceText: tenantClientsArr.tenantName,
                  },
                ]),
              );
              setSelectedClients(tenantClientsArr.clients.map((x) => x.clientId));
            }
          } else {
            dispatch(showErrorSnackbar(res.messages[0]));
          }
        })
        .catch(() => {
          setLoading(false);
          dispatch(showErrorSnackbar("Failed to load clients"));
        });
    }
  }, [userRecord]);

  const handleSave = () => {
    setSubmitting(true);

    UpdateUserClients(userId, {
      tenantId,
      clients: selectedClients,
    })
      .then(({ data: res }) => {
        if (res.succeeded) {
          dispatch(showSuccessSnackbar(res.messages[0]));
        } else {
          dispatch(showErrorSnackbar(res.messages[0]));
        }
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
        dispatch(showErrorSnackbar("Failed to update tenant and client"));
      });
  };

  const getSkeleton = () => (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Skeleton
            variant='rounded'
            animation='wave'
            width='100%'
          >
            <FormControl>
              <InputLabel>Clients</InputLabel>
              <Select fullWidth></Select>
            </FormControl>
          </Skeleton>
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row-reverse"}
        paddingTop={theme.spacing(2)}
      >
        <Button />
      </Grid>
    </>
  );

  return (
    <Box hidden={!active}>
      {loading ? (
        getSkeleton()
      ) : (
        <>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <FormControl fullWidth>
                <InputLabel htmlFor='client-dropdown'>Clients</InputLabel>
                <Select
                  labelId='client-dropdown'
                  id='client-dropdown'
                  multiple
                  fullWidth
                  value={selectedClients}
                  input={<OutlinedInput label='Tag' />}
                  renderValue={(selected) => {
                    const retVal: Array<string> = [];
                    clients.forEach((tenant) => {
                      tenant.clients.forEach((client) => {
                        if (selected.indexOf(client.clientId) > -1) {
                          retVal.push(`${client.clientName} (${tenant.tenantName})`);
                        }
                      });
                    });
                    return retVal.join(", ");
                  }}
                  MenuProps={MenuProps}
                >
                  {clients.map((tenantClient, index) => (
                    <div key={index}>
                      {tenantClient.clients.map((client, cIndex) => {
                        return (
                          <MenuItem
                            key={"client_" + cIndex}
                            value={client.clientId}
                          >
                            <Checkbox
                              checked={selectedClients.findIndex((x) => x === client.clientId) >= 0}
                              onChange={({ target: { checked } }) => {
                                const checkBoxValue = client.clientId;

                                if (checked && selectedClients.indexOf(checkBoxValue) == -1) {
                                  setSelectedClients([...selectedClients, checkBoxValue]);
                                }
                                if (!checked && selectedClients.indexOf(checkBoxValue) > -1) {
                                  const newArr = [...selectedClients];
                                  newArr.splice(
                                    newArr.findIndex((item) => item === checkBoxValue),
                                    1,
                                  );
                                  setSelectedClients(newArr);
                                }
                              }}
                            />
                            <ListItemText primary={client.clientName} />
                          </MenuItem>
                        );
                      })}
                    </div>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            direction={"row-reverse"}
            paddingTop={theme.spacing(2)}
          >
            <LoadingButton
              variant='contained'
              type='submit'
              loading={submitting}
              onClick={handleSave}
            >
              Save Changes
            </LoadingButton>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ClientTab;
