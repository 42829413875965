import React, { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import { SelectTenantClientRequest } from "../../Api/Auth/TokenEndpoints";
import {
  CheckUserIsSuperAdmin,
  GetUserAssignedClients,
  GetUserAssignedTenants,
} from "../../Api/User/UserEndpoints";
import { ClientResponse } from "../../Models/ApiResponse/Tenant/ClientResponse";
import { TenantResponse } from "../../Models/ApiResponse/Tenant/TenantReponse";
import { showErrorSnackbar } from "../../Redux/Slice/Application/ApplicationSlice";
import { useAppDispatch } from "../../Redux/Store";
import { GetAutheticatedUserId } from "../../Util/AuthHelper";
import { RedirectToLogin } from "../../Util/AuthHelper";
import { httpClient } from "../../Api/APIBase";

const selectTenantFormValidationSchema = Yup.object().shape({
  tenant: Yup.string().required("Please select tenant"),
  client: Yup.string().required("Please select client"),
});

const SelectTenantClient: FC = function () {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [adminIsLoading, setAdminIsLoading] = useState(true);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [tenants, setTenants] = useState<Array<TenantResponse>>([]);
  const [clients, setClients] = useState<Array<ClientResponse>>([]);

  const handleLogout = async () => {
    try {
      // Make a POST request to the logout endpoint using httpClient
      const response = await httpClient.get("identity/token/logout");
      // Check if the request was successful (status code 200)
      if (response.status === 200) {
        // Redirect to the login page
        RedirectToLogin();
      } else {
        // Handle unsuccessful logout (optional)
        console.error("Logout failed:", response.statusText);
        // Optionally, display an error message to the user
      }
    } catch (error) {
      // Handle any errors that occur during logout
      console.error("An error occurred during logout:");
      // Optionally, display an error message to the user
    }
  };

  const formik = useFormik({
    initialValues: {
      tenant: "",
      client: "",
    },
    validationSchema: selectTenantFormValidationSchema,
    onSubmit: (_, { setSubmitting }) => {
      setSubmitting(true);
      SelectTenantClientRequest({
        clientCode: _.client,
        tenantIdentifier: _.tenant,
      })
        .then(({ data }) => {
          if (data.succeeded) {
            window.location.href = "/tenant/home/?t=" + data.data.tenantClientString;
          } else {
            dispatch(showErrorSnackbar(data.messages[0]));
          }
        })
        .catch(() => {
          setSubmitting(false);
          dispatch(showErrorSnackbar("Something went wrong."));
        });
    },
  });

  const loadClients = (tenantIdentifier: string) => {
    setClients([]);
    GetUserAssignedClients(tenantIdentifier)
      .then(({ data }) => {
        if (data.succeeded) {
          setClients(data.data);
        } else {
          dispatch(showErrorSnackbar(data.messages[0]));
        }
        setLoading(false);
      })
      .catch(() => {
        dispatch(showErrorSnackbar("Something went wrong"));
      });
  };

  const userId = GetAutheticatedUserId();

  useEffect(() => {
    if (userId != null) {
      setLoading(true);
      GetUserAssignedTenants(userId)
        .then(({ data }) => {
          if (data.succeeded) {
            setTenants(data.data);
          } else {
            dispatch(showErrorSnackbar(data.messages[0]));
          }
          setLoading(false);
        })
        .catch(() => {
          dispatch(showErrorSnackbar("Something went wrong"));
        });
    }
  }, []);

  useEffect(() => {
    if (userId) {
      setAdminIsLoading(true);
      CheckUserIsSuperAdmin(userId)
        .then((res) => {
          setIsSuperAdmin(res.data);
          setAdminIsLoading(false);
        })
        .catch(() => {
          setAdminIsLoading(false);
        });
    }
  }, []);

  const getSkeleton = () => {
    return (
      <Grid
        container
        spacing={2}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid
          item
          xs={12}
        >
          <Skeleton
            variant='rounded'
            animation='wave'
            width='100%'
          >
            <Select fullWidth></Select>
          </Skeleton>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Skeleton
            variant='rounded'
            animation='wave'
            width='100%'
          >
            <Select fullWidth></Select>
          </Skeleton>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Skeleton
            variant='rounded'
            animation='wave'
            width='100%'
          >
            <Button fullWidth />
          </Skeleton>
        </Grid>
      </Grid>
    );
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        background: theme.palette.background.default,
      }}
    >
      <Container>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          paddingTop={30}
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={5}
          >
            <Paper
              elevation={3}
              style={{
                padding: 30,
              }}
            >
              <Grid
                item
                container
                spacing={2}
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid item>
                  <Typography variant='h5'>Select Tenant & Client</Typography>
                </Grid>
                <Grid item>
                  {adminIsLoading ? (
                    <Skeleton
                      variant='rounded'
                      animation='wave'
                      width='100%'
                    >
                      <Typography>Go to Admin Console</Typography>
                    </Skeleton>
                  ) : (
                    <Typography>
                      {isSuperAdmin && (
                        <Link
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => navigate("/admin", { replace: true })}
                        >
                          Go to Admin Console
                        </Link>
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Box pt={3}>
                {loading ? (
                  getSkeleton()
                ) : (
                  <Grid
                    container
                    spacing={3}
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <FormControl
                        fullWidth
                        disabled={formik.isSubmitting}
                      >
                        <InputLabel>Tenant</InputLabel>
                        <Select
                          name='tenant'
                          fullWidth
                          label='Tenant'
                          onChange={(x) => {
                            loadClients(x.target.value as string);
                            formik.handleChange(x);
                          }}
                          onBlur={formik.handleBlur}
                          error={formik.touched.tenant && !!formik.errors.tenant}
                        >
                          <MenuItem
                            value=''
                            disabled
                          >
                            <em>Select</em>
                          </MenuItem>
                          {tenants.map((x, index) => (
                            <MenuItem
                              key={index}
                              value={x.tenantIdentifier}
                            >
                              {x.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.touched.tenant && !!formik.errors.tenant && (
                          <FormHelperText error={true}>{formik.errors.tenant}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <FormControl
                        fullWidth
                        disabled={clients == null || clients.length === 0 || formik.isSubmitting}
                      >
                        <InputLabel>Client</InputLabel>
                        <Select
                          name='client'
                          fullWidth
                          label='Client'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.client} // Ensure selected value reflects formik state
                        >
                          <MenuItem
                            value=''
                            disabled
                          >
                            <em>Select</em>
                          </MenuItem>
                          {clients &&
                            clients
                              .slice() // Create a shallow copy to avoid mutating the original array
                              .sort((a, b) => a.name.localeCompare(b.name)) // Sort clients alphabetically by name
                              .map((x, index) => (
                                <MenuItem
                                  key={index}
                                  value={x.clientCode}
                                >
                                  {x.name}
                                </MenuItem>
                              ))}
                        </Select>
                        {formik.touched.client && !!formik.errors.client && (
                          <FormHelperText error={true}>{formik.errors.client}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <LoadingButton
                        fullWidth
                        variant='contained'
                        loading={formik.isSubmitting}
                        onClick={() => formik.handleSubmit()}
                      >
                        Sign In to Tenant
                      </LoadingButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <LoadingButton
                        fullWidth
                        variant='contained'
                        loading={formik.isSubmitting}
                        onClick={handleLogout}
                        style={{ backgroundColor: "#FF0481", color: "white" }}
                      >
                        Sign Out
                      </LoadingButton>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SelectTenantClient;
