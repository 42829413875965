import React, { FC, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { LoadingButton, Skeleton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  useTheme,
} from "@mui/material";
import {
  CheckEmailAvailable,
  CheckUsernameAvailable,
  UpdateBasicUserInfo,
} from "../../../Api/User/UserEndpoints";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../Redux/Slice/Application/ApplicationSlice";
import { editUserSelector, setUserRecord } from "../../../Redux/Slice/Users/EditUserSlice";
import { ApiCallStatus } from "../../../Redux/State/Common/GenericApiState";
import { useAppDispatch } from "../../../Redux/Store";
import NamedAvatar from "../../Common/NamedAvatar";
import { EditUserTabProps } from "./CommonProps";

const validationSchema = Yup.object().shape({
  userName: Yup.string()
    .required("Username is required")
    .matches(/^[a-zA-Z0-9]+$/i, "Only numbers and Alphabets are allowed")
    .min(6, "Username should have atleast 6 characters."),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  phoneNumber: Yup.string().matches(
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
    "Phone number is invalid",
  ),
});

const UserInfoTab: FC<EditUserTabProps> = function ({ active }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const state = useSelector(editUserSelector);

  const initialValues = {
    userName: state.userRecord.userName || "",
    firstName: state.userRecord.firstName || "",
    lastName: state.userRecord.lastName || "",
    email: state.userRecord.email || "",
    phoneNumber: state.userRecord.phoneNumber || null,
    isActive: state.userRecord.isActive,
    emailConfirmed: state.userRecord.emailConfirmed, //EN-147
  };

  useEffect(() => {
    formik.setValues({
      userName: state.userRecord.userName,
      firstName: state.userRecord.firstName,
      lastName: state.userRecord.lastName,
      email: state.userRecord.email,
      phoneNumber: state.userRecord.phoneNumber,
      isActive: state.userRecord.isActive,
      emailConfirmed: state.userRecord.emailConfirmed,
    });
    formik.setTouched({
      email: false,
      firstName: false,
      lastName: false,
      isActive: false,
      phoneNumber: false,
      userName: false,
      emailConfirmed: false,
    });
  }, [state.userRecord]);

  const submitForm = () => {
    formik.setSubmitting(true);
    const { firstName, lastName, userName, email, phoneNumber, isActive, emailConfirmed } =
      formik.values;
    UpdateBasicUserInfo({
      userId: state.userId,
      firstName,
      lastName,
      userName,
      email,
      phoneNumber,
      isActive,
      emailConfirmed, //EN-147
    })
      .then(({ data }) => {
        formik.setSubmitting(false);
        if (data.succeeded) {
          dispatch(showSuccessSnackbar("User information updated successfully."));
          dispatch(setUserRecord(data.data));
        } else {
          dispatch(showErrorSnackbar(data.messages[0]));
        }
      })
      .catch(() => {
        dispatch(showErrorSnackbar("Failed to update user information."));
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      console.log(values, setSubmitting);
      setSubmitting(true);
      try {
        let allowToSubmit = true;
        if (values.userName != state.userRecord.userName) {
          const userNameRes = await CheckUsernameAvailable(values.userName);
          if (userNameRes.data === false) {
            setFieldError("userName", "Username is already exists.");
            allowToSubmit = false;
          }
        }
        if (values.email != state.userRecord.email) {
          const emailRes = await CheckEmailAvailable(values.userName);
          if (emailRes.data === false) {
            setFieldError("email", "Email is already exists.");
            allowToSubmit = false;
          }
        }
        if (allowToSubmit) {
          submitForm();
        }
      } catch (error) {
        console.log(error);
        dispatch(showErrorSnackbar("Something went wrong."));
      }
    },
  });

  const getSkeleton = () => (
    <>
      <Grid container>
        <Grid
          item
          xs={3}
        >
          <Box
            sx={{ height: "100%", width: "100%" }}
            px={2}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='80%'
              height='90%'
            >
              <NamedAvatar
                sx={{ height: "90%", width: "80%" }}
                variant='rounded'
                displayText=''
              ></NamedAvatar>
            </Skeleton>
            <Box
              pt={2}
              mr={5}
              display={"flex"}
              justifyContent={"center"}
            >
              <Skeleton
                variant='rounded'
                animation='wave'
                width='70%'
              >
                <Button />
              </Skeleton>
            </Box>
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={9}
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <TextField fullWidth />
            </Skeleton>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <TextField fullWidth />
            </Skeleton>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <TextField fullWidth />
            </Skeleton>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <TextField fullWidth />
            </Skeleton>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <TextField fullWidth />
            </Skeleton>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox name='isActive' />}
                  name='isActive'
                  label='Active?'
                />
              </FormGroup>
            </Skeleton>
          </Grid>
          <Grid
            item
            xs={12}
          >
            {/* En-147 */}
            <Skeleton
              variant='rounded'
              animation='wave'
              width='100%'
            >
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox name='emailConfirmed' />}
                  name='emailConfirmed'
                  label='emailConfirmed?'
                />
              </FormGroup>
            </Skeleton>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row-reverse"}
        paddingTop={theme.spacing(2)}
      >
        <Grid item>
          <Skeleton>
            <Button></Button>
          </Skeleton>
        </Grid>
      </Grid>
    </>
  );
  return (
    <Box hidden={!active}>
      {state.loading == ApiCallStatus.Loading ? (
        getSkeleton()
      ) : (
        <>
          <Grid container>
            <Grid
              item
              xs={3}
            >
              <Box
                sx={{ height: "100%", width: "100%" }}
                px={2}
              >
                <NamedAvatar
                  sx={{ height: "80%", width: "80%" }}
                  variant='rounded'
                  displayText={state.userRecord.firstName + " " + state.userRecord.userName}
                ></NamedAvatar>
                <Box
                  pt={2}
                  mr={5}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Button
                    variant='outlined'
                    disabled={formik.isSubmitting}
                  >
                    Upload Image
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={9}
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  placeholder='Username'
                  label='Username'
                  name='userName'
                  value={formik.values.userName || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.userName && !!formik.errors.userName}
                  helperText={formik.touched.userName && formik.errors.userName}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <TextField
                  fullWidth
                  placeholder='First Name'
                  label='First Name'
                  name='firstName'
                  value={formik.values.firstName || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.firstName && !!formik.errors.firstName}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <TextField
                  fullWidth
                  placeholder='Last Name'
                  label='Last Name'
                  name='lastName'
                  value={formik.values.lastName || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.lastName && !!formik.errors.lastName}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <TextField
                  fullWidth
                  placeholder='Email'
                  label='Email'
                  name='email'
                  value={formik.values.email || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && !!formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <TextField
                  fullWidth
                  placeholder='Phone Number'
                  label='Phone Number'
                  name='phoneNumber'
                  value={formik.values.phoneNumber || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name='isActive' />}
                    name='isActive'
                    label='Active?'
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormGroup>
              </Grid>
              {/* En-147 */}
              <Grid
                item
                xs={12}
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name='emailConfirmed' />}
                    label='Auto Confirm Email?'
                    checked={formik.values.emailConfirmed}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction={"row-reverse"}
            paddingTop={theme.spacing(2)}
          >
            <Grid item>
              <LoadingButton
                variant='contained'
                loading={formik.isSubmitting}
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Save Changes
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default UserInfoTab;
