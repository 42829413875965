import React, {
  FC,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { BreadcrumbsEnum } from "../../Components/Base/BreadcrumbsBar";
import SearchTextField
  from "../../Components/Common/SearchTextField/SearchTextField";
import PaperCard from "../../Components/Common/Styled/PaperCard";
import UsersGrid from "../../Components/User/UsersGrid";
import {
  Menu,
  setActiveBreadCumb,
  setActiveMenu,
} from "../../Redux/Slice/Application/ApplicationSlice";
import {
  fetchPagedUser,
  setQuickSearch,
} from "../../Redux/Slice/Users/UserGridSlice";
import { useAppDispatch } from "../../Redux/Store";


const ViewUsers: FC = function () {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setActiveBreadCumb(BreadcrumbsEnum.Users));
    dispatch(setActiveMenu(Menu.Users));
  }, []);

  return (
    <>
      <PaperCard>
        <Stack
          pb={1}
          direction="row"
          spacing={1}
        >
          <Typography
            variant='h5'
            style={{
              flex: 1
            }}>
            Users
          </Typography>
          <SearchTextField
            label="Search"
            placeholder="Search"
            size="small"
            onSearch={(text) => {
              dispatch(setQuickSearch(text));
              dispatch(fetchPagedUser());
            }}
          />
          <Button
            variant='contained'
            onClick={() => {
              navigate("/admin/users/create");
            }}
          >
            Create
          </Button>
          <Button
            variant='contained'
            color="secondary"
            onClick={() => {
              dispatch(fetchPagedUser());
            }}
          >
            Reload
          </Button>
        </Stack>
        <UsersGrid />
      </PaperCard>
    </>
  );
};

export default ViewUsers;
