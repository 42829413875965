import React, { FC, useEffect } from "react";
import { Grid } from "@mui/material";
import { BreadcrumbsEnum } from "../../Components/Base/BreadcrumbsBar";
import PaperCard from "../../Components/Common/Styled/PaperCard";
import EditUserBase from "../../Components/User/EditUser/EditUserBase";
import {
  Menu,
  setActiveBreadCumb,
  setActiveMenu,
} from "../../Redux/Slice/Application/ApplicationSlice";
import { useAppDispatch } from "../../Redux/Store";
import { useParams } from "react-router";

const EditUser: FC = function () {
  const dispatch = useAppDispatch();

  const { tenantId } = useParams();

  useEffect(() => {
    if (tenantId && Number(tenantId) > 0) {
      dispatch(setActiveMenu(Menu.Tenants));
      dispatch(setActiveBreadCumb(BreadcrumbsEnum.EditTenantUser));
    } else {
      dispatch(setActiveMenu(Menu.Users));
      dispatch(setActiveBreadCumb(BreadcrumbsEnum.EditUser));
    }
  }, []);
  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Grid
        item
        lg={6}
        md={12}
        xs={12}
        sm={12}
      >
        <PaperCard
          sx={{
            minHeight: "200px",
          }}
        >
          <EditUserBase />
        </PaperCard>
      </Grid>
    </Grid>
  );
};

export default EditUser;
