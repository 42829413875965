import { AddEditServerRequest } from "../../Models/ApiRequest/Admin/Server/AddEditServerRequest";
import { IResult } from "../../Models/ApiResponse/Common/IResult";
import { PaginatedResult } from "../../Models/ApiResponse/Common/PaginatedResult";
import { BasicServerInfo } from "../../Models/ApiResponse/Server/BasicServerInfo";
import { ServerInfo } from "../../Models/ApiResponse/Server/ServerInfo";
import { httpClient } from "../APIBase";

const GetServersPageRequest = function (currentPage: number, pageSize: number, search: string) {
  return httpClient.get<PaginatedResult<ServerInfo>>("/admin/servers", {
    params: {
      currentPage,
      pageSize,
      search,
    },
  });
};

const GetAllServersInfo = function () {
  return httpClient.get<IResult<Array<BasicServerInfo>>>("/admin/servers/all");
};

const GetServerInfo = function (serverId: number) {
  return httpClient.get<IResult<ServerInfo>>(`/admin/servers/${serverId}`);
};

const AddEditServer = function (serverRequest: AddEditServerRequest) {
  return httpClient.post<IResult<number>>("/admin/servers", serverRequest);
};

export { AddEditServer, GetAllServersInfo, GetServerInfo, GetServersPageRequest };
