import React, { FC } from "react";

import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";

import ChangePasswordComp from "../CommonComponents/ChangePasswordComp";

export type ChangePasswordModalProps = {
  nameToDisplay: string;
  userId: string;
  onCancel(): void;
  onSave(): void;
};

const ChangePasswordModal: FC<ChangePasswordModalProps> = function ({
  userId,
  nameToDisplay,
  onCancel,
  onSave,
}) {
  return (
    <Dialog open={true}>
      <DialogTitle>Change Password - {nameToDisplay}</DialogTitle>
      <DialogContent>
        <Box py={2}>
          <ChangePasswordComp
            userId={userId}
            showCancel={true}
            onCancel={onCancel}
            onSave={onSave}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordModal;
