import axios from "axios";
import { ChangeNewUserPasswordRequest } from "../../Models/ApiRequest/Admin/User/ChangeNewUserPasswordRequest";
import { CreateUserRequest } from "../../Models/ApiRequest/Admin/User/CreateUserRequest";
import { ToggleUserStatusRequest } from "../../Models/ApiRequest/Admin/User/ToggleUserStatusRequest";
import {
  UpdateTenantClientRequest,
  UpdateUserClientRequest,
} from "../../Models/ApiRequest/Admin/User/UpdateTenantClientRequest";
import { UpdateUserBasicInfoRequest } from "../../Models/ApiRequest/Admin/User/UpdateUserBasicInfoRequest";
import { UpdateUserRolesRequest } from "../../Models/ApiRequest/Admin/User/UpdateUserRolesRequest";
import { IResult } from "../../Models/ApiResponse/Common/IResult";
import { PaginatedResult } from "../../Models/ApiResponse/Common/PaginatedResult";
import { UserAllRolesResponse } from "../../Models/ApiResponse/Role/RoleNamesResponse";
import { BasicTenantClientResponse } from "../../Models/ApiResponse/Tenant/BasicTenantClientResponse";
import { ClientResponse } from "../../Models/ApiResponse/Tenant/ClientResponse";
import { TenantResponse } from "../../Models/ApiResponse/Tenant/TenantReponse";
import {
  MasterUserInfoResponse,
  UserInfoResponse,
} from "../../Models/ApiResponse/User/UserInfoResponse";
import { GetAutheticatedUserId } from "../../Util/AuthHelper";
import { httpClient } from "../APIBase";

const userBasePath = "/admin/user";

const GetUsersPagination = function (pageNumber: number, pageSize: number, search: string) {
  return httpClient.get<PaginatedResult<UserInfoResponse>>(`${userBasePath}/page`, {
    params: {
      pageNumber,
      pageSize,
      search,
    },
  });
};

const CheckUsernameAvailable = function (userName: string) {
  return httpClient.get<boolean>(`${userBasePath}/check/username`, {
    params: {
      userName,
    },
  });
};

const CheckUserIsSuperAdmin = function (userId: string) {
  return httpClient.get<boolean>(`${userBasePath}/${userId}/is-super-admin`);
};

const CheckEmailAvailable = function (email: string) {
  return httpClient.get<boolean>(`${userBasePath}/check/email`, {
    params: {
      email,
    },
  });
};

const CheckUsernameAndEmailAvailable = function (userName: string, email: string) {
  return axios.all([CheckUsernameAvailable(userName), CheckEmailAvailable(email)]);
};

const CreateUser = function (request: CreateUserRequest) {
  return httpClient.post<IResult<string>>(`${userBasePath}/create`, request);
};

const ToggleUserStatus = function (request: ToggleUserStatusRequest) {
  return httpClient.post<IResult<string>>(`${userBasePath}/toggle-status`, request);
};

const GetUserRoles = function (userId: string) {
  return httpClient.get<IResult<UserAllRolesResponse>>(`${userBasePath}/${userId}/roles`);
};

const GetUserAssignedTenants = function (userId: string) {
  return httpClient.get<IResult<Array<TenantResponse>>>(`${userBasePath}/${userId}/tenants`);
};

const GetUserAssignedClients = function (tenantIdentifier: string) {
  return httpClient.get<IResult<Array<ClientResponse>>>(
    `${userBasePath}/${tenantIdentifier}/clients`,
  );
};

const GetAssignedUserTenantClients = function (userId: string) {
  return httpClient.get<IResult<Array<BasicTenantClientResponse>>>(
    `${userBasePath}/${userId}/tenant-clients`,
  );
};

const UpdateUserTenantClients = function (userId: string, request: UpdateTenantClientRequest) {
  return httpClient.put<IResult<string>>(`${userBasePath}/${userId}/tenant-clients`, request);
};

const UpdateUserClients = function (userId: string, request: UpdateUserClientRequest) {
  return httpClient.put<IResult<string>>(`${userBasePath}/${userId}/clients`, request);
};

const UpdateRoles = function (userId: string, request: UpdateUserRolesRequest) {
  return httpClient.put<IResult<string>>(`${userBasePath}/${userId}/roles`, request);
};

const GetMasterUserRecord = function (userId: string) {
  return httpClient.get<IResult<MasterUserInfoResponse>>(`${userBasePath}/${userId}/master`);
};

const UpdateBasicUserInfo = function (request: UpdateUserBasicInfoRequest) {
  return httpClient.put<IResult<MasterUserInfoResponse>>(`${userBasePath}/update`, request);
};
const UpdatePassword = function (userId: string, password: string) {
  return httpClient.put<IResult<string>>(`${userBasePath}/${userId}/update-password`, {
    Password: password,
  });
};

const ResetPassword = function (request: ChangeNewUserPasswordRequest) {
  return httpClient.post<IResult<string>>(`${userBasePath}/user-password`, request);
};

const GetAuthUserInfo = function () {
  return httpClient.get<IResult<UserInfoResponse>>(`${userBasePath}/${GetAutheticatedUserId()}`);
};

const ConfirmUserEmail = function (userId: string | undefined, code: string | null) {
  return httpClient.get<IResult<UserInfoResponse>>(
    `${userBasePath}/confirm-email?userId=${userId}&code=${code}`,
  );
};

const UpdatePin = function (userId: string, pin: string) {
  return httpClient.put<IResult<string>>(`${userBasePath}/${userId}/update-pin`, {
    Pin: pin,
  });
};

const forgotPassword = function (email: string) {
  return httpClient.post(`${userBasePath}/forgot-password`, { Email: email });
};

const ResendConfirmationEmail = function ({ userId }: { userId: string }) {
  return httpClient.post<IResult<string>>(`${userBasePath}/${userId}/resend-confirmationemail`, {
    userId,
  });
};

const GetTenantClientEmployees = function (userId: string) {
  return httpClient.get<IResult<{ [key: number]: number[] }>>(
    `${userBasePath}/${userId}/tenant-clients-employee`,
  );
};

const UpdateTenantClientEmployees = function (
  userId: string,
  request: { [key: number]: number[] },
) {
  return httpClient.put<IResult<string>>(`${userBasePath}/${userId}/tenant-clients-employee`, {
    ...request,
  });
};

export {
  CheckEmailAvailable,
  CheckUsernameAndEmailAvailable,
  CheckUsernameAvailable,
  ConfirmUserEmail,
  CreateUser,
  forgotPassword,
  GetAssignedUserTenantClients,
  GetAuthUserInfo,
  GetMasterUserRecord,
  GetUserAssignedClients,
  GetUserAssignedTenants,
  GetUserRoles,
  GetUsersPagination,
  ResendConfirmationEmail,
  ResetPassword,
  ToggleUserStatus,
  UpdateBasicUserInfo,
  UpdatePassword,
  UpdatePin,
  UpdateRoles,
  UpdateUserTenantClients,
  UpdateUserClients,
  CheckUserIsSuperAdmin,
  GetTenantClientEmployees,
  UpdateTenantClientEmployees,
};
