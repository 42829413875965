import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetClientsPagination } from "../../../Api/Client/ClientEndpoints";
import { ClientGridResponse } from "../../../Models/ApiResponse/Client/ClientGridResponse";
import { PaginatedResult } from "../../../Models/ApiResponse/Common/PaginatedResult";
import { ClientsGridState } from "../../State/Client/ClientsGridState";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { RootState } from "../../Store";

const fetchClients = createAsyncThunk<
  PaginatedResult<ClientGridResponse>,
  { tenantId: number },
  { state: RootState }
>("clients/fetchClients", async (_, thunk) => {
  const { getState } = thunk;
  const state = getState().ClientGridSlice;
  const res = await GetClientsPagination(
    state.currentPage,
    state.pageSize,
    _.tenantId,
    state.quickSearch,
  );
  return res.data as PaginatedResult<ClientGridResponse>;
});

const getInitialState = () => {
  return {
    isAddModalOpen: false,
    currentPage: 1,
    pageSize: 10,
    rows: [],
    status: ApiCallStatus.NoStarted,
    totalCount: 0,
    totalPages: 0,
    error: "",
    clientId: 0,
    tenantId: 0,
    failedConfigurationModalOpen: false,
    quickSearch: "",
  } as ClientsGridState;
};

const clientGridSlice = createSlice({
  name: "clientsGrid",
  initialState: getInitialState(),
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload + 1;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    openAddModal: (state) => {
      state.isAddModalOpen = true;
    },
    closeAddModal: (state) => {
      state.isAddModalOpen = false;
    },
    showFailedConfigurationModalClient: (
      state,
      action: PayloadAction<{
        tenantId: number;
        clientId: number;
      }>,
    ) => {
      state.failedConfigurationModalOpen = true;
      state.tenantId = action.payload.tenantId;
      state.clientId = action.payload.clientId;
    },
    hideFailedConfigurationModalClient: (state) => {
      state.failedConfigurationModalOpen = false;
      state.tenantId = 0;
      state.clientId = 0;
    },
    setQuickSearch: (state, action: PayloadAction<string>) => {
      state.quickSearch = action.payload;
    },
    reset: () => {
      return { ...getInitialState() };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.status = ApiCallStatus.Loading;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        if (action.payload.succeeded) {
          state.rows = action.payload.data;
          state.currentPage = action.payload.currentPage;
          state.totalCount = action.payload.totalCount;
          state.totalPages = action.payload.totalPages;
          state.status = ApiCallStatus.Success;
        } else {
          state.status = ApiCallStatus.Error;
        }
      })
      .addCase(fetchClients.rejected, (state) => {
        state.rows = [];
        state.status = ApiCallStatus.Error;
        state.error = "Something went wrong";
      });
  },
});

export { fetchClients };
export const clientsGridSelector = (state: RootState) => state.ClientGridSlice;
export const {
  setPage,
  setPageSize,
  closeAddModal,
  openAddModal,
  reset,
  showFailedConfigurationModalClient,
  hideFailedConfigurationModalClient,
  setQuickSearch,
} = clientGridSlice.actions;
export default clientGridSlice.reducer;
