import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Grid } from "@mui/material";
import { BreadcrumbsEnum } from "../../Components/Base/BreadcrumbsBar";
import EditClientBase from "../../Components/Clients/EditClient/EditClientBase";
import PaperCard from "../../Components/Common/Styled/PaperCard";
import {
  editClientSelector,
} from "../../Redux/Selectors/Client/EditClientSelectors";
import {
  Menu,
  setActiveBreadCumb,
  setActiveBreadCumbChipLinkParams,
  setActiveBreadCumbHelpingText,
  setActiveMenu,
  setBreadCumbDisplayText,
} from "../../Redux/Slice/Application/ApplicationSlice";
import {
  clear,
  setTenantAndClientId,
} from "../../Redux/Slice/Clients/EditClientSlice";
import { useAppDispatch } from "../../Redux/Store";


const EditClient = function () {
  const dispatch = useAppDispatch();

  const { clientId, tenantId } = useParams();
  const { data } = useSelector(editClientSelector);

  useEffect(() => {
    dispatch(setActiveMenu(Menu.Clients));
    dispatch(setActiveBreadCumb(BreadcrumbsEnum.EditClient));

    dispatch(
      setTenantAndClientId({
        clientId: Number(clientId),
        tenantId: Number(tenantId),
      }),
    );

    return () => {
      dispatch(clear());
    };
  }, []);

  useEffect(() => {
    dispatch(setBreadCumbDisplayText([
      { path: BreadcrumbsEnum.TenantInstance, replaceText: data?.tenantName || "" }
    ]));
    dispatch(setActiveBreadCumbHelpingText(`Id: ${clientId}`));
    dispatch(setActiveBreadCumbChipLinkParams([
      {
        path: BreadcrumbsEnum.Clients,
        params: {
          tenantId: tenantId || ""
        }
      }
    ]));

  }, [data?.tenantName]);

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Grid
        item
        lg={6}
        md={12}
        xs={12}
        sm={12}
      >
        <PaperCard
          sx={{
            minHeight: "200px",
          }}
        >
          <EditClientBase />
        </PaperCard>
      </Grid>
    </Grid>
  );
};

export default EditClient;
