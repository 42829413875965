import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetClientApiKeys } from "../../../Api/Client/ClientEndpoints";
import { ClientApiKeyGridResponse } from "../../../Models/ApiResponse/Client/ClientApiKeyGridResponse";
import { PaginatedResult } from "../../../Models/ApiResponse/Common/PaginatedResult";
import { RootSelector } from "../../Selectors/RootSelector";
import { ClientApiKeysGridSlice } from "../../State/Client/ClientsGridState";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { RootState } from "../../Store";
import { showErrorSnackbar } from "../Application/ApplicationSlice";

export const fetchClientApiKeys = createAsyncThunk<
  PaginatedResult<ClientApiKeyGridResponse>,
  void,
  { state: RootState }
>("editClient/fetchClientApiKeys", async (_, thunk) => {
  const { getState, rejectWithValue, dispatch } = thunk;
  const editClientState = getState().EditClientSlice;
  const apiGridState = getState().clientApiKeysGridSlice;
  try {
    const res = await GetClientApiKeys(
      editClientState.tenantId,
      editClientState.clientId,
      apiGridState.currentPage,
      apiGridState.pageSize,
      "",
    );
    return res.data as PaginatedResult<ClientApiKeyGridResponse>;
  } catch (error) {
    dispatch(showErrorSnackbar("Failed to client api keys"));
    return rejectWithValue("Something went wrong.");
  }
});

const getInitialState = () => {
  return {
    currentPage: 1,
    pageSize: 5,
    rows: [],
    totalCount: 0,
    totalPages: 0,
    status: ApiCallStatus.NoStarted,
    error: "",
  } as ClientApiKeysGridSlice;
};

const clientApiKeysGridSlice = createSlice({
  name: "clientApiKeysGridSlice",
  initialState: getInitialState(),
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload + 1;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    clear: () => {
      return { ...getInitialState() };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchClientApiKeys.pending, (state) => {
        state.status = ApiCallStatus.Loading;
      })
      .addCase(fetchClientApiKeys.fulfilled, (state, action) => {
        if (action.payload.succeeded) {
          state.rows = action.payload.data;
          state.currentPage = action.payload.currentPage;
          state.totalCount = action.payload.totalCount;
          state.totalPages = action.payload.totalPages;
          state.status = ApiCallStatus.Success;
        } else {
          state.status = ApiCallStatus.Error;
        }
      })
      .addCase(fetchClientApiKeys.rejected, (state) => {
        state.rows = [];
        state.status = ApiCallStatus.Error;
        state.error = "Something went wrong";
      });
  },
});

export const clientApiKeysGridSelector = createSelector(
  RootSelector,
  (state) => state.clientApiKeysGridSlice,
);
export const { setPage, setPageSize, clear } = clientApiKeysGridSlice.actions;
export default clientApiKeysGridSlice.reducer;
