export enum SpecialtyEnum {
  GeneralPractice = 1,
  GeneralSurgery = 2,
  AllergyImmunology = 3,
  Otolaryngology = 4,
  Anesthesiology = 5,
  Cardiology = 6,
  Dermatology = 7,
  FamilyPractice = 8,
  InterventionalPainManagement = 9,
  Gastroenterology = 10,
  InternalMedicine = 11,
  OsteopathicManipulativeTherapy = 12,
  Neurology = 13,
  Neurosurgery = 14,
  ObstetricsGynecology = 16,
  Ophthalmology = 18,
  OralSurgery = 19,
  OrthopedicSurgery = 20,
  Pathology = 22,
  PlasticSurgery = 24,
  PhysicalMedicineRehabilitation = 25,
  Psychiatry = 26,
  ColorectalSurgery = 28,
  PulmonaryDisease = 29,
  DiagnosticRadiology = 30,
  AnesthesiologistAssistant = 32,
  ThoracicSurgery = 33,
  Urology = 34,
  Chiropractic = 35,
  NuclearMedicine = 36,
  PediatricMedicine = 37,
  GeriatricMedicine = 38,
  Nephrology = 39,
  HandSurgery = 40,
  Optometry = 41,
  CertifiedNurseMidwife = 42,
  CertifiedRegisteredNurseAssistant = 43,
  InfectiousDisease = 44,
  MammographyScreeningCenter = 45,
  Endocrinology = 46,
  IndependentDiagnosticTestingFacility = 47,
  Podiatry = 48,
  AmbulatorySurgicalCenter = 49,
  NursePractitioner = 50,
  MedicalSupplyCompanyWithOrthotist = 51,
  MedicalSupplyCompanyWithProsthetist = 52,
  MedicalSupplyCompanyWithOrthotistProsthetist = 53,
  OtherMedicalSupplyCompany = 54,
  IndividualCertifiedOrthotist = 55,
  IndividualCertifiedProsthetist = 56,
  IndividualCertifiedProsthetistOrthotist = 57,
  MedicalSupplyCompanyWithPharmacist = 58,
  AmbulanceServiceProvider = 59,
  PublicHealthOrWelfareAgency = 60,
  VoluntaryHealthOrCharitableAgency = 61,
  Psychologist = 62,
  PortableXRaySupplier = 63,
  Audiologist = 64,
  PhysicalTherapist = 65,
  Rheumatology = 66,
  OccupationalTherapist = 67,
  ClinicalPsychologist = 68,
  ClinicalLaboratory = 69,
  MultispecialtyClinicOrGroupPractice = 70,
  RegisteredDietitianNutritionProfessional = 71,
  PainManagement = 72,
  MassImmunizationRosterBillers = 73,
  RadiationTherapyCenter = 74,
  SlidePreparationFacilities = 75,
  PeripheralVascularDisease = 76,
  VascularSurgery = 77,
  CardiacSurgery = 78,
  AddictionMedicine = 79,
  LicensedClinicalSocialWorker = 80,
  CriticalCare = 81,
  Hematology = 82,
  HematologyOncology = 83,
  PreventativeMedicine = 84,
  MaxillofacialSurgery = 85,
  NeuroPsychiatry = 86,
  AllOtherSuppliers = 87,
  UnknownSupplierProviderSpecialty = 88,
  CertifiedClinicalNurseSpecialist = 89,
  MedicalOncology = 90,
  SurgicalOncology = 91,
  RadiationOncology = 92,
  EmergencyMedicine = 93,
  InterventionalRadiology = 94,
  Optician = 96,
  PhysicianAssistant = 97,
  GynecologicalOncology = 98,
  UnknownPhysicianSpecialty = 99,
  Hospital = 100,
  SkilledNursingFacility = 101,
  IntermediateCareNursingFacility = 102,
  OtherNursingFacility = 103,
  HomeHealthAgency = 104,
  Pharmacy = 105,
  MedicalSupplyCompanyWithRespiratoryTherapist = 106,
  DepartmentStore = 107,
  GroceryStore = 108,
}
