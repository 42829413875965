import React, {
  FC,
  useEffect,
} from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { SaveClientKpi } from "../../../Api/Client/ClientEndpoints";
import {
  ClientKpiDto,
} from "../../../Models/ApiResponse/Client/ClientByIdResponse";
import {
  editClientSelector,
} from "../../../Redux/Selectors/Client/EditClientSelectors";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../Redux/Slice/Application/ApplicationSlice";
import { fetchClientById } from "../../../Redux/Slice/Clients/EditClientSlice";
import { ApiCallStatus } from "../../../Redux/State/Common/GenericApiState";
import { useAppDispatch } from "../../../Redux/Store";
import { EditClientInfoTabProps } from "./CommonProps";


enum ActiveAccordin {
  Credentialing = 1,
  FrontDesk = 2,
  Medical = 3,
  Billing = 4,
}

type AccordianProps = {
  isExpanded: boolean;
  onExpandChange(activeAccordin: ActiveAccordin): void;
};

const CredentialingFormAccordian: FC<AccordianProps> = function ({ isExpanded, onExpandChange }) {
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const validationSchema = Yup.object().shape({
    volumeCredentialDenials: Yup.number().typeError("Only numbers are allowed"),
    credentialDenialsCashValue: Yup.number().typeError("Only numbers are allowed"),
  });

  const { tenantId, clientId, data } = useSelector(editClientSelector);

  const formik = useFormik({
    initialValues: {
      volumeCredentialDenials: "",
      credentialDenialsCashValue: "",
    },
    validationSchema: validationSchema,
    onSubmit(values, { setSubmitting }) {
      if (data?.clientKpi) {
        const clientKpi: ClientKpiDto = {
          ...data?.clientKpi,
          volumeCredentialDenials: values.volumeCredentialDenials
            ? Number(values.volumeCredentialDenials)
            : null,
          credentialDenialsCashValue: values.credentialDenialsCashValue
            ? Number(values.credentialDenialsCashValue)
            : null,
        };

        setSubmitting(true);

        SaveClientKpi(tenantId, clientId, clientKpi)
          .then(({ data }) => {
            if (data.succeeded) {
              dispatch(showSuccessSnackbar("Credentialing updated successfully"));
              dispatch(fetchClientById());
              setSubmitting(false);
            } else {
              dispatch(showErrorSnackbar("Failed to update credentialing"));
              setSubmitting(false);
            }
          })
          .catch(() => {
            dispatch(showErrorSnackbar("Failed to update credentialing"));
            setSubmitting(false);
          });
      }
    },
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    isSubmitting,
    setValues,
  } = formik;

  useEffect(() => {
    setValues({
      volumeCredentialDenials: data?.clientKpi?.volumeCredentialDenials?.toString() || "",
      credentialDenialsCashValue: data?.clientKpi?.credentialDenialsCashValue?.toString() || "",
    });
  }, [data?.clientKpi]);

  return (
    <Accordion
      sx={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.secondary.contrastText,
      }}
      expanded={isExpanded}
      onChange={() => onExpandChange(ActiveAccordin.Credentialing)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Credentialing</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='Volume Credential Denials'
              label='Volume Credential Denials'
              name='volumeCredentialDenials'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.volumeCredentialDenials && !!errors.volumeCredentialDenials}
              helperText={touched.volumeCredentialDenials && errors.volumeCredentialDenials}
              value={values.volumeCredentialDenials}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='Credential Denials Cash Value'
              label='Credential Denials Cash Value'
              name='credentialDenialsCashValue'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.credentialDenialsCashValue && !!errors.credentialDenialsCashValue}
              helperText={touched.credentialDenialsCashValue && errors.credentialDenialsCashValue}
              value={values.credentialDenialsCashValue}
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <LoadingButton
          variant='contained'
          loading={isSubmitting}
          onClick={() => handleSubmit()}
        >
          Update
        </LoadingButton>
      </AccordionActions>
    </Accordion>
  );
};

const FrontDeskAccordian: FC<AccordianProps> = function ({ isExpanded, onExpandChange }) {
  const dispatch = useAppDispatch();

  const validationSchema = Yup.object().shape({
    claimDenialPercentage: Yup.number().typeError("Only numbers are allowed"),
    demographicDenialPercentage: Yup.number().typeError("Only numbers are allowed"),
  });

  const { tenantId, clientId, data } = useSelector(editClientSelector);

  const formik = useFormik({
    initialValues: {
      claimDenialPercentage: "",
      demographicDenialPercentage: "",
    },
    validationSchema: validationSchema,
    onSubmit(values, { setSubmitting }) {
      if (data?.clientKpi) {
        const clientKpi: ClientKpiDto = {
          ...data?.clientKpi,
          claimDenialPercentage: values.claimDenialPercentage
            ? Number(values.claimDenialPercentage)
            : null,
          demographicDenialPercentage: values.demographicDenialPercentage
            ? Number(values.demographicDenialPercentage)
            : null,
        };

        setSubmitting(true);

        SaveClientKpi(tenantId, clientId, clientKpi)
          .then(({ data }) => {
            if (data.succeeded) {
              dispatch(showSuccessSnackbar("Front Desk updated successfully"));
              dispatch(fetchClientById());
              setSubmitting(false);
            } else {
              dispatch(showErrorSnackbar("Failed to update Front Desk"));
              setSubmitting(false);
            }
          })
          .catch(() => {
            dispatch(showErrorSnackbar("Failed to update Front Desk"));
            setSubmitting(false);
          });
      }
    },
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    isSubmitting,
    setValues,
  } = formik;

  useEffect(() => {
    setValues({
      claimDenialPercentage: data?.clientKpi?.claimDenialPercentage?.toString() || "0",
      demographicDenialPercentage: data?.clientKpi?.demographicDenialPercentage?.toString() || "0",
    });
  }, [data?.clientKpi]);

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => onExpandChange(ActiveAccordin.FrontDesk)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Front Desk</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='Claim Denial Percentage'
              label='Claim Denial Percentage'
              name='claimDenialPercentage'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.claimDenialPercentage && !!errors.claimDenialPercentage}
              helperText={touched.claimDenialPercentage && errors.claimDenialPercentage}
              value={parseFloat(values.claimDenialPercentage).toFixed(2)}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='Demographic Denial Percentage'
              label='Demographic Denial Percentage'
              name='demographicDenialPercentage'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.demographicDenialPercentage && !!errors.demographicDenialPercentage}
              helperText={touched.demographicDenialPercentage && errors.demographicDenialPercentage}
              value={parseFloat(values.demographicDenialPercentage)}
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <LoadingButton
          variant='contained'
          loading={isSubmitting}
          onClick={() => handleSubmit()}
        >
          Update
        </LoadingButton>
      </AccordionActions>
    </Accordion>
  );
};

const MedicalAccordian: FC<AccordianProps> = function ({ isExpanded, onExpandChange }) {
  const dispatch = useAppDispatch();

  const validationSchema = Yup.object().shape({
    codingDenialPercentage: Yup.number().typeError("Only numbers are allowed"),
    averageSubmitDays: Yup.number().typeError("Only numbers are allowed"),
  });

  const { tenantId, clientId, data } = useSelector(editClientSelector);

  const formik = useFormik({
    initialValues: {
      codingDenialPercentage: "",
      averageSubmitDays: "",
    },
    validationSchema: validationSchema,
    onSubmit(values, { setSubmitting }) {
      if (data?.clientKpi) {
        const clientKpi: ClientKpiDto = {
          ...data?.clientKpi,
          codingDenialPercentage: values.codingDenialPercentage
            ? Number(values.codingDenialPercentage)
            : null,
          averageSubmitDays: values.averageSubmitDays ? Number(values.averageSubmitDays) : null,
        };

        setSubmitting(true);

        SaveClientKpi(tenantId, clientId, clientKpi)
          .then(({ data }) => {
            if (data.succeeded) {
              dispatch(showSuccessSnackbar("Billing updated successfully"));
              dispatch(fetchClientById());
              setSubmitting(false);
            } else {
              dispatch(showErrorSnackbar("Failed to update Billing"));
              setSubmitting(false);
            }
          })
          .catch(() => {
            dispatch(showErrorSnackbar("Failed to update Billing"));
            setSubmitting(false);
          });
      }
    },
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    isSubmitting,
    setValues,
  } = formik;

  useEffect(() => {
    setValues({
      codingDenialPercentage: data?.clientKpi?.codingDenialPercentage?.toString() || "",
      averageSubmitDays: data?.clientKpi?.averageSubmitDays?.toString() || "",
    });
  }, [data?.clientKpi]);

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => onExpandChange(ActiveAccordin.Medical)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Medical</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='Coding Denial Percentage'
              label='Coding Denial Percentage'
              name='codingDenialPercentage'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.codingDenialPercentage && !!errors.codingDenialPercentage}
              helperText={touched.codingDenialPercentage && errors.codingDenialPercentage}
              value={
                values.codingDenialPercentage &&
                parseFloat(values.codingDenialPercentage).toFixed(2)
              }
              disabled={isSubmitting}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='Average Submit Days'
              label='Average Submit Days'
              name='averageSubmitDays'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.averageSubmitDays && !!errors.averageSubmitDays}
              helperText={touched.averageSubmitDays && errors.averageSubmitDays}
              value={values.averageSubmitDays && parseFloat(values.averageSubmitDays)}
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <LoadingButton
          variant='contained'
          loading={isSubmitting}
          onClick={() => handleSubmit()}
        >
          Update
        </LoadingButton>
      </AccordionActions>
    </Accordion>
  );
};

const BillingAccordian: FC<AccordianProps> = function ({ isExpanded, onExpandChange }) {
  const dispatch = useAppDispatch();

  const validationSchema = Yup.object().shape({
    monthlyCashCollection: Yup.number().typeError("Only numbers are allowed"),
    dailyClaimCount: Yup.number().typeError("Only numbers are allowed"),
    averageDaysInReceivables: Yup.number().typeError("Only numbers are allowed"),
    aR90DaysInsurancePercentage: Yup.number().typeError("Only numbers are allowed"),
    aR90DaysSelfPayPercentage: Yup.number().typeError("Only numbers are allowed"),
    aR180DaysInsurancePercentage: Yup.number().typeError("Only numbers are allowed"),
    aR180DaysSelfPayPercentage: Yup.number().typeError("Only numbers are allowed"),
    cleanClaimRate: Yup.number().typeError("Only numbers are allowed"),
  });

  const { tenantId, clientId, data } = useSelector(editClientSelector);

  const formik = useFormik({
    initialValues: {
      monthlyCashCollection: "",
      dailyClaimCount: "",
      averageDaysInReceivables: "",
      aR90DaysInsurancePercentage: "",
      aR90DaysSelfPayPercentage: "",
      aR180DaysInsurancePercentage: "",
      aR180DaysSelfPayPercentage: "",
      cleanClaimRate: "",
    },
    validationSchema: validationSchema,
    onSubmit(values, { setSubmitting }) {
      if (data?.clientKpi) {
        const clientKpi: ClientKpiDto = {
          ...data?.clientKpi,
          monthlyCashCollection: Number(values.monthlyCashCollection),
          dailyClaimCount: Number(values.dailyClaimCount),
          averageDaysInReceivables: values.averageDaysInReceivables
            ? Number(values.averageDaysInReceivables)
            : null,
          aR90DaysInsurancePercentage: values.aR90DaysInsurancePercentage
            ? Number(values.aR90DaysInsurancePercentage)
            : null,
          aR90DaysSelfPayPercentage: values.aR90DaysSelfPayPercentage
            ? Number(values.aR90DaysSelfPayPercentage)
            : null,
          aR180DaysInsurancePercentage: values.aR180DaysInsurancePercentage
            ? Number(values.aR180DaysInsurancePercentage)
            : null,
          aR180DaysSelfPayPercentage: values.aR180DaysSelfPayPercentage
            ? Number(values.aR180DaysSelfPayPercentage)
            : null,
          cleanClaimRate: values.cleanClaimRate ? Number(values.cleanClaimRate) : null,
        };

        setSubmitting(true);

        SaveClientKpi(tenantId, clientId, clientKpi)
          .then(({ data }) => {
            if (data.succeeded) {
              dispatch(showSuccessSnackbar("Billing updated successfully"));
              dispatch(fetchClientById());
              setSubmitting(false);
            } else {
              dispatch(showErrorSnackbar("Failed to update Billing"));
              setSubmitting(false);
            }
          })
          .catch(() => {
            dispatch(showErrorSnackbar("Failed to update Billing"));
            setSubmitting(false);
          });
      }
    },
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    isSubmitting,
    setValues,
  } = formik;

  useEffect(() => {
    setValues({
      monthlyCashCollection: data?.clientKpi?.monthlyCashCollection?.toString() || "0",
      dailyClaimCount: data?.clientKpi?.dailyClaimCount?.toString() || "",
      averageDaysInReceivables: data?.clientKpi?.averageDaysInReceivables?.toString() || "",
      aR90DaysInsurancePercentage: data?.clientKpi?.aR90DaysInsurancePercentage?.toString() || "",
      aR90DaysSelfPayPercentage: data?.clientKpi?.aR90DaysSelfPayPercentage?.toString() || "",
      aR180DaysInsurancePercentage: data?.clientKpi?.aR180DaysInsurancePercentage?.toString() || "",
      aR180DaysSelfPayPercentage: data?.clientKpi?.aR180DaysSelfPayPercentage?.toString() || "",
      cleanClaimRate: data?.clientKpi?.cleanClaimRate?.toString() || "",
    });
  }, [data?.clientKpi]);

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => onExpandChange(ActiveAccordin.Billing)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Billing</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='Monthly Cash Collection'
              label='Monthly Cash Collection'
              name='monthlyCashCollection'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.monthlyCashCollection && !!errors.monthlyCashCollection}
              helperText={touched.monthlyCashCollection && errors.monthlyCashCollection}
              value={parseFloat(values.monthlyCashCollection).toFixed(2)}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='Daily Claim Count'
              label='Daily Claim Count'
              name='dailyClaimCount'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.dailyClaimCount && !!errors.dailyClaimCount}
              helperText={touched.dailyClaimCount && errors.dailyClaimCount}
              value={values.dailyClaimCount}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='Average Days In Receivables'
              label='Average Days In Receivables'
              name='averageDaysInReceivables'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.averageDaysInReceivables && !!errors.averageDaysInReceivables}
              helperText={touched.averageDaysInReceivables && errors.averageDaysInReceivables}
              value={values.averageDaysInReceivables}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='AR90 Days Insurance Percentage'
              label='AR90 Days Insurance Percentage'
              name='aR90DaysInsurancePercentage'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.aR90DaysInsurancePercentage && !!errors.aR90DaysInsurancePercentage}
              helperText={touched.aR90DaysInsurancePercentage && errors.aR90DaysInsurancePercentage}
              value={values.aR90DaysInsurancePercentage}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='AR90 Days SelfPay Percentage'
              label='AR90 Days SelfPay Percentage'
              name='aR90DaysSelfPayPercentage'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.aR90DaysSelfPayPercentage && !!errors.aR90DaysSelfPayPercentage}
              helperText={touched.aR90DaysSelfPayPercentage && errors.aR90DaysSelfPayPercentage}
              value={values.aR90DaysSelfPayPercentage}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='AR180 Days Insurance Percentage'
              label='AR180 Days Insurance Percentage'
              name='aR180DaysInsurancePercentage'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.aR180DaysInsurancePercentage && !!errors.aR180DaysInsurancePercentage}
              helperText={
                touched.aR180DaysInsurancePercentage && errors.aR180DaysInsurancePercentage
              }
              value={values.aR180DaysInsurancePercentage}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='AR180 Days SelfPay Percentage'
              label='AR180 Days SelfPay Percentage'
              name='aR180DaysSelfPayPercentage'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.aR180DaysSelfPayPercentage && !!errors.aR180DaysSelfPayPercentage}
              helperText={touched.aR180DaysSelfPayPercentage && errors.aR180DaysSelfPayPercentage}
              value={values.aR180DaysSelfPayPercentage}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              placeholder='Clean ClaimRate'
              label='Clean ClaimRate'
              name='cleanClaimRate'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.cleanClaimRate && !!errors.cleanClaimRate}
              helperText={touched.cleanClaimRate && errors.cleanClaimRate}
              value={values.cleanClaimRate}
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <LoadingButton
          variant='contained'
          loading={isSubmitting}
          onClick={() => handleSubmit()}
        >
          Update
        </LoadingButton>
      </AccordionActions>
    </Accordion>
  );
};

const DepartmentKPITab: FC<EditClientInfoTabProps> = function ({ active }) {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<ActiveAccordin | null>(null);

  const handleChange = (panel: ActiveAccordin) => {
    if (expanded == panel) {
      setExpanded(null);
    } else {
      setExpanded(panel);
    }
  };
  const { loading } = useSelector(editClientSelector);

  const getSkeleton = () => {
    return (<Box>
      <Skeleton variant="rounded" animation="wave" width="100%">
        <Accordion expanded={false}>
          <AccordionDetails></AccordionDetails>
        </Accordion>
      </Skeleton>
      <Skeleton variant="rounded" animation="wave" width="100%">
        <Accordion expanded={false}>
          <AccordionDetails></AccordionDetails>
        </Accordion>
      </Skeleton>
      <Skeleton variant="rounded" animation="wave" width="100%">
        <Accordion expanded={false}>
          <AccordionDetails></AccordionDetails>
        </Accordion>
      </Skeleton>
      <Skeleton variant="rounded" animation="wave" width="100%">
        <Accordion expanded={false}>
          <AccordionDetails></AccordionDetails>
        </Accordion>
      </Skeleton>
    </Box>);
  };

  return (
    <Box
      hidden={!active}
      padding={theme.spacing(2)}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          {
            loading == ApiCallStatus.Loading ? getSkeleton() :
              <>
                <CredentialingFormAccordian
                  isExpanded={expanded == ActiveAccordin.Credentialing}
                  onExpandChange={handleChange}
                />
                <FrontDeskAccordian
                  isExpanded={expanded == ActiveAccordin.FrontDesk}
                  onExpandChange={handleChange}
                />
                <MedicalAccordian
                  isExpanded={expanded == ActiveAccordin.Medical}
                  onExpandChange={handleChange}
                />
                <BillingAccordian
                  isExpanded={expanded == ActiveAccordin.Billing}
                  onExpandChange={handleChange}
                />
              </>
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default DepartmentKPITab;
