import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginatedResult } from "../../../Models/ApiResponse/Common/PaginatedResult";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { RootState } from "../../Store";
import { GetUserLoginHistoryGridResponse } from "../../../Models/ApiResponse/Dashboard/GetUserLoginHistoryGridResponse";
import { GetUserLoginHistoryGrid } from "../../../Api/Dashboard/DashboardEndpoints";
import { UserLoginHistoryGridState } from "../../State/Dashboard/UserLoginHistory/UserLoginHistoryState";

export const fetchUserLoginGridData = createAsyncThunk<
  PaginatedResult<GetUserLoginHistoryGridResponse>,
  void,
  { state: RootState }
>("dashboard/fetchUserLoginHistoryGridData", async (_, thunk) => {
  const { getState } = thunk;
  const state = getState().userLoginGridHistorySlice;
  const res = await GetUserLoginHistoryGrid({
    pageNumber: state.currentPage,
    pageSize: state.pageSize,
    quickSearch: state.quickSearch,
  });
  return res.data as PaginatedResult<GetUserLoginHistoryGridResponse>;
});

const getInitialState = () => {
  return {
    currentPage: 1,
    pageSize: 10,
    rows: [],
    status: ApiCallStatus.NoStarted,
    totalCount: 0,
    totalPages: 0,
    error: "",
    quickSearch: "",
  } as UserLoginHistoryGridState;
};

const userLoginGridHistorySlice = createSlice({
  name: "userLoginGridHistory",
  initialState: getInitialState(),
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload + 1;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setQuickSearch: (state, action: PayloadAction<string>) => {
      state.quickSearch = action.payload;
    },
    reset: () => {
      return { ...getInitialState() };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserLoginGridData.pending, (state) => {
        state.status = ApiCallStatus.Loading;
      })
      .addCase(fetchUserLoginGridData.fulfilled, (state, action) => {
        if (action.payload.succeeded) {
          state.rows = action.payload.data;
          state.currentPage = action.payload.currentPage;
          state.totalCount = action.payload.totalCount;
          state.totalPages = action.payload.totalPages;
          state.status = ApiCallStatus.Success;
        } else {
          state.status = ApiCallStatus.Error;
        }
      })
      .addCase(fetchUserLoginGridData.rejected, (state) => {
        state.rows = [];
        state.status = ApiCallStatus.Error;
        state.error = "Something went wrong";
      });
  },
});

export const userLoginGridHistorySelector = (state: RootState) => state.userLoginGridHistorySlice;
export const { setPage, setPageSize, reset, setQuickSearch } = userLoginGridHistorySlice.actions;
export default userLoginGridHistorySlice.reducer;
