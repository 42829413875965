import React, { useEffect } from "react";
import PaperCard from "../../Components/Common/Styled/PaperCard";
import { Button, Stack, Typography } from "@mui/material";
import SearchTextField from "../../Components/Common/SearchTextField/SearchTextField";
import { useAppDispatch } from "../../Redux/Store";
import {
  fetchTenantAssociatedUserLoginGridData,
  setQuickSearch,
} from "../../Redux/Slice/Tenants/TenantUserLoginGridHistorySlice";
import { useParams } from "react-router";
import {
  Menu,
  setActiveBreadCumb,
  setActiveMenu,
} from "../../Redux/Slice/Application/ApplicationSlice";
import { BreadcrumbsEnum } from "../../Components/Base/BreadcrumbsBar";
import TenantUserLoginHistoryGrid from "../../Components/Tenant/TenantUserLoginHistoryGrid/TenantUserLoginHistoryGrid";

const ViewTenantUserLoginHistory: React.FC = function () {
  const dispatch = useAppDispatch();
  const params = useParams();
  const tenantId = Number(params.tenantId);

  useEffect(() => {
    dispatch(setActiveMenu(Menu.Tenants));
    dispatch(setActiveBreadCumb(BreadcrumbsEnum.ViewTenantUserLoginHistory));
  }, []);

  return (
    <PaperCard>
      <Stack
        pb={1}
        direction='row'
        spacing={1}
      >
        <Typography
          variant='h5'
          style={{
            flex: 1,
          }}
        >
          Tenant Associated Users
        </Typography>
        <SearchTextField
          label='Search'
          placeholder='Search'
          onSearch={(text) => {
            dispatch(setQuickSearch(text));
            dispatch(fetchTenantAssociatedUserLoginGridData({ tenantId }));
          }}
          size='small'
        />
        <Button
          variant='contained'
          color='secondary'
          onClick={() => {
            dispatch(fetchTenantAssociatedUserLoginGridData({ tenantId }));
          }}
        >
          Reload
        </Button>
      </Stack>
      <TenantUserLoginHistoryGrid tenantId={tenantId} />
    </PaperCard>
  );
};

export default ViewTenantUserLoginHistory;
