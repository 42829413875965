import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";

const PaperCard = styled(Paper, { shouldForwardProp: (prop) => prop !== "elevation" })(
  ({ theme }) => ({
    padding: theme.spacing(2),
  }),
);

export default PaperCard;
