import React from "react";
import AitDataGrid from "../../Common/DataGrid/AitDataGrid";
import { useSelector } from "react-redux";
import { GridColDef } from "@mui/x-data-grid";
import { useAppDispatch } from "../../../Redux/Store";
import {
  fetchTenantAssociatedUserLoginGridData,
  setPage,
  setPageSize,
  tenantUserLoginGridHistorySelector,
} from "../../../Redux/Slice/Tenants/TenantUserLoginGridHistorySlice";
import { setBreadCumbDisplayText } from "../../../Redux/Slice/Application/ApplicationSlice";
import { BreadcrumbsEnum } from "../../Base/BreadcrumbsBar";

const TenantUserLoginHistoryGrid: React.FC<{ tenantId: number }> = function ({ tenantId }) {
  const state = useSelector(tenantUserLoginGridHistorySelector);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchTenantAssociatedUserLoginGridData({ tenantId }));
  }, [state.currentPage, state.pageSize]);

  React.useEffect(() => {
    dispatch(
      setBreadCumbDisplayText([
        { path: BreadcrumbsEnum.TenantInstance, replaceText: state.rows[0]?.tenantName },
      ]),
    );
  }, [state.rows]);

  const columns: Array<GridColDef> = [
    {
      field: "id",
      headerName: "Id",
      width: 10,
      sortable: false,
      flex: 0,
    },
    {
      field: "userId",
      headerName: "User Id",
      width: 80,
      flex: 1,
      sortable: false,
    },
    {
      field: "username",
      headerName: "User Name",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "loginTime",
      headerName: "Login Time",
      width: 150,
      flex: 1,
      sortable: false,
      type: "dateTime",
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
    {
      field: "logoutTime",
      headerName: "Logout Time",
      width: 150,
      flex: 1,
      sortable: false,
      type: "dateTime",
      valueGetter: ({ value }) => {
        return value && new Date(value);
      },
    },
  ];

  return (
    <div
      style={{
        height: "500px",
      }}
    >
      <AitDataGrid
        columns={columns}
        state={state}
        setPage={setPage}
        setPageSize={setPageSize}
        keyColumnName='id'
      />
    </div>
  );
};

export default TenantUserLoginHistoryGrid;
