import React, {
  FC,
  useEffect,
} from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { BreadcrumbsEnum } from "../../Components/Base/BreadcrumbsBar";
import SearchTextField
  from "../../Components/Common/SearchTextField/SearchTextField";
import PaperCard from "../../Components/Common/Styled/PaperCard";
import AddEditRoles from "../../Components/Roles/AddEditRoles";
import RolesGrid from "../../Components/Roles/RolesGrid";
import {
  Menu,
  setActiveBreadCumb,
  setActiveMenu,
} from "../../Redux/Slice/Application/ApplicationSlice";
import {
  addRole,
  fetchPaginatedRoles,
  rolesGridSelector,
  setQuickSearch,
} from "../../Redux/Slice/Roles/RolesGridSlice";
import { useAppDispatch } from "../../Redux/Store";


const ViewRoles: FC = function () {
  const dispatch = useAppDispatch();

  const state = useSelector(rolesGridSelector);

  useEffect(() => {
    dispatch(setActiveMenu(Menu.Roles));
    dispatch(setActiveBreadCumb(BreadcrumbsEnum.Roles));
  }, []);

  return (
    <>
      {state.isAddEditModalOpen && (
        <AddEditRoles
          roleId={state.openedRoleId}
          roleName={state.openedRoleName}
        />
      )}
      <PaperCard>
        <Stack
          pb={1}
          direction="row"
          spacing={1}
        >
          <Typography
            variant='h5'
            style={{
              flex: 1
            }}
          >
            Roles
          </Typography>
          <SearchTextField
            label="Search"
            placeholder="Search"
            onSearch={(text) => {
              dispatch(setQuickSearch(text));
              dispatch(fetchPaginatedRoles());
            }}
            size="small" />
          <Button
            variant='contained'
            onClick={() => {
              dispatch(addRole());
            }}
          >
            Create
          </Button>
          <Button
            variant='contained'
            color="secondary"
            onClick={() => {
              dispatch(fetchPaginatedRoles());
            }}
          >
            Reload
          </Button>
        </Stack>
        <RolesGrid />
      </PaperCard>
    </>
  );
};

export default ViewRoles;
