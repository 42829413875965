import React from "react";
import {
  GridPagination,
  useGridApiContext,
  useGridSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
} from "@mui/x-data-grid";
import { TablePaginationProps } from "@mui/material/TablePagination";
import MuiPagination from "@mui/material/Pagination";

const PaginationAction = function ({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);

  return (
    <MuiPagination
      color='primary'
      className={className}
      count={Math.ceil(rowCount / pageSize)}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
};

const CustomPagination = function (props: any) {
  return (
    <GridPagination
      ActionsComponent={PaginationAction}
      labelDisplayedRows={({ from, to, count }) => {
        return `Showing ${from} to ${to} of ${count} entries`;
      }}
      {...props}
    />
  );
};

export default CustomPagination;
