import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Link, Typography, useTheme } from "@mui/material";
import PaperCard from "../Common/Styled/PaperCard";

export enum BreadcrumbsEnum {
  AdminConsole = "AdminConsole",
  Dashboard = "Dashboard",
  Servers = "Servers",
  Tenants = "Tenants",
  TenantInstance = "TenantInstance",
  Users = "Users",
  CreateUser = "CreateUser",
  EditUser = "EditUser",
  Roles = "Roles",
  EditPermission = "EditPermission",
  Clients = "Clients",
  ClientInstance = "ClientInstance",
  EditClient = "EditClients",
  TenantDashboard = "TenantDashboard",
  ViewTenantUsers = "ViewTenantUsers",
  ViewTenantUserLoginHistory = "ViewTenantUserLoginHistory",
  EditTenantUser = "EditTenantUser",
  ViewClaimStatusBatch = "ViewClaimStatusBatch",
  ViewClaimStatusBatchHistories = "ViewClaimStatusBatchHistories",
}

interface BreadcrumbChip {
  displayText: string;
  path: Array<BreadcrumbsEnum>;
  linkTo: string;
  icon?: React.ReactElement;
  isLabelOnly: boolean;
  allowToReplaceText?: boolean;
}

const adminConsoleChip: BreadcrumbChip = {
  displayText: "Admin Console",
  path: [],
  linkTo: "/admin/dashboard",
  icon: (
    <HomeIcon
      sx={{ mr: 0.5 }}
      fontSize='inherit'
    />
  ),
  isLabelOnly: false,
};

const seversChip: BreadcrumbChip = {
  displayText: "Servers",
  path: [BreadcrumbsEnum.AdminConsole],
  linkTo: "/admin/servers",
  isLabelOnly: false,
};

const tenantsChip: BreadcrumbChip = {
  displayText: "Tenants",
  path: [BreadcrumbsEnum.AdminConsole],
  linkTo: "/admin/tenants",
  isLabelOnly: false,
};

const tenantInstanceChip: BreadcrumbChip = {
  displayText: "Tenant",
  path: [BreadcrumbsEnum.AdminConsole, BreadcrumbsEnum.TenantInstance],
  linkTo: "",
  isLabelOnly: true,
  allowToReplaceText: true,
};

const usersChip: BreadcrumbChip = {
  displayText: "Users",
  path: [BreadcrumbsEnum.AdminConsole],
  linkTo: "/admin/users",
  isLabelOnly: false,
};

const createUserChip: BreadcrumbChip = {
  displayText: "Create",
  path: [BreadcrumbsEnum.AdminConsole, BreadcrumbsEnum.Users],
  linkTo: "/admin/users",
  isLabelOnly: false,
};

const editUserChip: BreadcrumbChip = {
  displayText: "Edit",
  path: [BreadcrumbsEnum.AdminConsole, BreadcrumbsEnum.Users],
  linkTo: "/admin/users",
  isLabelOnly: false,
};

const rolesChip: BreadcrumbChip = {
  displayText: "Roles",
  path: [BreadcrumbsEnum.AdminConsole],
  linkTo: "/admin/roles",
  isLabelOnly: false,
};

const editPermissionChip: BreadcrumbChip = {
  displayText: "Edit Permission",
  path: [BreadcrumbsEnum.AdminConsole, BreadcrumbsEnum.Roles],
  linkTo: "/admin/roles/:id/editPermission",
  isLabelOnly: false,
};

const clientsChip: BreadcrumbChip = {
  displayText: "Clients",
  path: [BreadcrumbsEnum.AdminConsole, BreadcrumbsEnum.Tenants, BreadcrumbsEnum.TenantInstance],
  linkTo: "/admin/tenants/:tenantId/clients",
  isLabelOnly: false,
};

const clientInstanceChip: BreadcrumbChip = {
  displayText: "Client",
  path: [
    BreadcrumbsEnum.AdminConsole,
    BreadcrumbsEnum.Tenants,
    BreadcrumbsEnum.TenantInstance,
    BreadcrumbsEnum.Clients,
  ],
  linkTo: "",
  isLabelOnly: true,
  allowToReplaceText: true,
};

const editClientChip: BreadcrumbChip = {
  displayText: "Edit Client",
  path: [
    BreadcrumbsEnum.AdminConsole,
    BreadcrumbsEnum.Tenants,
    BreadcrumbsEnum.TenantInstance,
    BreadcrumbsEnum.Clients,
  ],
  linkTo: "/admin/clients/:clientId/editClient",
  isLabelOnly: false,
};

const tenantDashboardChip: BreadcrumbChip = {
  displayText: "Dashboard",
  path: [BreadcrumbsEnum.AdminConsole, BreadcrumbsEnum.Tenants, BreadcrumbsEnum.TenantInstance],
  linkTo: "/admin/tenants/:tenantId/dashboard",
  isLabelOnly: false,
};

const dashboardChip: BreadcrumbChip = {
  displayText: "Dashboard",
  path: [BreadcrumbsEnum.AdminConsole],
  linkTo: "/admin/dashboard",
  isLabelOnly: false,
};

const viewTenantUsersChip: BreadcrumbChip = {
  displayText: "Assigned Users",
  path: [BreadcrumbsEnum.AdminConsole, BreadcrumbsEnum.Tenants, BreadcrumbsEnum.TenantInstance],
  linkTo: "/admin/tenants/:tenantId/users",
  isLabelOnly: false,
};

const editTenantUserChip: BreadcrumbChip = {
  displayText: "Edit User",
  path: [BreadcrumbsEnum.AdminConsole, BreadcrumbsEnum.Tenants, BreadcrumbsEnum.TenantInstance],
  linkTo: "/admin/tenants/:tenantId/users/:userId/edit",
  isLabelOnly: false,
};

const ViewTenantUserLoginHistoryChip: BreadcrumbChip = {
  displayText: "Tenant Users Login History",
  path: [BreadcrumbsEnum.AdminConsole, BreadcrumbsEnum.Tenants, BreadcrumbsEnum.TenantInstance],
  linkTo: "/admin/tenants/:tenantId/users-login-history",
  isLabelOnly: false,
};

const ViewClaimStatusBatchHistories: BreadcrumbChip = {
  displayText: "Histories",
  path: [
    BreadcrumbsEnum.AdminConsole,
    BreadcrumbsEnum.Tenants,
    BreadcrumbsEnum.TenantInstance,
    BreadcrumbsEnum.ViewClaimStatusBatch,
  ],
  linkTo: "/admin/tenants/:tenantId/claimStatusBatch/:claimStatusBatchId/histories",
  isLabelOnly: false,
};

const ViewClaimStatusBatches: BreadcrumbChip = {
  displayText: "Claim Status Batch",
  path: [
    BreadcrumbsEnum.AdminConsole,
    BreadcrumbsEnum.Tenants,
    BreadcrumbsEnum.TenantInstance,
    BreadcrumbsEnum.ViewClaimStatusBatch,
  ],
  linkTo: "",
  isLabelOnly: true,
};

const chips: Record<BreadcrumbsEnum, BreadcrumbChip> = {
  [BreadcrumbsEnum.AdminConsole]: adminConsoleChip,
  [BreadcrumbsEnum.Servers]: seversChip,
  [BreadcrumbsEnum.Tenants]: tenantsChip,
  [BreadcrumbsEnum.TenantInstance]: tenantInstanceChip,
  [BreadcrumbsEnum.Users]: usersChip,
  [BreadcrumbsEnum.CreateUser]: createUserChip,
  [BreadcrumbsEnum.EditUser]: editUserChip,
  [BreadcrumbsEnum.Roles]: rolesChip,
  [BreadcrumbsEnum.EditPermission]: editPermissionChip,
  [BreadcrumbsEnum.Clients]: clientsChip,
  [BreadcrumbsEnum.ClientInstance]: clientInstanceChip,
  [BreadcrumbsEnum.EditClient]: editClientChip,
  [BreadcrumbsEnum.TenantDashboard]: tenantDashboardChip,
  [BreadcrumbsEnum.Dashboard]: dashboardChip,
  [BreadcrumbsEnum.ViewTenantUsers]: viewTenantUsersChip,
  [BreadcrumbsEnum.ViewTenantUserLoginHistory]: ViewTenantUserLoginHistoryChip,
  [BreadcrumbsEnum.EditTenantUser]: editTenantUserChip,
  [BreadcrumbsEnum.ViewClaimStatusBatchHistories]: ViewClaimStatusBatchHistories,
  [BreadcrumbsEnum.ViewClaimStatusBatch]: ViewClaimStatusBatches,
};

export interface BreadcrumbsBarProps {
  activeBreadcrumb: BreadcrumbsEnum;
  activeBreadCumbChipLinkParams: Array<{ path: BreadcrumbsEnum; params: Record<string, string> }>;
  activeBreadcrumbHelpingText: string;
  activeBreadCumbPathHelpingTexts: Array<{ path: BreadcrumbsEnum; helpingText: string }>;
  subPathReplaceText: Array<{ path: BreadcrumbsEnum; replaceText: string }>;
}

const BreadcrumbsBar: FC<BreadcrumbsBarProps> = function ({
  activeBreadcrumb,
  activeBreadCumbChipLinkParams,
  activeBreadcrumbHelpingText,
  activeBreadCumbPathHelpingTexts,
  subPathReplaceText,
}) {
  const theme = useTheme();
  const navigate = useNavigate();

  const replaceLinkParams = (link: string, params: Record<string, string>) => {
    if (params) {
      let newLink = "";
      Object.keys(params).forEach((key) => {
        newLink = link.replaceAll(`:${key}`, params[key]);
      });
      return newLink;
    }
    return link;
  };

  return (
    <PaperCard
      sx={{
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
      }}
      elevation={7}
    >
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize='small' />}
        aria-label='breadcrumb'
      >
        {chips[activeBreadcrumb] &&
          chips[activeBreadcrumb].path.map((path, index) => {
            const pathHelpingText = activeBreadCumbPathHelpingTexts.find((x) => x.path == path);
            const subPathReplace = subPathReplaceText.find((x) => x.path == path);
            const linkParams = activeBreadCumbChipLinkParams.find((x) => x.path == path);
            const pathChip = chips[path];
            return (
              pathChip &&
              (pathChip.isLabelOnly ? (
                <>
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color='text.secondary'
                  >
                    {pathChip.icon}
                    {pathChip.allowToReplaceText && subPathReplace
                      ? subPathReplace.replaceText
                      : pathChip.displayText}
                    {pathHelpingText && `  (${pathHelpingText.helpingText})`}
                  </Typography>
                </>
              ) : (
                <Link
                  key={`Breadcrumbs_${index}`}
                  underline='hover'
                  sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                  color='inherit'
                  onClick={() => {
                    navigate(
                      linkParams
                        ? replaceLinkParams(pathChip.linkTo, linkParams.params)
                        : pathChip.linkTo,
                    );
                  }}
                >
                  {pathChip.icon}
                  {pathChip.allowToReplaceText && subPathReplace
                    ? subPathReplace.replaceText
                    : pathChip.displayText}
                  {pathHelpingText && `  (${pathHelpingText.helpingText})`}
                </Link>
              ))
            );
          })}
        {
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color='text.primary'
          >
            {chips[activeBreadcrumb].icon}
            {chips[activeBreadcrumb].displayText}
            {activeBreadcrumbHelpingText && `  (${activeBreadcrumbHelpingText})`}
          </Typography>
        }
      </Breadcrumbs>
    </PaperCard>
  );
};

export default BreadcrumbsBar;
