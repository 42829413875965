import React, { FC, useState, useEffect } from "react";
import { UserStepProps } from "./CommonProps";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import { useAppDispatch } from "../../../Redux/Store";
import {
  addUserSelector,
  fetchClients,
  fetchTenants,
  reset,
  setActiveStep,
  setTenantAndClientStep,
} from "../../../Redux/Slice/Users/AddUserSlice";
import { LoadingButton } from "@mui/lab";
import { AddUserStep } from "../../../Redux/State/User/AddUserState";
import { useSelector } from "react-redux";

const TenantAndClientStep: FC<UserStepProps> = function ({ active }) {
  const dispatch = useAppDispatch();
  const {
    tenantAndClientAssignmentStep: { clients, tenants },
  } = useSelector(addUserSelector);

  const theme = useTheme();

  const [selectedTenants, setSelectedTenants] = useState<Array<number>>([]);
  const [selectedClients, setSelectedClients] = useState<Array<string>>([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    dispatch(fetchTenants());
    return () => {
      dispatch(reset());
    };
  }, []);

  const loadClients = async (tenantIds: Array<number>) => {
    if (tenantIds.length > 0) {
      dispatch(fetchClients(tenantIds));
    }
  };

  const handleTenantChange = (event: SelectChangeEvent<Array<number>>) => {
    const {
      target: { value },
    } = event;
    const selectedTenants =
      typeof value === "string" ? value.split(",").map((x) => parseInt(x)) : value;

    setSelectedTenants(selectedTenants);

    const updatedClientIds: Array<string> = [];

    selectedClients.map((tenantClientId) => {
      const tenantCId = parseInt(tenantClientId?.split("|")[0]);
      const clientId = parseInt(tenantClientId?.split("|")[1]);
      if (selectedTenants.indexOf(tenantCId) > -1) {
        updatedClientIds.push(getTenantClientStr(tenantCId, clientId));
      }
    });

    setSelectedClients(updatedClientIds);
    loadClients(selectedTenants);
  };

  const getTenantClientStr = (tenantId: number, clientId: number) => {
    return `${tenantId}|${clientId}`;
  };

  return (
    <Box hidden={!active}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor='tenant-dropdown'>Tenants</InputLabel>
            <Select
              labelId='tenant-dropdown'
              id='tenant-dropdown'
              multiple
              fullWidth
              value={selectedTenants}
              input={<OutlinedInput label='Tag' />}
              renderValue={(selected) => {
                return tenants
                  .filter((r) => selected.indexOf(r.tenantId) > -1)
                  .map((r) => r.tenantName)
                  .join(", ");
              }}
              MenuProps={MenuProps}
              onChange={handleTenantChange}
            >
              {tenants.map((tenant, index) => (
                <MenuItem
                  key={"tenant_" + index}
                  value={tenant.tenantId}
                >
                  <Checkbox checked={selectedTenants.indexOf(tenant.tenantId) > -1} />
                  <ListItemText primary={tenant.tenantName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor='client-dropdown'>Clients</InputLabel>
            <Select
              labelId='client-dropdown'
              id='client-dropdown'
              multiple
              fullWidth
              disabled={selectedTenants.length == 0}
              value={selectedClients}
              input={<OutlinedInput label='Tag' />}
              renderValue={(selected) => {
                const retVal: Array<string> = [];
                clients.forEach((tenant) => {
                  tenant.clients.forEach((client) => {
                    if (
                      selected.indexOf(getTenantClientStr(tenant.tenantId, client.clientId)) > -1
                    ) {
                      retVal.push(`${client.clientName} (${tenant.tenantName})`);
                    }
                  });
                });
                return retVal.join(", ");
              }}
              MenuProps={MenuProps}
            >
              {clients.map((tenantClient, index) => (
                <div key={index}>
                  <ListSubheader key={"tenantClient_" + index}>
                    {tenantClient.tenantName}
                  </ListSubheader>
                  {tenantClient.clients.map((client, cIndex) => {
                    return (
                      <MenuItem
                        key={"client_" + cIndex}
                        value={getTenantClientStr(tenantClient.tenantId, client.clientId)}
                      >
                        <Checkbox
                          checked={
                            selectedClients.findIndex(
                              (x) =>
                                x == getTenantClientStr(tenantClient.tenantId, client.clientId),
                            ) >= 0
                          }
                          onChange={({ target: { checked } }) => {
                            const checkBoxValue = getTenantClientStr(
                              tenantClient.tenantId,
                              client.clientId,
                            );
                            if (checked && selectedClients.indexOf(checkBoxValue) == -1) {
                              setSelectedClients([...selectedClients, checkBoxValue]);
                            }
                            if (!checked && selectedClients.indexOf(checkBoxValue) > -1) {
                              const newArr = [...selectedClients];
                              newArr.splice(
                                newArr.findIndex((item) => item === checkBoxValue),
                                1,
                              );
                              setSelectedClients(newArr);
                            }
                          }}
                        />
                        <ListItemText primary={client.clientName} />
                      </MenuItem>
                    );
                  })}
                </div>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row-reverse"}
        spacing={1}
        paddingTop={theme.spacing(2)}
      >
        <Grid item>
          <LoadingButton
            variant='contained'
            onClick={() => {
              dispatch(
                setTenantAndClientStep({
                  selectedTenants: selectedTenants,
                  selectedClients: selectedClients,
                }),
              );
              dispatch(setActiveStep(AddUserStep.Summary));
            }}
          >
            Next
          </LoadingButton>
        </Grid>
        <Grid item>
          <Button
            color='secondary'
            variant='contained'
            onClick={() => {
              dispatch(setActiveStep(AddUserStep.RolesInfo));
            }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TenantAndClientStep;
