import * as React from "react";
import history from "./history";
import { Blocker } from "history";

export const useBlocker = (blocker: Blocker, when = true) => {
  React.useEffect(() => {
    if (!when) return;
    const unblock = history.block((tx) => {
      // <-- use history
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [blocker, when]);
};
