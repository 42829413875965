import { AuthTokenKey, RefreshTokenKey, UserIdKey, UserImageURLKey } from "./Constants";

const isUserAuthenticated = () => {
  return localStorage.getItem(AuthTokenKey) !== null;
};

const getAuthenticationToken = () => {
  return localStorage.getItem(AuthTokenKey) || "";
};

const setAuthenticationValues = (
  authToken: string,
  refreshToken: string,
  userId: string,
  userImageUrl: string,
) => {
  if (authToken) {
    localStorage.setItem(AuthTokenKey, authToken);
  }
  if (refreshToken) {
    localStorage.setItem(RefreshTokenKey, refreshToken);
  }
  if (userId) {
    localStorage.setItem(UserIdKey, userId);
  }
  if (userImageUrl) {
    localStorage.setItem(UserImageURLKey, userImageUrl);
  }
};

const clearAuthenticationValues = () => {
  localStorage.removeItem(AuthTokenKey);
  localStorage.removeItem(RefreshTokenKey);
  localStorage.removeItem(UserIdKey);
  localStorage.removeItem(UserImageURLKey);
};

const getAutheticatedUserId = () => {
  return localStorage.getItem(UserIdKey);
};

const redirectToLogin = () => {
  const url = "/login";
  clearAuthenticationValues();
  window.location.href = url;
};

const getTokenExpirationDate = () => {
  try {
    return JSON.parse(atob(getAuthenticationToken().split(".")[1])).exp;
  } catch (e) {
    return null;
  }
};

const isTokenExpired = () => {
  const exp = getTokenExpirationDate() * 1000;
  return exp < Date.now();
};

export {
  clearAuthenticationValues as ClearAuthenticationValues,
  getAuthenticationToken as GetAuthenticationToken,
  getAutheticatedUserId as GetAutheticatedUserId,
  getTokenExpirationDate as GetTokenExpirationDate,
  isTokenExpired as IsTokenExpired,
  isUserAuthenticated as IsUserAuthenticated,
  redirectToLogin as RedirectToLogin,
  setAuthenticationValues as SetAuthenticationValues,
};
