import React, { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { GetClientApiKeyById, SaveClientApiKey } from "../../../../Api/Client/ClientEndpoints";
import {
  ApiIntegrationEnum,
  GetApiIntegrationEnumDisplayName,
} from "../../../../Models/Enums/Applications";
import {
  editClientIdSelector,
  editClientTenantIdSelector,
} from "../../../../Redux/Selectors/Client/EditClientSelectors";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../../Redux/Slice/Application/ApplicationSlice";
import { fetchClientApiKeys } from "../../../../Redux/Slice/Clients/ClientApiKeysGridSlice";
import { closeAddEditClientApiKeyModal } from "../../../../Redux/Slice/Clients/EditClientSlice";
import { useAppDispatch } from "../../../../Redux/Store";

export type AddEditClientApiKeyModalProps = {
  apiKeyId: number;
};

const validationSchema = Yup.object().shape({
  integrationType: Yup.number()
    .required("Integration Type is required")
    .not([0], "Integration Type is required"),
  apiKey: Yup.string().required("Api Key is required"),
  apiUrl: Yup.string().required("Api Url is required").url("Invalid URL"),
});

const AddEditClientApiKeyModal: FC<AddEditClientApiKeyModalProps> = function ({ apiKeyId }) {
  const isEditMode = apiKeyId !== 0;
  const dispatch = useAppDispatch();
  const clientId = useSelector(editClientIdSelector);
  const tenantId = useSelector(editClientTenantIdSelector);
  const [loading, setLoading] = useState(true);

  const formik = useFormik({
    initialValues: {
      integrationType: ApiIntegrationEnum.SelfPayEligibility,
      apiKey: "",
      apiUrl: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      SaveClientApiKey(tenantId, clientId, {
        id: apiKeyId,
        apiIntegrationId: values.integrationType,
        apiKey: values.apiKey,
        apiUrl: values.apiUrl,
      })
        .then(({ data }) => {
          setSubmitting(false);
          if (data.succeeded) {
            dispatch(showSuccessSnackbar("Client Api Key Saved Successfully."));
            dispatch(closeAddEditClientApiKeyModal());
            dispatch(fetchClientApiKeys());
          } else {
            dispatch(showErrorSnackbar(data.messages[0]));
          }
        })
        .catch(() => {
          dispatch(showErrorSnackbar("Failed to save Client Api Key."));
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (isEditMode) {
      setLoading(true);
      GetClientApiKeyById(tenantId, clientId, apiKeyId)
        .then(({ data }) => {
          setLoading(false);
          if (data.succeeded) {
            formik.setValues({
              apiKey: data.data.apiKey,
              apiUrl: data.data.apiUrl,
              integrationType: data.data.apiIntegrationId,
            });
          } else {
            dispatch(showErrorSnackbar("Failed to load the Api Key Data."));
          }
        })
        .catch(() => {
          dispatch(showErrorSnackbar("Failed to load the Api Key Data."));
        });
    } else {
      setLoading(false);
    }
  }, []);

  const { handleChange, values, handleBlur, errors, touched, handleSubmit, isSubmitting } = formik;

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>{isEditMode ? "Edit" : "Add"} Api Key</DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <FormControl
                fullWidth
                error={touched.integrationType && !!errors.integrationType}
                disabled={loading || isSubmitting}
              >
                <InputLabel>API Integrations</InputLabel>
                <Select
                  name='integrationType'
                  placeholder='Integration Type'
                  label='Integration Type'
                  value={values.integrationType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.integrationType && !!errors.integrationType}
                >
                  <MenuItem
                    value=''
                    disabled
                  >
                    <em>Select</em>
                  </MenuItem>
                  {Object.keys(ApiIntegrationEnum).map((key, index) => {
                    if (!isNaN(Number(key))) {
                      return (
                        <MenuItem
                          value={key}
                          key={index}
                        >
                          {GetApiIntegrationEnumDisplayName(Number(key))}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
                {touched.integrationType && !!errors.integrationType && (
                  <FormHelperText error={true}>{errors.integrationType}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                name='apiKey'
                placeholder='Api Key'
                label='Api Key'
                fullWidth
                value={values.apiKey}
                error={touched.apiKey && !!errors.apiKey}
                helperText={touched.apiKey && errors.apiKey}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={loading || isSubmitting}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                name='apiUrl'
                placeholder='Api Url'
                label='Api Url'
                fullWidth
                value={values.apiUrl}
                error={touched.apiUrl && !!errors.apiUrl}
                helperText={touched.apiUrl && errors.apiUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={loading || isSubmitting}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          disabled={false}
          color='secondary'
          onClick={() => {
            dispatch(closeAddEditClientApiKeyModal());
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant='contained'
          loading={isSubmitting}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditClientApiKeyModal;
