import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetTenantsPageRequest } from "../../../Api/Tenants/TenantEndpoints";
import { PaginatedResult } from "../../../Models/ApiResponse/Common/PaginatedResult";
import { LoadMode, TenantInfo } from "../../../Models/ApiResponse/Tenant/TenantInfo";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { TenantGridState } from "../../State/Tenant/TenantGridState";
import { RootState } from "../../Store";

const getInitialState = (): TenantGridState => {
  return {
    rows: [],
    status: ApiCallStatus.NoStarted,
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
    isAddEditModalOpen: false,
    failedConfigurationModalOpen: false,
    tenantId: 0,
    quickSearch: "",
    loadMode: LoadMode.ActiveOnly,
  } as TenantGridState;
};

const fetchPagedTenants = createAsyncThunk<PaginatedResult<TenantInfo>, void, { state: RootState }>(
  "tenants/fetchAll",
  async (_, thunk) => {
    const { getState } = thunk;
    const state = getState().TenantGridSlice;
    const response = await GetTenantsPageRequest(
      state.currentPage,
      state.pageSize,
      state.quickSearch,
      state.loadMode === LoadMode.ActiveOnly,
    );
    return response.data as PaginatedResult<TenantInfo>;
  },
);

const TenantGridSlice = createSlice({
  name: "TenantGrid",
  initialState: getInitialState(),
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload + 1;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    showAddEditModal: (state, action: PayloadAction<number>) => {
      state.isAddEditModalOpen = true;
      state.tenantId = action.payload;
    },
    hideAddEditModal: (state) => {
      state.isAddEditModalOpen = false;
      state.tenantId = 0;
    },
    showFailedConfigurationModal: (state, action: PayloadAction<number>) => {
      state.failedConfigurationModalOpen = true;
      state.tenantId = action.payload;
    },
    hideFailedConfigurationModal: (state) => {
      state.failedConfigurationModalOpen = false;
      state.tenantId = 0;
    },
    setQuickSearch: (state, action: PayloadAction<string>) => {
      state.quickSearch = action.payload;
    },
    setLoadMode: (state, action: PayloadAction<LoadMode>) => {
      state.loadMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPagedTenants.fulfilled, (state, action) => {
      if (action.payload.succeeded) {
        state.rows = action.payload.data;
        state.currentPage = action.payload.currentPage;
        state.totalCount = action.payload.totalCount;
        state.totalPages = action.payload.totalPages;
        state.status = ApiCallStatus.Success;
      } else {
        state.status = ApiCallStatus.Error;
      }
    });
    builder.addCase(fetchPagedTenants.pending, (state) => {
      state.status = ApiCallStatus.Loading;
    });
    builder.addCase(fetchPagedTenants.rejected, (state) => {
      state.rows = [];
      state.status = ApiCallStatus.Error;
      state.error = "Something went wrong";
    });
  },
});

export const {
  setPage,
  setPageSize,
  showAddEditModal,
  hideAddEditModal,
  showFailedConfigurationModal,
  hideFailedConfigurationModal,
  setQuickSearch,
  setLoadMode,
} = TenantGridSlice.actions;
export const tenantGridSelector = (state: RootState) => state.TenantGridSlice;
export { fetchPagedTenants };
export default TenantGridSlice.reducer;
