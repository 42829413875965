import React, {
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import { Badge } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { DeleteClient } from "../../Api/Client/ClientEndpoints";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Slice/Application/ApplicationSlice";
import {
  clientsGridSelector,
  fetchClients,
  setPage,
  setPageSize,
  showFailedConfigurationModalClient,
} from "../../Redux/Slice/Clients/ClientsGridSlice";
import { useAppDispatch } from "../../Redux/Store";
import AitDataGrid from "../Common/DataGrid/AitDataGrid";
import ConfirmModal from "../Common/Modals/ConfirmModal";


const ClientsGrid = function () {
  const state = useSelector(clientsGridSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tenantId } = useParams();
  useEffect(() => {
    dispatch(fetchClients({
      tenantId: Number(tenantId)
    }));
  }, [state.currentPage, state.pageSize]);

  const initialConfirmDelete = {
    show: false,
    message: "",
    tenantId: 0,
    clientId: 0,
    clientName: "",
  };
  const [confirmDelete, setConfirmDelete] = useState<{
    show: boolean;
    message: string;
    tenantId: number;
    clientId: number;
    clientName: string;
  }>(initialConfirmDelete);
  const columns: Array<GridColDef> = [
    {
      field: "clientId",
      headerName: "Client ID",
      width: 70,
      sortable: false,
      flex: 0,
    },
    {
      field: "tenantName",
      headerName: "Tenant Name",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Name",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "clientCode",
      headerName: "Client Code",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "faxNumber",
      headerName: "Fax Number",
      width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: "tenantFailedConfigurationCount",
      headerName: "Failed Configurations",
      align: "center",
      renderCell: ({ row }) => {
        const failedCount = Number(row["tenantFailedConfigurationCount"]);
        return (<Badge badgeContent={failedCount.toString()} color="error" sx={{
          cursor: "pointer"
        }}
          onClick={() => {
            dispatch(showFailedConfigurationModalClient({
              tenantId: Number(tenantId),
              clientId: Number(row["clientId"])
            }));
          }}
        >
          <ErrorIcon />
        </Badge>);

      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      sortable: false,
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            label='Edit'
            key='editClient'
            showInMenu
            onClick={() => {
              navigate(`/admin/tenants/${Number(row["tenantId"])}/clients/${id}/edit`);
            }}
          />,
          <GridActionsCellItem
            label='Delete'
            key='deleteClient'
            showInMenu
            onClick={() => {
              setConfirmDelete({
                show: true,
                message: `Are you sure want to client delete ${row["name"]}?`,
                tenantId: Number(row["tenantId"]),
                clientId: Number(id),
                clientName: row["name"],
              });
            }}
          />,
        ];
      },
    },
  ];

  const confirmModal = () => (
    <ConfirmModal
      title='Confirm'
      message={confirmDelete?.message}
      onNo={() => {
        setConfirmDelete(initialConfirmDelete);
      }}
      onYes={() => {
        setConfirmDelete(initialConfirmDelete);
        DeleteClient(confirmDelete.tenantId, confirmDelete.clientId)
          .then(({ data: res }) => {
            if (res.succeeded) {
              dispatch(showSuccessSnackbar(res.messages[0]));
            } else {
              dispatch(showErrorSnackbar(res.messages[0]));
            }
            dispatch(fetchClients({
              tenantId: Number(tenantId)
            }));
          })
          .catch(() => {
            dispatch(showErrorSnackbar("Failed to delete client."));
          });
      }}
      show={confirmDelete.show}
    />
  );

  return (
    <div
      style={{
        height: "500px",
      }}
    >
      {confirmDelete.show && confirmModal()}
      <AitDataGrid
        columns={columns}
        keyColumnName='clientId'
        state={state}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export default ClientsGrid;
