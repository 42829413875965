import { GetDeletedClaimBatches } from "../../../../Api/Tenants/TenantDashboardEndpoints";
import { PaginatedResult } from "../../../../Models/ApiResponse/Common/PaginatedResult";
import { GetDeletedClaimBatchesQueryResponse } from "../../../../Models/ApiResponse/TenantDashboard/GetDeletedClaimBatchesQueryResponse";
import { DeletedBatchesGridState } from "../../../State/TenantDashboard/DeletedBatchesGrid/DeletedBatchesGridState";
import { RootState } from "../../../Store";
import { ApiCallStatus } from "../../../State/Common/GenericApiState";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SortModel } from "../../../State/Common/GenericGridState";
import { EntityPrefix, GetColumnPrefix } from "../../../../Util/ColumnHelper";

export const fetchDeletedBatchesClaims = createAsyncThunk<
  PaginatedResult<GetDeletedClaimBatchesQueryResponse>,
  { tenantId: number; quickSearch?: string },
  { state: RootState }
>("dashboard/fetchDeletedBatchesClaims", async (_, thunk) => {
  const { getState } = thunk;
  const state = getState().deletedBatchesGridSlice;
  const res = await GetDeletedClaimBatches(
    _.tenantId,
    state.currentPage,
    state.pageSize,
    _.quickSearch || "",
    GetColumnPrefix(EntityPrefix.ClaimStatusBatches, state.sortModel.field),
    state.sortModel.order,
  );
  return res.data as PaginatedResult<GetDeletedClaimBatchesQueryResponse>;
});

const getInitialState = () => {
  return {
    currentPage: 1,
    pageSize: 10,
    status: ApiCallStatus.NoStarted,
    totalPages: 0,
    totalCount: 0,
    rows: [],
    sortModel: {
      field: "lastModifiedOn",
      order: "desc",
    },
  } as DeletedBatchesGridState;
};

const deletedBatchesGridSlice = createSlice({
  name: "deletedBatchesGridSlice",
  initialState: getInitialState(),
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload + 1;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSortModel: (state, action: PayloadAction<SortModel>) => {
      state.sortModel = action.payload;
    },
  },
  extraReducers(builder) {
    //tofo
    builder
      .addCase(fetchDeletedBatchesClaims.pending, (state) => {
        state.status = ApiCallStatus.Loading;
      })
      .addCase(fetchDeletedBatchesClaims.fulfilled, (state, action) => {
        if (action.payload.succeeded) {
          state.rows = action.payload.data;
          state.currentPage = action.payload.currentPage;
          state.totalCount = action.payload.totalCount;
          state.totalPages = action.payload.totalPages;
          state.status = ApiCallStatus.Success;
        } else {
          state.status = ApiCallStatus.Error;
        }
      })
      .addCase(fetchDeletedBatchesClaims.rejected, (state) => {
        state.status = ApiCallStatus.Error;
      });
  },
});
export const { setPage, setPageSize, setSortModel } = deletedBatchesGridSlice.actions;
export const deletedBatchesGridSelector = (state: RootState) => state.deletedBatchesGridSlice;
export default deletedBatchesGridSlice.reducer;
