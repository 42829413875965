import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Menu,
  setActiveBreadCumb,
  setActiveMenu,
} from "../../Redux/Slice/Application/ApplicationSlice";
import UserLoginHistoryGrid from "../../Components/Dashboard/UserLoginHistoryGrid/UserLoginHistoryGrid";
import { BreadcrumbsEnum } from "../../Components/Base/BreadcrumbsBar";

const Dashboard: FC = function () {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveMenu(Menu.Dashboard));
    dispatch(setActiveBreadCumb(BreadcrumbsEnum.Dashboard));
  }, []);

  return (
    <div>
      <UserLoginHistoryGrid />
    </div>
  );
};

export default Dashboard;
