import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import DnsIcon from "@mui/icons-material/Dns";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleIcon from "@mui/icons-material/People";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import {
  CSSObject,
  styled,
  Theme,
} from "@mui/material/styles";
import { Menu } from "../../Redux/Slice/Application/ApplicationSlice";


export interface SidebarProps {
  isOpen?: boolean;
  activeMenu?: Menu;
}
export const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  marginTop: `calc(${theme.spacing(8)} + 1px)`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginTop: `calc(${theme.spacing(8)} + 1px)`,
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: drawerWidth,
    marginTop: `calc(${theme.spacing(8)} + 1px)`,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }),
);

interface SidebarMenuItem {
  displayText: string;
  key: string;
  icon: any;
  onClick?(): any;
}

const Sidebar: FC<SidebarProps> = function ({ isOpen, activeMenu }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const menus: SidebarMenuItem[] = [
    {
      displayText: "Dashboard",
      icon: <SpaceDashboardIcon />,
      key: Menu.Dashboard,
      onClick: function () {
        navigate("/admin/dashboard");
      },
    },
    {
      displayText: "Servers",
      key: Menu.Servers,
      icon: <DnsIcon />,
      onClick: function () {
        navigate("/admin/servers");
      },
    },
    {
      displayText: "Tenants",
      icon: <FolderCopyIcon />,
      key: Menu.Tenants,
      onClick: function () {
        navigate("/admin/tenants");
      },
    },
    {
      displayText: "Users",
      icon: <PeopleIcon />,
      key: Menu.Users,
      onClick: function () {
        navigate("/admin/users");
      },
    },
    {
      displayText: "Roles",
      icon: <ManageAccountsIcon />,
      key: Menu.Roles,
      onClick: function () {
        navigate("/admin/roles");
      },
    },
  ];

  return (
    <Box>
      <Drawer
        variant='permanent'
        open={isOpen}
      >
        <List>
          {menus.map((menu) => {
            const active = menu.key == activeMenu;
            return (
              <ListItem
                key={menu.key}
                disablePadding
                sx={{ display: "block" }}
              >
                <Tooltip
                  title={isOpen ? "" : menu.displayText}
                  placement='right'
                  hidden={isOpen}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: isOpen ? "initial" : "center",
                      px: 2.5,
                      borderRight: active ? 5 : 0,
                      borderRadius: 1,
                      borderRightColor: theme.palette.primary.main,
                      color: active
                        ? theme.palette.primary.main
                        : theme.components?.MuiListItemButton?.defaultProps?.color,
                    }}
                    onClick={() => {
                      if (menu.onClick) {
                        menu.onClick();
                      }
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: isOpen ? 3 : "auto",
                        justifyContent: "center",
                        color: active
                          ? theme.palette.primary.main
                          : theme.components?.MuiListItemButton?.defaultProps?.color,
                      }}
                      color='primary'
                    >
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={menu.displayText}
                      sx={{ opacity: isOpen ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
