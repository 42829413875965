import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { UpdateRolePermissions } from "../../Api/Roles/RolesEndpoints";
import {
  Menu,
  setActiveBreadCumb,
  setActiveMenu,
  showErrorSnackbar,
} from "../../Redux/Slice/Application/ApplicationSlice";
import {
  fetchRolePermissions,
  permissionGridSelector,
  reset,
  setPage,
  setPageSize,
  setRoleId,
} from "../../Redux/Slice/Roles/PermissionGridSlice";
import { useAppDispatch } from "../../Redux/Store";
import { BreadcrumbsEnum } from "../Base/BreadcrumbsBar";
import AitDataGrid from "../Common/DataGrid/AitDataGrid";
import PaperCard from "../Common/Styled/PaperCard";

const EditPermission = function () {
  const state = useSelector(permissionGridSelector);
  const dispatch = useAppDispatch();
  const params = useParams();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

  useEffect(() => {
    if (state.rows.length > 0) {
      setRowSelectionModel(state.rows.filter((x) => x.selected).map((x) => x.value));
    }
  }, [state.rows]);

  useEffect(() => {
    dispatch(setActiveMenu(Menu.Roles));
    dispatch(setActiveBreadCumb(BreadcrumbsEnum.EditPermission));
    if (params.id) {
      dispatch(setRoleId(params.id));
      dispatch(fetchRolePermissions());
    }

    return () => {
      dispatch(reset());
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: "type",
      headerName: "Type",
      sortable: false,
      width: 100,
      flex: 0,
    },
    {
      field: "value",
      headerName: "Permission Name",
      sortable: false,
      flex: 1,
    },
  ];

  return (
    <PaperCard>
      <Box
        pb={1}
        pl={1}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Typography variant='h5'>Edit Permission for {state.roleName}</Typography>
        <LoadingButton
          variant='contained'
          loading={submitting}
          onClick={() => {
            setSubmitting(true);
            UpdateRolePermissions({
              roleId: state.roleId,
              roleClaims: state.rows.map((x) => {
                const newObj = { ...x };
                newObj.selected = rowSelectionModel.indexOf(x.value) > -1;
                return newObj;
              }),
            })
              .then(() => {
                setSubmitting(false);
                navigate("/admin/roles", { replace: true });
              })
              .catch(({ response }) => {
                setSubmitting(false);
                let message = response?.data?.Messages[0];
                if (!message) {
                  message = "Failed to update permission";
                }
                dispatch(showErrorSnackbar(message));
              });
          }}
        >
          Save Permissions
        </LoadingButton>
      </Box>
      <Box sx={{ height: 400, width: "100%" }}>
        <AitDataGrid
          state={state}
          columns={columns}
          setPage={setPage}
          setPageSize={setPageSize}
          keyColumnName='value'
          checkboxSelection={true}
          disableRowSelectionOnClick={true}
          isRowSelectable={true}
          paginationMode='client'
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />
      </Box>
    </PaperCard>
  );
};

export default EditPermission;
