import { GetAllAuthTypeResponse } from "../../Models/ApiResponse/AuthType/GetAllAuthTypeResponse";
import { IResult } from "../../Models/ApiResponse/Common/IResult";
import { httpClient } from "../APIBase";

const authTypeBasePath = "/admin/authType";

export const GetAllAuthTypesRequest = function (tenantId: number) {
  return httpClient.get<IResult<Array<GetAllAuthTypeResponse>>>(
    `${authTypeBasePath}/${tenantId}/all`,
  );
};
