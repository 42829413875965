import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginatedResult } from "../../../Models/ApiResponse/Common/PaginatedResult";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { RootState } from "../../Store";
import { GetTenantUsersUserLoginHistoryGridResponse } from "../../../Models/ApiResponse/Tenant/GetTenantUsersUserLoginHistoryGridResponse";
import { GetTenantAssociatedUsersLoginHistoryGrid } from "../../../Api/Tenants/TenantEndpoints";
import { TenantUsersLoginHistoryGridState } from "../../State/Tenant/TenantUsersLoginHistoryGridState";

export const fetchTenantAssociatedUserLoginGridData = createAsyncThunk<
  PaginatedResult<GetTenantUsersUserLoginHistoryGridResponse>,
  { tenantId: number },
  { state: RootState }
>("tenant/fetchTenantUserLoginGridHistorySlice", async (_, thunk) => {
  const { getState } = thunk;
  const state = getState().TenantUserLoginGridHistorySlice;
  const res = await GetTenantAssociatedUsersLoginHistoryGrid(
    _.tenantId,
    state.currentPage,
    state.pageSize,
    state.quickSearch,
  );
  return res.data as PaginatedResult<GetTenantUsersUserLoginHistoryGridResponse>;
});

const getInitialState = () => {
  return {
    currentPage: 1,
    pageSize: 10,
    rows: [],
    status: ApiCallStatus.NoStarted,
    totalCount: 0,
    totalPages: 0,
    error: "",
    quickSearch: "",
  } as TenantUsersLoginHistoryGridState;
};

const tenantUserLoginGridHistorySlice = createSlice({
  name: "tenantUserLoginGridHistory",
  initialState: getInitialState(),
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload + 1;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setQuickSearch: (state, action: PayloadAction<string>) => {
      state.quickSearch = action.payload;
    },
    reset: () => {
      return { ...getInitialState() };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTenantAssociatedUserLoginGridData.pending, (state) => {
        state.status = ApiCallStatus.Loading;
      })
      .addCase(fetchTenantAssociatedUserLoginGridData.fulfilled, (state, action) => {
        if (action.payload.succeeded) {
          state.rows = action.payload.data;
          state.currentPage = action.payload.currentPage;
          state.totalCount = action.payload.totalCount;
          state.totalPages = action.payload.totalPages;
          state.status = ApiCallStatus.Success;
        } else {
          state.status = ApiCallStatus.Error;
        }
      })
      .addCase(fetchTenantAssociatedUserLoginGridData.rejected, (state) => {
        state.rows = [];
        state.status = ApiCallStatus.Error;
        state.error = "Something went wrong";
      });
  },
});

export const tenantUserLoginGridHistorySelector = (state: RootState) =>
  state.TenantUserLoginGridHistorySlice;
export const { setPage, setPageSize, reset, setQuickSearch } =
  tenantUserLoginGridHistorySlice.actions;
export default tenantUserLoginGridHistorySlice.reducer;
