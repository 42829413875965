import React, { FC, useEffect } from "react";
import { fetchUserRecord, reset, setUserId } from "../../../Redux/Slice/Users/EditUserSlice";
import { useAppDispatch } from "../../../Redux/Store";

import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";

import ChangePinComp from "../CommonComponents/ChangePinComp";

export type ChangePinModalProps = {
  nameToDisplay: string;
  userId: string;
  onCancel(): void;
  onSave(): void;
};

const ChangePinModal: FC<ChangePinModalProps> = function ({
  userId,
  nameToDisplay,
  onCancel,
  onSave,
}) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(setUserId(userId));
      dispatch(fetchUserRecord(userId));
    }
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Dialog open={true}>
      <DialogTitle>Change Pin - {nameToDisplay}</DialogTitle>
      <DialogContent>
        <Box py={2}>
          <ChangePinComp
            userId={userId}
            showCancel={true}
            onCancel={onCancel}
            onSave={onSave}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePinModal;
