import React, { FC, PropsWithChildren } from "react";
import { authenticatedUserSelector } from "../../Redux/Slice/AuthenticatedUser/AutheticatedUserSlice";
import { useStateSelector } from "../../Redux/Store";

export interface ProtectedRouteProps {}

const ProtectedRoute: FC<PropsWithChildren<ProtectedRouteProps>> = function ({
  children,
}): React.ReactNode | any {
  const { isUserAuthenticated } = useStateSelector(authenticatedUserSelector);

  if (!isUserAuthenticated) {
    window.location.href = "/login";
  }
  return children;
};

export default ProtectedRoute;
