import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetFailedConfiguration } from "../../../Api/Tenants/TenantEndpoints";
import { GetErrorOrFailedClientInsuranceRpaConfigByClientIdResponse } from "../../../Models/ApiResponse/Shared/GetFailedClientInsuranceRpaConfigurationsResponse";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { FailedConfigurationGridState } from "../../State/FailedConfiguration/FailedConfigurationGridState";
import { RootState } from "../../Store";
import { showErrorSnackbar } from "../Application/ApplicationSlice";

export const fetchFailedConfiguration = createAsyncThunk<
  Array<GetErrorOrFailedClientInsuranceRpaConfigByClientIdResponse>,
  { tenantId: number; clientId: number },
  { state: RootState }
>("tenants/fetchFailedConfiguration", async (_, thunk) => {
  const { dispatch, rejectWithValue, fulfillWithValue } = thunk;
  try {
    const response = await GetFailedConfiguration(_.tenantId, _.clientId);
    if (!response.data.succeeded) {
      dispatch(showErrorSnackbar("Failed to Configuration Info"));
      return rejectWithValue([]);
    }
    return fulfillWithValue(response.data.data);
  } catch (error) {
    dispatch(showErrorSnackbar("Failed to Configuration Info"));
    return rejectWithValue([]);
  }
});

const getInitialState = () => {
  return {
    rows: [],
    status: ApiCallStatus.NoStarted,
    error: "",
  } as FailedConfigurationGridState;
};

const failedConfigurationGridSlice = createSlice({
  name: "failedConfigurationGridSlice",
  initialState: getInitialState(),
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchFailedConfiguration.pending, (state) => {
      state.status = ApiCallStatus.Loading;
      state.rows = [];
    });
    builder.addCase(fetchFailedConfiguration.fulfilled, (state, action) => {
      state.rows = action.payload;
      state.status = ApiCallStatus.Success;
    });
    builder.addCase(fetchFailedConfiguration.rejected, (state) => {
      state.status = ApiCallStatus.Error;
      state.rows = [];
    });
  },
});

export default failedConfigurationGridSlice.reducer;
