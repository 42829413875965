import React, {
  FC,
  useEffect,
} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { BreadcrumbsEnum } from "../../Components/Base/BreadcrumbsBar";
import AddClientModal from "../../Components/Clients/AddClientModal";
import ClientsGrid from "../../Components/Clients/ClientsGrid";
import SearchTextField
  from "../../Components/Common/SearchTextField/SearchTextField";
import PaperCard from "../../Components/Common/Styled/PaperCard";
import ClientFailedConfigurationModal
  from "../../Components/Shared/ClientFailedConfigurationModal";
import {
  Menu,
  setActiveBreadCumb,
  setActiveMenu,
  setBreadCumbDisplayText,
} from "../../Redux/Slice/Application/ApplicationSlice";
import {
  clientsGridSelector,
  fetchClients,
  openAddModal,
  reset,
  setQuickSearch,
} from "../../Redux/Slice/Clients/ClientsGridSlice";
import { useAppDispatch } from "../../Redux/Store";


const ViewClients: FC = function () {
  const dispatch = useAppDispatch();
  const { tenantId } = useParams();
  const state = useSelector(clientsGridSelector);
  useEffect(() => {
    dispatch(setActiveMenu(Menu.Tenants));
    dispatch(setActiveBreadCumb(BreadcrumbsEnum.Clients));
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    dispatch(setBreadCumbDisplayText([
      { path: BreadcrumbsEnum.TenantInstance, replaceText: state.rows[0]?.tenantName }
    ]));
  }, [state.rows]);

  return (
    <>
      {state.isAddModalOpen && <AddClientModal />}
      {state.failedConfigurationModalOpen && <ClientFailedConfigurationModal tenantId={state.tenantId} clientId={state.clientId} />}
      <PaperCard>
        <Stack
          pb={1}
          direction="row"
          spacing={1}
        >
          <Typography variant='h5' style={{
            flex: 1
          }}>Clients</Typography>
          <SearchTextField
            label="Search"
            placeholder="Search"
            onSearch={(text) => {
              dispatch(setQuickSearch(text));
              dispatch(fetchClients({
                tenantId: Number(tenantId)
              }));
            }}
            size="small" />
          <Button
            variant='contained'
            onClick={() => {
              dispatch(openAddModal());
            }}
          >
            Create
          </Button>
          <Button
            variant='contained'
            color="secondary"
            onClick={() => {
              dispatch(fetchClients({
                tenantId: Number(tenantId)
              }));
            }}
          >
            Reload
          </Button>
        </Stack>
        <ClientsGrid />
      </PaperCard>
    </>
  );
};

export default ViewClients;
