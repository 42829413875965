const isDevEnvironment = () => {
  try {
    if (process.env.NODE_ENV === "development") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

const ConvertToCamelCase = function (inputStr: string) {
  return inputStr
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
};

export { isDevEnvironment as IsDevEnvironment, ConvertToCamelCase };
