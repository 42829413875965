import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  ListItemText,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { Button, Grid, useTheme } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useAppDispatch } from "../../../Redux/Store";
import {
  addUserSelector,
  fetchRoles,
  setActiveStep,
  setRolesInfo,
} from "../../../Redux/Slice/Users/AddUserSlice";
import { AddUserStep } from "../../../Redux/State/User/AddUserState";
import { UserStepProps } from "./CommonProps";
import { SelectChangeEvent } from "@mui/material/Select";

import { useSelector } from "react-redux";

const RolesInfoStep: FC<UserStepProps> = function ({ active }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const state = useSelector(addUserSelector);
  const {
    rolesInfoStep: { roles },
  } = state;

  useEffect(() => {
    dispatch(fetchRoles());
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [selectedRoles, setSelectedRoles] = useState<string[]>(state.rolesInfoStep.selectedRoles);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Box hidden={!active}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <FormControl fullWidth>
            <InputLabel id='demo-multiple-checkbox-label'>Roles</InputLabel>
            <Select
              labelId='demo-multiple-checkbox-label'
              id='demo-multiple-checkbox'
              multiple
              fullWidth
              value={selectedRoles}
              input={<OutlinedInput label='Tag' />}
              renderValue={(selected) => {
                return roles
                  .filter((r) => selected.indexOf(r.normalizedName) > -1)
                  .map((r) => r.name)
                  .join(", ");
              }}
              MenuProps={MenuProps}
              onChange={handleChange}
            >
              {roles.map((role, index) => (
                <MenuItem
                  key={"Role_" + index}
                  value={role.normalizedName}
                >
                  <Checkbox checked={selectedRoles.indexOf(role.normalizedName) > -1} />
                  <ListItemText primary={role.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row-reverse"}
        spacing={1}
        paddingTop={theme.spacing(2)}
      >
        <Grid item>
          <LoadingButton
            variant='contained'
            onClick={() => {
              dispatch(setRolesInfo(selectedRoles));
              dispatch(setActiveStep(AddUserStep.TenantAndClientAssignment));
            }}
          >
            Next
          </LoadingButton>
        </Grid>
        <Grid item>
          <Button
            color='secondary'
            variant='contained'
            onClick={() => {
              dispatch(setActiveStep(AddUserStep.UserInfo));
            }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RolesInfoStep;
