import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  editUserSelector,
  fetchUserRecord,
  reset,
  setActiveTab,
  setUserId,
} from "../../../Redux/Slice/Users/EditUserSlice";
import { useAppDispatch } from "../../../Redux/Store";
import { Box, useTheme } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector } from "react-redux";
import UserInfoTab from "./UserInfoTab";
import { EditUserTabs } from "../../../Redux/State/User/EditUserState";
import RolesInfoTab from "./RolesInfoTab";
import TenantAndClientTab from "./TenantAndClientTab";
import ChangePasswordTab from "./ChangePasswordTab";
import ChangePinTab from "./ChangePinTab";
import ClientTab from "./ClientTab";

const EditUserBase = function () {
  const { userId, tenantId } = useParams();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { activeTab } = useSelector(editUserSelector);

  useEffect(() => {
    if (userId) {
      dispatch(setUserId(userId));
      dispatch(fetchUserRecord(userId));
    }
    return () => {
      dispatch(reset());
    };
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setActiveTab(newValue + 1));
  };
  return (
    <Box p={theme.spacing(1)}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab - 1}
          onChange={handleChange}
        >
          <Tab label='User Info'></Tab>
          <Tab label='Roles'></Tab>
          <Tab label='Tenant and Client'></Tab>
          <Tab label='Change Password'></Tab>
          <Tab label='Change Pin'></Tab>
        </Tabs>
      </Box>
      <Box py={theme.spacing(2)}>
        <UserInfoTab active={activeTab == EditUserTabs.BasicUserInfo} />
        <RolesInfoTab active={activeTab == EditUserTabs.RolesInfo} />
        {tenantId && Number(tenantId) > 0 ? (
          <ClientTab
            active={activeTab == EditUserTabs.TenantAndClient}
            tenantId={Number(tenantId)}
          />
        ) : (
          <TenantAndClientTab active={activeTab == EditUserTabs.TenantAndClient} />
        )}
        <ChangePasswordTab active={activeTab == EditUserTabs.ChangePassword} />
        <ChangePinTab active={activeTab == EditUserTabs.ChangePin} />
      </Box>
    </Box>
  );
};

export default EditUserBase;
