import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { failedConfigurationSelector } from "../../Redux/Selectors/FailedConfiguration/FailedConfigurationSelector";
import { hideFailedConfigurationModalClient } from "../../Redux/Slice/Clients/ClientsGridSlice";
import { fetchFailedConfiguration } from "../../Redux/Slice/FailedConfiguration/FailedConfigurationGridSlice";
import { hideFailedConfigurationModal } from "../../Redux/Slice/Tenants/TenantsGridSlice";
import { ApiCallStatus } from "../../Redux/State/Common/GenericApiState";
import { useAppDispatch } from "../../Redux/Store";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const PasswordCell = function ({ password }: any) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
    console.log("heloo", showPassword);
  };

  return (
    <TableCell>
      <span>
        <span style={{ display: "flex", alignItems: "center" }}>
          {showPassword ? password : "••••••••"}
          <IconButton
            onClick={togglePasswordVisibility}
            style={{ marginLeft: "9px" }}
          >
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </span>
      </span>
    </TableCell>
  );
};

export type ClientFailedConfigurationModalProps = {
  tenantId: number;
  clientId: number;
};

const ClientFailedConfigurationModal: FC<ClientFailedConfigurationModalProps> = function ({
  tenantId,
  clientId,
}) {
  const dispatch = useAppDispatch();
  const failedConfiguration = useSelector(failedConfigurationSelector);

  useEffect(() => {
    dispatch(
      fetchFailedConfiguration({
        tenantId,
        clientId,
      }),
    );
  }, []);

  const getRows = () =>
    failedConfiguration.rows.map((row, index) => {
      return (
        <TableRow key={index}>
          <TableCell>
            {row.failureReported && (
              <Typography
                style={{
                  backgroundColor: "red",
                  fontWeight: "bold",
                  color: "white",
                  padding: "7px",
                }}
              >
                Failed
              </Typography>
            )}
            {row.expiryWarningReported && (
              <Typography
                style={{
                  backgroundColor: "yellow",
                  fontWeight: "bold",
                  color: "#ff4081",
                  padding: "7px",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                Expiring Soon
              </Typography>
            )}
          </TableCell>
          <TableCell>{row.clientInsuranceLookupName}</TableCell>
          <TableCell>{row.username}</TableCell>
          <PasswordCell password={row.password} />
          <TableCell>
            URL{" "}
            <InsertLinkIcon
              style={{
                cursor: "pointer",
              }}
              onClick={() => window.open(row.targetUrl, "_blank")}
            />
          </TableCell>
          <TableCell>{row.rpaGroupName}</TableCell>
          <TableCell>{row.failureReported && row.failureMessage}</TableCell>
        </TableRow>
      );
    });

  const getNoRowsComponent = () => {
    return <Typography>No Rows found.</Typography>;
  };

  const getLoader = () => {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  };

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth='md'
      scroll='paper'
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        View Failed/Expiring Client RPA Insurance Configurations
        <IconButton
          onClick={() => {
            if (clientId > 0) {
              dispatch(hideFailedConfigurationModalClient());
            } else {
              dispatch(hideFailedConfigurationModal());
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          height: "400px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Insurance name</TableCell>
              <TableCell>UserName</TableCell>
              <TableCell>Password</TableCell>
              <TableCell>Target Uri</TableCell>
              <TableCell>RPA Group Name</TableCell>
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {failedConfiguration.status == ApiCallStatus.Success &&
              failedConfiguration.rows.length > 0 &&
              getRows()}
          </TableBody>
        </Table>
        <Grid
          container
          justifyContent='center'
          pt={3}
          alignItems='center'
        >
          <Grid item>
            {failedConfiguration.status == ApiCallStatus.Success &&
              failedConfiguration.rows.length == 0 &&
              getNoRowsComponent()}
            {failedConfiguration.status == ApiCallStatus.Loading && getLoader()}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ClientFailedConfigurationModal;
