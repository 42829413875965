import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetPaginatedRoles } from "../../../Api/Roles/RolesEndpoints";
import { PaginatedResult } from "../../../Models/ApiResponse/Common/PaginatedResult";
import { RoleResponse } from "../../../Models/ApiResponse/Role/RoleNamesResponse";
import { ApiCallStatus } from "../../State/Common/GenericApiState";
import { RolesGridState } from "../../State/Role/RolesGridState";
import { RootState } from "../../Store";

const getInitialState = () => {
  return {
    currentPage: 1,
    pageSize: 10,
    rows: [],
    status: ApiCallStatus.NoStarted,
    totalCount: 0,
    totalPages: 0,
    error: "",
    isAddEditModalOpen: false,
    openedRoleId: "",
    openedRoleName: "",
    quickSearch: "",
  } as RolesGridState;
};

const fetchPaginatedRoles = createAsyncThunk<
  PaginatedResult<RoleResponse>,
  void,
  { state: RootState }
>("roles/fetchPaginated", async (_, thunk) => {
  const { getState } = thunk;
  const state = getState().RolesGridSlice;
  const response = await GetPaginatedRoles(state.currentPage, state.pageSize, state.quickSearch);
  return response.data as PaginatedResult<RoleResponse>;
});

const rolesGridSlice = createSlice({
  name: "RolesGrid",
  initialState: getInitialState(),
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload + 1;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    addRole: (state) => {
      state.isAddEditModalOpen = true;
      state.openedRoleId = "";
      state.openedRoleName = "";
    },
    editRole: (state, action: PayloadAction<{ roleId: string; roleName: string }>) => {
      state.isAddEditModalOpen = true;
      state.openedRoleId = action.payload.roleId;
      state.openedRoleName = action.payload.roleName;
    },
    closeRoleModal: (state) => {
      state.isAddEditModalOpen = false;
      state.openedRoleId = "";
      state.openedRoleName = "";
    },
    setQuickSearch: (state, action: PayloadAction<string>) => {
      state.quickSearch = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPaginatedRoles.pending, (state) => {
        state.status = ApiCallStatus.Loading;
      })
      .addCase(fetchPaginatedRoles.fulfilled, (state, action) => {
        if (action.payload.succeeded) {
          state.rows = action.payload.data;
          state.currentPage = action.payload.currentPage;
          state.totalCount = action.payload.totalCount;
          state.totalPages = action.payload.totalPages;
          state.status = ApiCallStatus.Success;
        } else {
          state.status = ApiCallStatus.Error;
        }
      })
      .addCase(fetchPaginatedRoles.rejected, (state) => {
        state.rows = [];
        state.status = ApiCallStatus.Error;
        state.error = "Something went wrong";
      });
  },
});

export const { setPage, setPageSize, addRole, editRole, closeRoleModal, setQuickSearch } =
  rolesGridSlice.actions;
export { fetchPaginatedRoles };
export const rolesGridSelector = (state: RootState) => state.RolesGridSlice;
export default rolesGridSlice.reducer;
