import { Box, Button, Paper, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import React, { FC, useState } from "react";
import AbortedBatchesGrid from "./AbortedBatchesGrid";
import CompletedBatchesGrid from "./CompletedBatchesGrid";
import InProgressBatchesGrid from "./InProgressBatchesGrid";
import { useAppDispatch } from "../../../../Redux/Store";
import { fetchInProgressBatchesClaims } from "../../../../Redux/Slice/TenantDashboard/ClaimStatusBatches/InProgressBatchesGridSlice";
import { fetchCompletedBatchesClaims } from "../../../../Redux/Slice/TenantDashboard/ClaimStatusBatches/CompletedBatchesGridSlice";
import DeletedClaimStatusBatchesGrid from "./DeletedClaimStatusBatchesGrid";
import { fetchDeletedBatchesClaims } from "../../../../Redux/Slice/TenantDashboard/ClaimStatusBatches/DeletedBatchesGridSlice";
import SearchTextField from "../../../Common/SearchTextField/SearchTextField";

enum ActiveTabEnum {
  InProgress = 1,
  Completed = 2,
  Aborted = 3,
  Deleted = 4,
}

export type ClaimStatusBatchesDashboardBaseProps = {
  tenantId: number;
};

const ClaimStatusBatchesDashboardBase: FC<ClaimStatusBatchesDashboardBaseProps> = function ({
  tenantId,
}) {
  const [activeTab, setActiveTab] = useState<ActiveTabEnum>(ActiveTabEnum.InProgress);

  const [quickSearch, setQuickSearch] = useState<string>("");

  const theme = useTheme();

  const dispatch = useAppDispatch();

  const setNavigation = function (newValue: number) {
    setActiveTab(newValue);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setQuickSearch("");
    setNavigation(newValue);
  };

  return (
    <div>
      <Paper
        variant='elevation'
        style={{
          padding: theme.spacing(2),
        }}
      >
        <Stack
          pb={1}
          direction='row'
          spacing={1}
        >
          <Typography
            variant='h6'
            style={{
              flex: 1,
            }}
          >
            Claim Status Batches
          </Typography>
          <SearchTextField
            label='Search'
            placeholder='Search'
            onSearch={(text) => {
              setQuickSearch(text);
            }}
            size='small'
            searchText={quickSearch}
          />
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              if (activeTab === ActiveTabEnum.InProgress) {
                dispatch(fetchInProgressBatchesClaims({ tenantId: tenantId, quickSearch }));
              } else if (activeTab === ActiveTabEnum.Completed) {
                dispatch(fetchCompletedBatchesClaims({ tenantId: tenantId, quickSearch }));
              } else if (activeTab === ActiveTabEnum.Deleted) {
                dispatch(fetchDeletedBatchesClaims({ tenantId: tenantId, quickSearch }));
              } else {
                dispatch(fetchInProgressBatchesClaims({ tenantId: tenantId, quickSearch }));
              }
            }}
          >
            Reload
          </Button>
        </Stack>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={handleChange}
          >
            <Tab
              label='In Progress'
              value={ActiveTabEnum.InProgress}
            />
            <Tab
              label='Completed'
              value={ActiveTabEnum.Completed}
            />
            <Tab
              label='Aborted'
              value={ActiveTabEnum.Aborted}
            />
            <Tab
              label='Deleted'
              value={ActiveTabEnum.Deleted}
            />
          </Tabs>
        </Box>
        <Box paddingTop={theme.spacing(2)}>
          {activeTab === ActiveTabEnum.InProgress && (
            <InProgressBatchesGrid
              tenantId={tenantId}
              quickSearch={quickSearch}
            />
          )}
          {activeTab === ActiveTabEnum.Completed && (
            <CompletedBatchesGrid
              tenantId={tenantId}
              quickSearch={quickSearch}
            />
          )}
          {activeTab === ActiveTabEnum.Aborted && (
            <AbortedBatchesGrid
              tenantId={tenantId}
              quickSearch={quickSearch}
            />
          )}
          {activeTab === ActiveTabEnum.Deleted && (
            <DeletedClaimStatusBatchesGrid
              tenantId={tenantId}
              quickSearch={quickSearch}
            />
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default ClaimStatusBatchesDashboardBase;
