import { SaveRoleRequest } from "../../Models/ApiRequest/Admin/Role/SaveRoleRequest";
import { UpdateRolePermissionRequest } from "../../Models/ApiRequest/Admin/Role/UpdateRolePermissionRequest";
import { IResult } from "../../Models/ApiResponse/Common/IResult";
import { PaginatedResult } from "../../Models/ApiResponse/Common/PaginatedResult";
import { PermissionResponse } from "../../Models/ApiResponse/Role/PermissionResponse";
import { RoleNamesResponse, RoleResponse } from "../../Models/ApiResponse/Role/RoleNamesResponse";
import { httpClient } from "../APIBase";

const roleEndpointsBasePath = "/admin/role";

const GetAllRoles = function () {
  return httpClient.get<IResult<Array<RoleNamesResponse>>>(`${roleEndpointsBasePath}/all`);
};

const GetPaginatedRoles = function (pageNumber: number, pageSize: number, search: string) {
  return httpClient.get<PaginatedResult<RoleResponse>>(`${roleEndpointsBasePath}/page`, {
    params: {
      pageNumber,
      pageSize,
      search,
    },
  });
};

const GetRolePermissions = function (roleId: string) {
  return httpClient.get<IResult<PermissionResponse>>(
    `${roleEndpointsBasePath}/${roleId}/permissions`,
  );
};

const SaveRole = function (request: SaveRoleRequest) {
  return httpClient.post<IResult<string>>(`${roleEndpointsBasePath}`, request);
};

const UpdateRolePermissions = function (request: UpdateRolePermissionRequest) {
  return httpClient.put<string>(`${roleEndpointsBasePath}/permissions/update`, request);
};

const DeleteRole = function (roleId: string) {
  return httpClient.delete<IResult<string>>(`${roleEndpointsBasePath}/${roleId}`);
};

export {
  DeleteRole,
  GetAllRoles,
  GetPaginatedRoles,
  GetRolePermissions,
  SaveRole,
  UpdateRolePermissions,
};
