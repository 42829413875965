import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { DeleteRole } from "../../Api/Roles/RolesEndpoints";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Slice/Application/ApplicationSlice";
import {
  editRole,
  fetchPaginatedRoles,
  rolesGridSelector,
  setPage,
  setPageSize,
} from "../../Redux/Slice/Roles/RolesGridSlice";
import { useAppDispatch } from "../../Redux/Store";
import AitDataGrid from "../Common/DataGrid/AitDataGrid";
import ConfirmModal from "../Common/Modals/ConfirmModal";

const RolesGrid: FC = function () {
  const state = useSelector(rolesGridSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const initialConfirmDelete = { show: false, message: "", roleId: "", roleName: "" };
  const [confirmDelete, setConfirmDelete] = useState<{
    show: boolean;
    message: string;
    roleId: string;
    roleName: string;
  }>(initialConfirmDelete);

  useEffect(() => {
    dispatch(fetchPaginatedRoles());
  }, [state.currentPage, state.pageSize]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Role Name",
      sortable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      sortable: false,
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            label='Edit Role'
            key='editEole'
            showInMenu
            onClick={() => {
              dispatch(editRole({ roleId: id.toString(), roleName: row["name"] }));
            }}
          />,
          <GridActionsCellItem
            label='Edit Permissions'
            key='editPermissions'
            showInMenu
            onClick={() => {
              navigate(`/admin/roles/${id}/editPermission`);
            }}
          />,
          <GridActionsCellItem
            label='Delete'
            key='deleteRole'
            showInMenu
            onClick={() => {
              setConfirmDelete({
                show: true,
                message: `Are you sure want to role delete ${row["name"]}?`,
                roleId: id.toString(),
                roleName: row["name"],
              });
            }}
          />,
        ];
      },
    },
  ];
  const confirmModal = () => (
    <ConfirmModal
      title='Confirm'
      message={confirmDelete?.message}
      onNo={() => {
        setConfirmDelete(initialConfirmDelete);
      }}
      onYes={() => {
        setConfirmDelete(initialConfirmDelete);
        DeleteRole(confirmDelete.roleId)
          .then(({ data: res }) => {
            if (res.succeeded) {
              dispatch(showSuccessSnackbar(res.messages[0]));
            } else {
              dispatch(showErrorSnackbar(res.messages[0]));
            }
          })
          .catch(() => {
            dispatch(showErrorSnackbar("Failed to delete role."));
          });
      }}
      show={confirmDelete.show}
    />
  );
  return (
    <div
      style={{
        height: "500px",
      }}
    >
      {confirmDelete.show && confirmModal()}
      <AitDataGrid
        keyColumnName='id'
        state={state}
        columns={columns}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export default RolesGrid;
